<template>
  <div class="vx-row grid">
    <div class="vx-col w-full sm:w-3/4 mb-base place-self-center">
      <vx-card>
        <b-form id="AffordabilityForm" @submit.prevent="send" onkeydown="return event.key != 'Enter';">
          <b-form-group :invalid-feedback="emailFeedback" :state="emailState" label="Client email:" class="mb-8">
            <b-form-input size="lg" id="input-none" v-model="email" v-on:keydown.enter="checkEmail"></b-form-input>
          </b-form-group>

          <b-form-group class="mb-8 justify-end flex flex-wrap items-center justify-end mt-4">
            <b-button variant="primary" :disabled="!emailState" @click="checkEmail">Check</b-button>
          </b-form-group>

          <b-form-group label="" v-if="showDeclaration">
            <b-form-radio v-model="declaration" name="accept-declaration" value="1" class="mb-6">
              <span v-html="declarationText()"></span>
            </b-form-radio>
            <b-form-radio v-model="declaration" name="reject-declaration" value="0">
              <span v-html="declarationTextReject()"></span>
            </b-form-radio>
          </b-form-group>

          <div class="vx-col mt-2 flex justify-end" v-if="showDeclaration">
            <b-button
              class="shadow-md hover:shadow-lg lg:mt-0 mb-2 mt-2 mr-4 p-2"
              variant="primary"
              size="lg"
              :disabled="!validateForm"
              type="submit"
              >Submit</b-button
            >
          </div>
        </b-form>
      </vx-card>
    </div>
  </div>
</template>

<script>
import { getErrorMessage } from '@repo/utils'
import helpers from '../../../helpers'
import userAPI from '../../../http/requests/user/userAPI'

export default {
  data() {
    return {
      user: '',
      email: '',
      ifa_details: '',
      userName: '',
      declaration: '',
      showDeclaration: false
    }
  },
  computed: {
    emailFeedback() {
      if (this.email.length < 4) {
        return 'Please enter a valid Email'
      }
      if (!helpers.emailRegex().test(this.email)) {
        return 'Please enter a valid Email'
      }
      return 'Email is required'
    },
    emailState() {
      if (this.email === '') {
        return null
      }
      return this.email?.length > 4 && helpers.emailRegex().test(this.email)
    },
    validateForm() {
      return this.emailFeedback && !!this.declaration
    }
  },
  methods: {
    checkEmail() {
      if (!this.emailState) {
        helpers.notifyError(this.$vs, 'Please enter a valid email')
        return
      }
      this.$vs.loading()

      userAPI
        .getIFAApproval({ email: this.email })
        .then((res) => {
          this.$vs.loading.close()
          this.userName = res.data.data.name
          this.showDeclaration = true
        })
        .catch((err) => {
          helpers.notifyError(this.$vs, getErrorMessage(err))
          this.$vs.loading.close()
        })
    },
    declarationText() {
      return `I, ${this.ifa_details.name} of ${this.ifa_details.company_name}, certify that ${this.userName} is suitably knowledgeable to understand the key features and risks associated with investments in unlisted securities, and specifically with respect to Pfida’s share-based investment offerings.`
    },
    declarationTextReject() {
      return `I, ${this.ifa_details.name} of ${this.ifa_details.company_name}, believe that the unlisted security investment opportunities offered by Pfida are currently not suitable for ${this.userName}`
    },
    userChange(value) {
      this.userIndex = value.index
    },
    send() {
      const payload = {
        company: 'pfhpp',
        declaration_text: +this.declaration ? this.declarationText() : this.declarationTextReject(),
        email: this.email,
        approved: this.declaration
      }
      this.$vs.loading()

      userAPI
        .postIFAApproval(payload)
        .then((result) => {
          this.$vs.loading.close()
          helpers.notifySuccess(this.$vs, result.data.response.messages[0])
          this.clearInput()
        })
        .catch((err) => {
          this.$vs.loading.close()
          helpers.notifyError(this.$vs, getErrorMessage(err))
        })
    },
    clearInput() {
      this.user = ''
      this.email = ''
      this.ifa_details = ''
      this.userName = ' '
      this.declaration = ''
      this.showDeclaration = false
    }
  },
  mounted() {},
  beforeRouteEnter(to, from, next) {
    let response
    userAPI
      .getIFAApproval()
      .then((res) => {
        response = res.data.data
        next((vm) => {
          vm.ifa_details = response.ifa_details
        })
      })
      .catch(() => {
        // if 401 redirect to error page, everything else show error
        // next(`/error-404`)
      })
  }
}
</script>
