<template>
  <div id="cost-calculator-estimate-component">
    <vx-card>
      <h4>Finance Cost Estimate</h4>
      <vs-divider class="mb-4" />

      <div v-if="isCalculated" id="isCalculated">
        <p class="text-lg">
          For a property worth <strong>{{ $filters.currency_formatter(property_value) }}</strong> if you were to get
          <strong>{{ $filters.currency_formatter(finance_amount) }}</strong> of
          <strong
            id="finance-type"
            class="underline"
            v-b-popover.hover.top="{ variant: 'secondary', content: popoverText, container:'cost-calculator-estimate-component' }"
            >{{ financeType }}</strong
          >
          finance over <strong id="term">{{ term }} years</strong>, your payments would be roughly as follows:
        </p>
      </div>

      <div class="monthly-payments">
        <div class="monthly-payments__price">
          <p>
            Monthly payments
            <b-icon icon="info-circle" v-b-popover.hover.top="{ variant: 'secondary', content: monhlyPaymentText,container:'cost-calculator-estimate-component' }" />
          </p>
          <p id="monthly-payments" class="text-4xl">{{ $filters.currency_formatter(monthly_payment) }}</p>
        </div>
        <div class="monthly-payments__price">
          <p>One-time arrangement fee</p>
          <p id="agreement-fee" class="text-4xl">{{ $filters.currency_formatter(arrangement_fee) }}</p>
        </div>
      </div>

      <h4 class="text-center mt-4">Estimated total payments</h4>
      <div class="price-range">
        <div class="price-range__price">
          <p><b-icon icon="info-circle" v-b-popover.hover.top="{ variant: 'secondary', content: bestCaseText, container:'cost-calculator-estimate-component' }" /></p>
          <p>Best case</p>
          <p id="best-case" class="text-2xl">{{ $filters.currency_formatter(total_payment_best_case) }}</p>
        </div>
        <div class="price-range__price">
          <p>
            <b-icon icon="info-circle" v-b-popover.hover.top="{ variant: 'secondary', content: midRangeText, container:'cost-calculator-estimate-component' }" />
          </p>
          <p>Mid range</p>
          <p id="mid-range" class="text-2xl">{{ $filters.currency_formatter(total_payment_mid_range) }}</p>
        </div>
        <div class="price-range__price">
          <p>
            <b-icon icon="info-circle" v-b-popover.hover.top="{ variant: 'secondary', content: worsCaseText, container:'cost-calculator-estimate-component' }" />
          </p>
          <p>Worst case</p>
          <p id="worst-case" class="text-2xl">{{ $filters.currency_formatter(total_payment_worst_case) }}</p>
        </div>
      </div>

      <div class="mt-4 mb-4 m-auto text-sm text-left">
        <p>
          The figures above assume you make your full target monthly payment on time each month. Failure to do so may
          result in different outcomes.
        </p>
        <p>
          The level of finance we can provide will be based on a full affordability assessment and may be subject to a
          cap.
        </p>
      </div>

      <div class="vx-col w-full mt-4" id="buttons">
        <div class="w-full mb-3">
          <b-button
            v-if="isLoggedIn && showEdit"
            @click="editDetails"
            variant="outline-primary"
            class="w-full shadow-md"
            id="edit"
            >Go back and edit your details <b-icon icon="pencil-square" class="ml-2" aria-hidden="true"></b-icon>
          </b-button>
        </div>

        <div class="w-full mb-3">
          <b-button
            v-if="showButtons"
            @click.prevent="goToAffordabilityCalculator"
            variant="primary"
            class="w-full shadow-md"
            id="goToAffordability"
            >See how much you can afford
          </b-button>
        </div>
        <div class="w-full mb-3">
          <b-button
            v-if="isLoggedIn && showButtons"
            variant="primary"
            @click.prevent="checkRouter()"
            class="w-full shadow-md"
            id="aipLink"
            >Get an Acceptance in Principle
          </b-button>
        </div>

        <div class="w-full mb-3">
          <b-button
            v-if="isLoggedIn && showButtons"
            variant="primary"
            id="historyLink"
            @click.prevent="$router.push('/calculators/calculation-history').catch(() => {})"
            class="w-full shadow-md"
            >Check historic results
          </b-button>
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>
export default {
  name: 'CostCalculatorEstimate',
  data() {
    return {
      bestCaseText:
        'This scenario assumes your rent goes down by the maximum 5% in every future year based on local property price movements.',
      midRangeText: 'This scenario assumes your rent stays level in every future year based on local property prices.',
      worsCaseText:
        'This scenario assumes your rent goes up by the maximum 5% in every future year based on local property price movements.',
      monhlyPaymentText: 'This is your estimated monthly payment including rent plus equity purchase.'
    }
  },
  methods: {
    goToAffordabilityCalculator() {
      const url = this.isLoggedIn ? 'affordability-calculator' : 'open-affordability-calculator'
      this.$router.push({
        name: url
      })
    },
    checkRouter() {
      this.$router.push('/calculators/acceptance-principle').catch(() => {})
    },
    setCostCalculatorDetails() {
      this.$store.dispatch('calculators/updateCostCalculatorData', {
        finance_type: this.isBuyToLet
          ? { label: 'Buy-To-Let Purchase Plan (BPP)', value: 'Buy-To-Let Purchase Plan' }
          : { label: 'Home Provision Scheme (HPS)', value: 'Home Provision Scheme' },
        property_value: this.property_value,
        deposit: this.deposit,
        term: this.term
      })
    },
    editDetails() {
      this.setCostCalculatorDetails()
      this.$router.push({ name: 'cost-calculator' })
    }
  },
  props: {
    isCalculated: {
      type: Boolean,
      default: false
    },
    term: {
      type: [String, Number],
      default: 0
    },
    deposit: {
      type: [String, Number],
      default: 0
    },
    finance_amount: {
      type: Number,
      default: 0
    },
    property_value: {
      type: [String, Number],
      default: 0
    },
    monthly_payment: {
      type: Number,
      default: 0
    },
    arrangement_fee: {
      type: Number,
      default: 0
    },
    total_payment_best_case: {
      type: Number,
      default: 0
    },
    total_payment_mid_range: {
      type: Number,
      default: 0
    },
    total_payment_worst_case: {
      type: Number,
      default: 0
    },
    isBuyToLet: {
      type: Boolean,
      default: false
    },
    showButtons: {
      type: Boolean,
      default: true
    },
    showEdit: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.auth.isUserLoggedIn()
    },
    financeType() {
      return this.isBuyToLet ? 'BPP' : 'HPS'
    },
    popoverText() {
      return this.isBuyToLet ? 'Buy-To-Let Purchase Plan' : 'Home Provision Scheme'
    }
  }
}
</script>

<style scoped>
.monthly-payments {
  margin: 10px 0;
  display: inline-flex;
  width: 100%;
}
.monthly-payments__price {
  text-align: center;
  word-break: break-word;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.price-range {
  margin: 10px 0;
  display: inline-flex;
  width: 100%;
}
.price-range__price {
  text-align: center;
  width: 33%;
  display: flex;
  flex-direction: column;
  word-break: break-word;
  justify-content: space-around;
}
.vx-card__body {
  padding-top: 0 !important;
}
</style>
