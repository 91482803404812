<template>
  <div id="hps">
    <div v-if="!error">
      <portal to="page-title">
        <h2 v-if="pageTitle">{{ pageTitle }}</h2>
      </portal>
      <portal to="ref">
        <h4 v-if="ref" class="portal-title mt-2">{{ ref }}</h4>
      </portal>

      <!-- Rent Arrears notification -->
      <div class="vx-col w-full mb-1" v-if="currentFinance.show_rent_arrears">
        <b-alert show variant="danger">
          You have
          <b>rent arrears of {{ $filters.currency_formatter_decimal(currentFinance.rent_arrears_remaining) }} </b>.
          Please pay this amount as soon as possible ensuring you use reference
          <b>{{ currentFinance.reference }}</b> when making the payment. You will <b>not be eligible</b> for rental
          discounts until this amount is cleared.
        </b-alert>
      </div>

      <vs-tabs :position="tabPosition" v-model="tabIndex" :value="tabIndex" alignment="fixed">
        <vs-tab label="Agreement Analytics">
          <AgreementAnalyticsTab v-if="loaded" v-on:updateIndex="setIndex()" :index="index"></AgreementAnalyticsTab>
        </vs-tab>

        <vs-tab label="Transactions">
          <div class="mt-10">
            <TransactionTab />
          </div>
        </vs-tab>

        <vs-tab label="Agreement Settings" v-if="this.$pfidaTenant">
          <div class="mt-10">
            <AgreementSettingsTab v-if="loaded" :labels="labels" :index="index"></AgreementSettingsTab>
          </div>
        </vs-tab>
      </vs-tabs>
    </div>
    <ServerError500 v-else />
  </div>
</template>

<script>
import TransactionTab from './Tabs/TransactionTab.vue'
import AgreementAnalyticsTab from './Tabs/AgreementAnalyticsTab.vue'
import AgreementSettingsTab from './Tabs/AgreementSettingsTab.vue'
import ServerError500 from '../../components/error-pages/ServerError500.vue'
import userAPI from '../../../http/requests/user/userAPI'

export default {
  components: {
    AgreementAnalyticsTab,
    TransactionTab,
    AgreementSettingsTab,
    ServerError500
  },
  methods: {
    getData() {
      this.$store
        .dispatch('getFinanceDetails')
        .then(() => {
          userAPI
            .changeExpectedPaymentSettings()
            .then((result) => {
              userAPI
                .changeUnexpectedPaymentSettings()
                .then((result) => {
                  this.labels.unexpectedPaymentMethod = result.data.data.settings
                  this.loaded = true
                })
                .catch(() => {
                  this.error = true
                })

              this.labels.expectedPaymentMethod = result.data.data.settings
            })
            .catch(() => {
              this.error = true
            })
        })
        .catch(() => {
          this.error = true
        })
    },
    setIndex() {
      this.tabIndex = 2
    }
  },
  created() {
    this.index = this.$route.params.index
    this.getData()
  },
  computed: {
    tabPosition(vm) {
      return vm.$store.state.windowWidth < 550 ? 'center' : 'bottom'
    },
    pageTitle() {
      const hpp = this.$store.state?.financeDetails[this.index - 1]
      return hpp?.product_name
    },
    currentFinance() {
      return this.$store.state.financeDetails[this.index - 1]
    },
    ref() {
      const hpp = this.$store.state.financeDetails[this.index - 1]
      return `Agreement reference: ${hpp?.reference}`
    }
  },
  data() {
    return {
      loaded: false,
      index: '',
      labels: {
        expectedPaymentMethod: '',
        unexpectedPaymentMethod: ''
      },
      error: false,
      tabIndex: 0
    }
  },
  mounted() {
    if (!this.currentFinance) {
      this.$router.push(`/dashboard`)
    }
  }
}
</script>

<style lang="scss">
.vue-portal-target {
  width: 100%;
}
.portal-title {
  color: $pfida-purple;
  width: 100%;
  font-size: 18px;
}

.content-container {
  font-size: 16px;
  display: flex;
  justify-items: center;
  justify-content: center;
  margin-bottom: 40px;
}
</style>
