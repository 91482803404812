<template>
  <div class="FormDate" @keyup.capture="updateValue">
    <input
      v-if="showDay"
      ref="day"
      v-model="day"
      class="FormDate__input FormDate__input--day"
      type="number"
      placeholder="dd"
      @input="updateDay"
      @blur="
        if (day.length === 1) {
          day = day.padStart(2, 0)
          updateValue()
        }
      "
    />
    <span v-if="showDay && showMonth" class="FormDate__divider">/</span>
    <input
      v-if="showMonth"
      ref="month"
      v-model="month"
      class="FormDate__input FormDate__input--month"
      type="number"
      placeholder="mm"
      @input="updateMonth"
      @blur="
        if (month.length === 1) {
          month = month.padStart(2, 0)
          updateValue()
        }
      "
    />
    <span v-if="showYear && (showDay || showMonth)" class="FormDate__divider">/</span>
    <input
      v-if="showYear"
      ref="year"
      v-model="year"
      class="FormDate__input FormDate__input--year"
      type="number"
      placeholder="yyyy"
    />
  </div>
</template>

<script>
export default {
  name: 'FreeDateInput',
  props: {
    value: {
      type: [Number, String],
      required: true
    },
    showDay: {
      type: Boolean,
      default: true
    },
    showMonth: {
      type: Boolean,
      default: true
    },
    showYear: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    year(current, prev) {
      if (current > 9999) this.year = prev
    }
  },
  data() {
    return {
      day: `${this.value ? new Date(this.value).getDate() : ''}`,
      month: `${this.value ? new Date(this.value).getMonth() + 1 : ''}`,
      year: `${this.value ? new Date(this.value).getFullYear() : ''}`
    }
  },
  methods: {
    updateValue() {
      const date = `${this.day}/${this.month}/${this.year}`
      this.$emit('input', date)
    },
    resetData() {
      this.day = ''
      this.month = ''
      this.year = ''
    },
    updateDay() {
      if (!this.day.length || parseInt(this.day, 10) < 4) return
      if (this.showMonth) this.$refs.month.select()
      else if (this.showYear) this.$refs.year.select()
    },
    updateMonth() {
      if (!this.month.length || parseInt(this.month, 10) < 2) return
      if (this.showYear) this.$refs.year.select()
    }
  }
}
</script>

<style lang="scss" scoped>
.FormDate {
  display: flex;
  justify-content: space-between;
  align-items: center;
  $spacing: 0.75em;

  border: 1px solid #ced4da;
  border-radius: 0.25em;
  width: 100%;
  // 1. Hide the spinner button in Chrome, Safari and Firefox.
  &__input {
    padding: $spacing;
    padding-right: $spacing / 2;
    padding-left: $spacing / 2;
    border: none;
    text-align: center;
    -moz-appearance: textfield; // 1
    width: 32%;

    &::-webkit-inner-spin-button {
      display: none; // 1
    }

    &:first-child {
      padding-left: $spacing;
    }

    &:last-child {
      padding-right: $spacing;
    }

    &:focus {
      outline: none;
    }
  }

  &__divider {
    padding-top: $spacing;
    padding-bottom: $spacing;
    pointer-events: none;
  }
}
</style>
