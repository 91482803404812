<template>
  <div>
    <b-card>
      <b-form id="userDetailsForm" @submit.prevent="updatePersonalDetails">
        <b-form-group id="input-group-name" label="Full name:" label-for="input-name">
          <b-form-input id="input-name" disabled v-model="name" placeholder="Enter name" required></b-form-input>
        </b-form-group>

        <b-form-group id="input-group-dob" label="Date of birth:" label-for="input-dob">
          <b-form-input id="input-dob" disabled v-model="dob" type="text" required></b-form-input>
        </b-form-group>

        <b-form-group
          id="input-group-email"
          :invalid-feedback="emailFeedback"
          :state="emailInputState"
          label="Email address:"
          label-for="input-email"
        >
          <b-form-input id="input-email" v-model="email" type="email" placeholder="Enter email" required></b-form-input>
          <span v-if="!email_verified && hasEmailChanges" class="text-warning"
            >Please <b-link class="text-primary underline" @click="resendEmailCode">verify</b-link> your email address
            to avoid being locked out of your account</span
          >
        </b-form-group>

        <b-form-group
          id="input-group-mobile"
          :invalid-feedback="mobileFeedback"
          :state="mobileInputState"
          label="Mobile number:"
          label-for="input-mobile"
        >
          <b-form-input
            name="input-mobile"
            @keypress="onPhoneKeydown($event)"
            v-model="telephone"
            type="tel"
            required
          ></b-form-input>

          <!-- <b-form-input id="input-mobile" v-model="telephone" type="number" required></b-form-input> -->
        </b-form-group>

        <b-form-group
          id="input-group-address"
          :invalid-feedback="addressFeedback"
          :state="addressInputState"
          label="Address:"
          label-for="input-address"
        >
          <b-form-input id="input-address" :disabled="_address" v-model="address" type="text" required></b-form-input>
        </b-form-group>

        <b-form-group id="input-group-city" label="City:" label-for="input-city">
          <b-form-input id="input-city" :disabled="_city" v-model="city" type="text" required></b-form-input>
        </b-form-group>

        <b-form-group
          id="input-group-postcode"
          :invalid-feedback="postcodeFeedback"
          :state="postcodeInputState"
          label="Postcode:"
          label-for="input-postcode"
        >
          <b-form-input
            id="input-postcode"
            :disabled="_postcode"
            v-model="postcode"
            type="text"
            required
          ></b-form-input>
        </b-form-group>

        <div class="flex flex-wrap items-center justify-end">
          <b-button
            class="hover:shadow-lg ml-auto mt-2"
            :disabled="!validateForm || !haveDetailsChanged"
            variant="primary"
            type="submit"
            >Save Changes</b-button
          >
        </div>
      </b-form>
    </b-card>

    <b-modal id="verifyEmailModal" centered title="Verify your email" hide-header hide-footer no-close-on-backdrop>
      <div class="text-center grid">
        <div class="vx-col w-full sm:w-3/4 my-base place-self-center">
          <div class="font-bold">
            <h4 id="title">Verify your email</h4>
          </div>
          <div class="my-4">
            <span
              >A code was sent to
              <br />
              <b-input-group class="my-2">
                <b-form-input
                  type="email"
                  id="emailEdit"
                  v-model="email"
                  :disabled="modal.editEmailDisabled"
                ></b-form-input>
                <b-input-group-append>
                  <b-button variant="primary" :disabled="modal.editEmailLoading || emailNotChanged" @click="editEmail">
                    <b-spinner v-if="modal.editEmailLoading" small></b-spinner>
                    <span v-else>
                      {{ modal.editEmailTxt }}
                    </span>
                  </b-button>
                </b-input-group-append>
              </b-input-group>
              <span> Please enter the code below to verify your new email address.</span>
            </span>
          </div>

          <div>
            <b-form @submit.prevent="SubmitCode">
              <b-input-group class="w-72 mx-auto">
                <b-form-input type="number" maxLength="6" v-model="modal.code" required></b-form-input>
              </b-input-group>
              <span class="text-danger">{{ modal.errorMessage }}</span>
              <div class="text-sm mt-2">
                <span
                  >Didn't receive the code?
                  <b-link id="#resend" type="button" @click="resendEmail">Resend</b-link></span
                >
              </div>
              <b-button variant="primary" type="submit" :disabled="!validate || modal.submitCodeLoading" class="my-4">
                <b-spinner v-if="modal.submitCodeLoading" small></b-spinner>
                <span v-else> Confirm </span>
              </b-button>
            </b-form>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import helpers from '../../../../helpers'
import jwt from '@/http/requests/auth/jwt'
import _ from 'lodash'
import { getErrorMessage } from '@repo/utils'
export default {
  data() {
    return {
      name: this.$store.state.user.details?.name,
      dob: this.$store.state.user.details?.birthdate,
      email: this.$store.state.user.details?.email,
      telephone: this.$store.state.user.details?.phone_number,
      address: this.$store.state.user.details?.address,
      city: this.$store.state.user.details?.city,
      postcode: this.$store.state.user.details?.postcode,

      //modal
      modal: {
        code: '',
        editEmailLoading: false,
        submitCodeLoading: false,
        editEmailDisabled: true,
        editEmailTxt: 'Edit',
        errorMessage: ''
      }
    }
  },
  computed: {
    _address() {
      return !!this.$store.state.user.details?.address
    },
    _city() {
      return !!this.$store.state.user.details?.city
    },
    _postcode() {
      return !!this.$store.state.user.details?.postcode
    },
    doesAddressExists() {
      return !!this.$store.state.user.details?.address
    },
    emailNotChanged() {
      if (this.modal.editEmailTxt === 'Send code') {
        return this.email === this.$store.state.user.details.email
      } else {
        return false
      }
    },
    validate() {
      return this.modal.code != '' && this.modal.code.toString()?.length >= 6
    },
    email_verified() {
      return JSON.parse(this.$store.state.user.details.email_verified?.toLowerCase())
    },
    hasEmailChanges() {
      return this.email === this.$store.state.user.details.email
    },
    mobileInputState() {
      return this.telephone?.length >= 11
    },
    addressInputState() {
      return this.address?.length >= 7
    },
    cityInputState() {
      return this.city?.length >= 1
    },
    postcodeInputState() {
      return this.postcode?.length >= 5 && this.postcode?.length <= 9
    },
    emailInputState() {
      return this.email?.length > 4 && helpers.emailRegex().test(this.email)
    },

    mobileFeedback() {
      if (this.telephone?.length > 0) {
        return 'The telephone field must be at least 11 characters'
      }
      return 'This field is required'
    },
    addressFeedback() {
      if (this.address?.length > 0) {
        return 'Please enter a valid address'
      }
      // return 'This field is required'
    },
    CityFeedback() {
      // return 'This field is required'
    },
    postcodeFeedback() {
      if (this.postcode?.length > 0) {
        return 'Please enter a valid postcode'
      }
    },
    emailFeedback() {
      if (this.email?.length > 0) {
        return 'Please enter a valid Email'
      }
      return 'This field is required'
    },
    validateForm() {
      return (
        this.mobileInputState &&
        this.addressInputState &&
        this.cityInputState &&
        this.postcodeInputState &&
        this.emailInputState
      )
    },
    haveDetailsChanged() {
      if (
        this.$store.state.user.details?.phone_number != this.telephone ||
        this.$store.state.user.details?.address != this.address ||
        this.$store.state.user.details?.city != this.city ||
        this.$store.state.user.details?.postcode != this.postcode ||
        this.$store.state.user.details?.email != this.email
      ) {
        return true
      }
      return false
    }
  },
  methods: {
    onPhoneKeydown(e) {
      let char = String.fromCharCode(e.keyCode) // Get the character
      if (/^[0-9 +]+$/i.test(char)) return true
      // Match with regex
      else e.preventDefault() // If not match, don't add to input text
    },
    updatePersonalDetails() {
      if (!this.validateForm) return
      let hasEmailChanged = false
      const payload = {
        user_attributes: []
      }
      if (this.telephone != this.$store.state.user.details.phone_number) {
        payload.user_attributes.push({
          Name: 'phone_number',
          Value: helpers.generateMobile(this.telephone)
        })
      }
      if (this.address != this.$store.state.user.details.address) {
        payload.user_attributes.push({
          Name: 'address',
          Value: this.address
        })
      }
      if (this.email != this.$store.state.user.details.email) {
        hasEmailChanged = true
        payload.user_attributes.push({
          Name: 'email',
          Value: this.email
        })
      }
      if (this.city != this.$store.state.user.details.city) {
        payload.user_attributes.push({
          Name: 'custom:city',
          Value: this.city
        })
      }
      if (this.postcode != this.$store.state.user.details.postcode) {
        payload.user_attributes.push({
          Name: 'postcode',
          Value: this.postcode
        })
      }
      if (payload.user_attributes.length > 0) {
        this.$vs.loading()
        this.$store
          .dispatch('user/updateUserDetails', payload)
          .then(() => {
            this.$vs.loading.close()
            if (hasEmailChanged) {
              this.$bvModal.show('verifyEmailModal')
            } else {
              helpers.notifySuccess(this.$vs, 'Profile updated successfully!')
            }
          })
          .catch((error) => {
            this.$vs.loading.close()
            helpers.notifyError(this.$vs, getErrorMessage(error))
          })
      }
    },
    resendEmailCode() {
      this.$store
        .dispatch('auth/resendEmailCode', { email: this.email })
        .then(() => {
          this.$bvModal.show('verifyEmailModal')
        })
        .catch((err) => {
          helpers.notifyError(this.$vs, getErrorMessage(err))
        })
    },

    // modal Fns
    editEmail() {
      if (this.modal.editEmailDisabled) {
        this.modal.editEmailDisabled = false
        this.modal.editEmailTxt = 'Send code'
      } else {
        this.modal.editEmailLoading = true
        const payload = {
          user_attributes: [
            {
              Name: 'email',
              Value: this.email
            }
          ]
        }
        this.$store
          .dispatch('user/updateUserDetails', payload)
          .then(() => {
            this.modal.editEmailLoading = false
            this.toast('A verification code has been sent')
            this.modal.editEmailDisabled = true
            this.modal.editEmailTxt = 'Edit'
          })
          .catch((error) => {
            this.modal.editEmailLoading = false
            helpers.notifyError(this.$vs, getErrorMessage(error))
          })
      }
    },
    toast(text) {
      this.$bvToast.toast(text, {
        toaster: 'b-toaster-bottom-center',
        noCloseButton: true,
        solid: false,
        id: 'copy-success',
        toastClass: 'rounded-full',
        bodyClass: 'text-center font-bold',
        appendToast: false
      })
    },
    SubmitCode() {
      this.modal.submitCodeLoading = true
      this.$store
        .dispatch('auth/verifyEmail', { code: this.modal.code })
        .then((res) => {
          helpers.notifySuccess(this.$vs, res.data.response.messages[0])
          this.modal.errorMessage = ''
          this.$store.commit('user/SET_USER_DETAILS', { email_verified: 'true' })
          this.$bvModal.hide('verifyEmailModal')
          this.modal.code = ''
          this.modal.submitCodeLoading = false
        })
        .catch((err) => {
          this.modal.errorMessage = err.response.data.response.messages[0]
          this.modal.submitCodeLoading = false
        })
    },

    resendEmail: _.throttle(
      function () {
        jwt
          .resendEmailCode({ email: this.email })
          .then((res) => {
            this.toast(res.data.response.messages[0])
          })
          .catch((err) => {
            helpers.notifyError(this.$vs, getErrorMessage(err))
          })
      },
      30000,
      { trailing: false }
    )
  }
}
</script>

<style lang="scss">
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
</style>
