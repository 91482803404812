<template>
  <div id="documents">
    <div class="mb-base" v-if="!error">
      <!-- Filter Card -->
      <b-card v-if="docsLoaded" class="shadow mb-4">
        <div class="flex items-baseline my-2 mx-2 flex-col-reverse sm:flex-row">
          <div class="flex items-baseline sm:flex-row">
            <div>
              <b-dropdown menu-class="menu-shadow" variant="filter" class="mr-4" no-caret>
                <b-dropdown-header id="dropdown-header-label"> Document Type </b-dropdown-header>
                <template #button-content>
                  Filter<b-icon icon="filter" class="cursor-pointer" font-scale="1.5" />
                </template>
                <b-dropdown-item-button
                  v-for="(filterOption, index) in filterOptions"
                  :active="filter === filterOption"
                  :key="index"
                  @click="ondropDownfilter"
                  class="dropdown-filter-btn"
                  >{{ filterOption }}</b-dropdown-item-button
                >
              </b-dropdown>
            </div>

            <div class="mt-4">
              <h3>
                <b-badge
                  v-if="filter"
                  class="pr-2 p-2 text-base font-normal text-black filter-badge"
                  font-scale="1.5"
                  pill
                  >{{ filter }}<b-icon class="cursor-pointer" @click="clearFilter" icon="x"
                /></b-badge>
              </h3>
            </div>
          </div>

          <div class="ml-auto">
            <b-button
              v-if="docsLoaded"
              :disabled="!AllUnreadLoaded"
              class="mt"
              variant="primary"
              @click="toggleAllReadUnread"
            >
              <span v-if="AllUnreadLoaded"> Mark all as {{ unreadDocs ? 'read' : 'unread' }} </span>
              <div v-if="!AllUnreadLoaded">
                Processing...
                <b-spinner small></b-spinner>
              </div>
            </b-button>
          </div>
        </div>
        <div class="my-2">
          <b-table
            outlined
            :items="tableItems"
            :fields="fields"
            id="documents-table"
            :current-page="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            :filter="filter"
            @filtered="onFiltered"
          >
            <template #cell(name)="data">
              <div>
                <span
                  :class="[{ 'notification-dot': !data.item.loading }, { 'loading-ring': data.item.loading }]"
                  v-b-popover.hover.top="{
                    variant: 'secondary',
                    content: data.item.unread ? 'Mark as read' : 'Mark as unread',
                    container: 'documents'
                  }"
                  @click.prevent="markAsRead(data.item)"
                >
                  <div></div>
                  <div></div>
                </span>
                <b-button
                  class="text-left ml-2"
                  @click.prevent="getCertificate(data.item)"
                  variant="light"
                  v-bind:id="`document-${data.index}`"
                  >{{ data.value }}
                  <b-icon icon="download" aria-hidden="true"></b-icon>
                </b-button>
              </div>
            </template>
          </b-table>
        </div>
      </b-card>
      <B-card v-if="!documents.length && !loading">
        <p>You do not have any documents at the moment</p>
      </B-card>

      <b-card v-if="loading" id="skeleton-loading">
        <b-skeleton-table v-if="loading" :rows="4" :columns="2" :table-props="{ bordered: false }"></b-skeleton-table>
      </b-card>
    </div>
    <ServerError500Vue :refreshFN="reload" v-else />

    <b-pagination
      v-if="docsLoaded && !error"
      v-model="currentPage"
      :per-page="perPage"
      :total-rows="rows"
      align="right"
      aria-controls="documents-table"
    ></b-pagination>
  </div>
</template>

<script>
import { userBackendClient } from '@repo/api/services/userBackend'
import helpers from '../../../helpers'
// import { saveAs } from 'file-saver'
import ServerError500Vue from '../../components/error-pages/ServerError500.vue'

export default {
  components: {
    ServerError500Vue
  },
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      fields: [
        { key: 'name', sortable: true, thStyle: { paddingLeft: '35.5px' } },
        { key: 'date', sortable: true, tdClass: 'align-middle' }
      ],
      filter: null,
      filteredRows: 0,
      AllUnreadLoaded: true
    }
  },
  methods: {
    markAsRead(doc) {
      const index = this.tableItems.findIndex((item) => item.relative_key === doc.relative_key)
      this.tableItems[index].loading = true
      userBackendClient({
        method: "post",
        url: "/toggle_document_unread",
        data: JSON.stringify({  relative_keys: [doc.relative_key]})
      }).then(() => {
          this.tableItems[index].loading = false

          if (doc.unread) {
            this.$store.commit('user/SET_UNREAD_DOCS_AMOUNT', this.unreadDocs - 1)
            this.$store.commit('user/UPDATE_DOCUMENT', { index, payload: false })
          } else {
            this.$store.commit('user/SET_UNREAD_DOCS_AMOUNT', this.unreadDocs + 1)
            this.$store.commit('user/UPDATE_DOCUMENT', { index, payload: true })
          }
        })
        .catch(() => {
          this.tableItems[index].loading = false
        })
    },
    toggleAllReadUnread() {
      this.AllUnreadLoaded = false

      this.$store
        .dispatch('user/toggleDocumentsUnread')
        .then(() => {
          this.AllUnreadLoaded = true
          if (this.unreadDocs) {
            this.$store.commit('user/SET_UNREAD_DOCS_AMOUNT', 0)
          } else {
            this.$store.commit('user/SET_UNREAD_DOCS_AMOUNT', this.tableItems.length)
          }
        })
        .catch(() => {
          this.AllUnreadLoaded = true
        })
    },
    getCertificate(doc) {
      this.$vs.loading()

      this.$store
        .dispatch('user/downloadDocument', doc.relative_key)
        .then((res) => {
          this.$vs.loading.close()

          const index = this.tableItems.findIndex((item) => item.relative_key === doc.relative_key)

          if (this.tableItems[index].unread === true) {
            this.$store.commit('user/SET_UNREAD_DOCS_AMOUNT', this.unreadDocs - 1)
          }

          this.tableItems[index].unread = false
          this.tableItems[index]._rowVariant = 'read'

          const base64 = res.data
          var link = document.createElement('a')
          link.innerHTML = ''
          link.download = doc.relative_key
          link.href = 'data:application/octet-stream;base64,' + base64
          document.body.appendChild(link)
          link.click()

          // ? code for showing PDF in app
          // var obj = document.createElement('object')
          // obj.style.width = '100%'
          // obj.style.height = '842pt'
          // obj.type = 'application/pdf'
          // obj.data = 'data:application/pdf;base64,' + base64
          // document.body.appendChild(obj)
          //?
        })
        .catch(() => {
          this.$vs.loading.close()
          helpers.ErrorAlert(this.$vs, 'Download failed, please try again')
        })
    },
    clearFilter() {
      this.filter = ''
      this.filteredRows = 0
      this.$router.push({ query: {} })
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.filteredRows = filteredItems.length
      this.currentPage = 1
    },
    selectChanged() {
      this.$router.push({ query: { filter: this.filter } })
    },
    ondropDownfilter(w) {
      this.$router.push({ query: { filter: w.target.innerHTML } })
    },
    reload() {
      this.$store.dispatch('user/getDocuments', { force: true })
    }
  },
  watch: {
    $route() {
      if (this.$route.query.filter) {
        this.filter = this.$route.query.filter
      }
    }
  },
  mounted() {
    if (this.$route.params.filter) {
      this.filter = this.$route.params.filter
    } else if (this.$route.query.filter) {
      this.filter = this.$route.query.filter
    }
  },
  computed: {
    docsLoaded() {
      return !this.loading && this.documents.length
    },
    unreadDocs() {
      return this.$store.state.user.UnreadDocuments
    },
    loading() {
      return this.$store.state.user.status.Documents.loading
    },
    error() {
      return this.$store.state.user.status.Documents.error
    },

    filterOptions() {
      const options = []
      this.documents.forEach((element) => {
        if (!options.includes(element.type) && element.type != '') {
          options.push(element.type)
        }
      })
      return options
    },
    documents() {
      return this.$store.state.user.Documents
    },
    tableItems() {
      return this.documents.map((item) => {
        return {
          ...item,
          name: item.display_name,
          date: item.last_modified,
          _rowVariant: item.unread ? 'unread' : 'read',
          loading: false
        }
      })
    },
    rows() {
      if (this.filteredRows) {
        return this.filteredRows
      }
      return this.tableItems.length
    }
  }
}
</script>
<style lang="scss">
.table-unread {
  background-color: rgba(200, 224, 235, 0.25);
}
.table-read {
  .notification-dot {
    cursor: pointer;
    height: 10px;
    width: 10px;
    background-color: transparent;
    border-radius: 50%;
    vertical-align: middle;
    display: inline-block;
    border: 1.5px solid var(--pfida-salmon);
  }
}
.notification-dot {
  cursor: pointer;
  height: 10px;
  width: 10px;
  background-color: var(--pfida-salmon);
  border-radius: 50%;
  vertical-align: middle;
  display: inline-block;
}

.loading-ring {
  display: inline-block;
  position: relative;
  width: 10px;
  height: 10px;
}
.loading-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  margin: 1px;
  border: 2px solid var(--pfida-salmon);
  border-radius: 50%;
  animation: loading-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--pfida-salmon) transparent transparent transparent;
}
.loading-ring div:nth-child(1) {
  animation-delay: -0.45s;
  top: -5px;
  left: -5px;
}
.loading-ring div:nth-child(2) {
  animation-delay: -0.3s;
  top: -5px;
  left: -5px;
}
.loading-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes loading-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
