<template>
  <div id="login">
    <div class="vx-card__title mb-4 text-center">
      <h4 class="mb-4">Login</h4>
      <p>Welcome back! Please login to your account.</p>
    </div>
    <div class="vx-col w-full">
      <b-form id="loginForm" @submit.prevent="logIn">
        <b-form-group id="input-group-email" :invalid-feedback="emailFeedback" :state="emailInputState" label="Email:"
          label-for="input-email">
          <b-input-group class="mb-2">
            <b-input-group-prepend is-text>
              <b-icon icon="person"></b-icon>
            </b-input-group-prepend>
            <b-form-input :state="emailInputState" id="input-email" v-model="email" type="email"
              required></b-form-input>
          </b-input-group>
        </b-form-group>

        <b-form-group id="input-group-password" :invalid-feedback="passwordFeedback" :state="passwordInputState"
          label="Password:" label-for="input-password">
          <b-input-group class="mb-2">
            <b-input-group-prepend is-text>
              <b-icon icon="lock"></b-icon>
            </b-input-group-prepend>
            <b-form-input id="input-password" :state="passwordInputState" v-model="password" type="password"
              required></b-form-input>
          </b-input-group>
        </b-form-group>

        <div class="vx-col w-full my-4">
          <router-link to="/forgot-password">Forgot Password?</router-link>
        </div>

        <div class="vx-col w-full mt-8">
          <b-button type="submit" :disabled="!validateForm" block class="hover:shadow-lg"
            variant="primary">Login</b-button>
        </div>
      </b-form>

      <div class="vx-col w-full mt-10">
        <span>Don't have an account? </span>
        <router-link :to="{ path: 'signup', query: { to: this.$router.currentRoute?.query?.to } }">Sign up</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { getErrorMessage } from '@repo/utils'
import helpers from '../../../helpers'
import * as Sentry from '@sentry/vue'

export default {
  data() {
    return {
      email: '',
      password: ''
    }
  },
  computed: {
    validateForm() {
      return this.email != '' && this.password != '' && helpers.emailRegex().test(this.email)
    },
    emailInputState() {
      if ((this.email?.length > 4 && helpers.emailRegex().test(this.email)) || this.email === '') {
        return null
      }
      return false
    },
    emailFeedback() {
      if (this.email.length < 4) {
        return 'Please enter a valid Email'
      }

      if (!helpers.emailRegex().test(this.email)) {
        return 'Please enter a valid Email'
      }
      return 'Email is required'
    },

    passwordInputState() {
      if (this.password?.length > 7 || this.password === '') {
        return null
      }
      return false
    },
    passwordFeedback() {
      if (this.password.length > 0) {
        return 'The password must be at least 8 characters'
      }
      return 'Password is required'
    }
  },
  methods: {
    onEnterClick() {
      if (this.validateForm) {
        this.logIn()
      }
    },
    logIn() {
      {
        this.$store.commit('RESET_USER_STATE_BEFORE_LOGIN')
        this.$vs.loading() // Loading

        const payload = {
          username: this.email.toLowerCase().replace(/ /g, ''),
          password: this.password
        }

        this.$store
          .dispatch(`auth/validateLoginCredentials`, payload)
          .then((response) => {
            if (response.data.data.ChallengeName === 'NEW_PASSWORD_REQUIRED') {
              this.$vs.loading?.close()

              //redirect to force password reset
              this.$router.push('/login/challenge-password')
            } else if (response.data.data.ChallengeName === 'SOFTWARE_TOKEN_MFA') {
              this.$vs.loading?.close()

              //Redirect to MFA page
              this.$router.push({ path: '/login/confirm', query: { to: this.$router.currentRoute?.query?.to } })
            } else if (response.data.data.ChallengeName === 'CONFIRM_EMAIL') {
              this.$vs.loading?.close()

              this.$router.push('/signup/confirm')
            } else {
              const accessToken = response?.data?.data?.AuthenticationResult?.AccessToken

              if (accessToken) {
                response = response.data
                const expiresIn = response.data.AuthenticationResult.ExpiresIn * 1000

                // fetch user details
                this.$store
                  .dispatch('user/getUser')
                  .then(() => {
                    this.$vs.loading?.close()

                    let expiryDate = Date.now() + expiresIn // 2 hours from now
                    // Set token expiry and user login status
                    this.$store.commit('auth/SET_EXPIRY_DATE', expiryDate.toString())
                    Sentry.setUser({ email: this.$store.state.user.details.email })

                    this.$store.commit('auth/SET_USERNAME', this.$store.state.user.details.username)

                    // remove auth email and session
                    this.$store.commit('auth/SET_EMAIL', '')
                    this.$store.commit('auth/SET_AUTH_SESSION', '')

                    this.$vs.loading?.close()
                    this.$store.dispatch('user/getDocuments')
                    this.$store.dispatch('user/getReferrals')

                    //! Call POST equity investment
                    //IF investor type is link then redirect to setting and show investor dec, none,link,
                    // block, link,none
                    let redirectTo
                    if (this.$store.getters['showInvestorTypePage']) {
                      redirectTo = "/investor-type"
                    }
                    this.$eventBus.emit("mife:login", { ...response.data.AuthenticationResult, redirectTo })
                  })
                  .catch((err) => {
                    helpers.notifyError(this.$vs, getErrorMessage(err))
                  })
              }
            }
          })
          .catch((error) => {
            this.$vs.loading?.close()
            if (error?.response?.data?.response?.status === 602) {
              this.$store.commit('auth/SET_EMAIL', this.email.toLowerCase())
              this.$router.push('/forgot-password/confirm').catch(() => { })
            } else {
              helpers.notifyError(this.$vs, getErrorMessage(error))
            }
          })
      }
    }
  },
  mounted() {
    if (this.$router.currentRoute?.query?.referral) {
      this.$store.commit('SET_REFERRAL', this.$router.currentRoute?.query?.referral)
    }
    // ? sets: equityInvestmentRedirect
    if (this.$router.currentRoute?.query?.company) {
      this.$store.commit('SET_EQUITY_INVESTMENT_INTEREST', this.$router.currentRoute?.query?.company)
    }
  }
}
</script>
