/*=========================================================================================
  File Name: moduleAuthActions.js
  Description: Auth Module Actions
==========================================================================================*/

import jwt from '../../http/requests/auth/jwt/index.js';
import userAPI from '../../http/requests/user/userAPI.js';

export default {
  /**
   * Signup user
   *
   * @param commit
   * @param payload
   * @returns {Promise<unknown>}
   */
  signUp({ commit }, payload) {
    return new Promise((resolve, reject) => {
      jwt
        .signUp(payload)
        .then((response) => {
          commit('SET_EMAIL', payload.email);
          commit('SET_PASSWORD', payload.password);

          if (response.data.data.ChallengeName === 'CONFIRM_EMAIL') {
            commit('SET_USERNAME', response.data.data.ChallengeParameters.USER_ID_FOR_SRP);
          } else {
            commit('SET_USERNAME', response.data.data.Username);
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  /**
   * confirm users email for signup
   *
   * @param commit
   * @param payload
   * @returns {Promise<unknown>}
   */
  signUpConfirm(_, payload) {
    return new Promise((resolve, reject) => {
      jwt
        .signUpConfirm(payload)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /**
   * Store's action to "validate a user's credentials"; calls
   * a real or mock API to validate the user.
   *
   * @param commit
   * @param payload
   * @returns {Promise<unknown>}
   */
  validateLoginCredentials({ commit }, payload) {
    return new Promise((resolve, reject) => {
      jwt
        .validateLoginCredentials(payload)
        .then((response) => {
          // Set bearer token in axios
          if (response.data.data.AuthenticationResult) {
            commit('SET_BEARER', response.data.data.AuthenticationResult.IdToken);
            commit('SET_ACCESS_TOKEN', response.data.data.AuthenticationResult.AccessToken);
          }

          if (response.data.data.ChallengeName === 'NEW_PASSWORD_REQUIRED') {
            commit('SET_EMAIL', payload.username);
            commit('SET_USERNAME', response.data.data.ChallengeParameters.USER_ID_FOR_SRP);
            commit('SET_AUTH_SESSION', response.data.data.Session);
            commit('SET_CHALLENGE_NAME', response.data.data.ChallengeName);
          } else if (response.data.data.ChallengeName === 'SOFTWARE_TOKEN_MFA') {
            commit('SET_EMAIL', payload.username);
            commit('SET_USERNAME', response.data.data.ChallengeParameters.USER_ID_FOR_SRP);
            commit('SET_AUTH_SESSION', response.data.data.Session);
            commit('SET_CHALLENGE_NAME', response.data.data.ChallengeName);
          } else if (response.data.data.ChallengeName === 'CONFIRM_EMAIL') {
            commit('SET_EMAIL', payload.username);
            commit('SET_PASSWORD', payload.password);
            commit('SET_USERNAME', response.data.data.ChallengeParameters.USER_ID_FOR_SRP);
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  deleteAccount(_, payload) {
    return new Promise((resolve, reject) => {
      jwt
        .deleteAccount(payload)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /**
   * Store's action to "validate login MFA"; calls a real or mock
   * API to validate the user.
   *
   * @param commit
   * @param payload
   * @returns {Promise<unknown>}
   */
  validateLoginMFA({ commit }, payload) {
    return new Promise((resolve, reject) => {
      jwt
        .validateLoginMFA(payload)
        .then((response) => {
          commit('user/TOGGLE_MFA', true, {
            root: true,
          });
          commit('SET_BEARER', response.data.data.AuthenticationResult.IdToken);
          commit('SET_ACCESS_TOKEN', response.data.data.AuthenticationResult.AccessToken);

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /**
   * Store's action to check if an email already exists in BE; calls
   * a real or mock API.
   *
   * @param commit
   * @param payload
   * @returns {Promise<unknown>}
   */
  checkEmailExists(_, payload) {
    return new Promise((resolve, reject) => {
      userAPI
        .checkEmailExists(payload)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /**
   * Store's action to "validate register MFA"; calls a real or mock
   * API to validate the user.
   *
   * @param commit
   * @param payload
   * @returns {Promise<unknown>}
   */
  validateRegisterMFA(_, payload) {
    return new Promise((resolve, reject) => {
      jwt
        .validateRegisterMFA(payload)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /**
   * Store's action to update a user's password; calls a real or mock
   * API to do this.
   *
   * @param commit
   * @param payload
   * @returns {Promise<unknown>}
   */
  updatePassword(_, payload) {
    return new Promise((resolve, reject) => {
      userAPI
        .updatePassword(payload)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  fetchAccessToken() {
    return new Promise((resolve) => {
      jwt.refreshToken().then((response) => {
        resolve(response);
      });
    });
  },

  /**
   * Resend confirmation code
   *
   * @param commit
   * @param payload
   * @returns {Promise<unknown>}
   */
  resendConfirmationCode(_, payload) {
    return new Promise((resolve, reject) => {
      jwt
        .resendConfirmationCode(payload)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /**
   * Initiate Forgot Password
   *
   * @param commit
   * @param payload
   * @returns {Promise<unknown>}
   */
  forgotPassword(_, payload) {
    return new Promise((resolve, reject) =>
      userAPI
        .forgotPassword(payload)
        .then((response) => resolve(response))
        .catch((error) => reject(error))
    );
  },

  /**
   * Confirm Forgot Password
   *
   * @param commit
   * @param payload
   * @returns {Promise<unknown>}
   */
  validateForgotMFA(_, payload) {
    return new Promise((resolve, reject) => {
      jwt
        .validateForgotMFA(payload)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  /**
   * Send confirmation to users email
   * @param commit
   * @param payload
   */
  verifyEmail(_, payload) {
    return new Promise((resolve, reject) => {
      jwt
        .verifyEmail(payload)
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  },

  /**
   * Send confirmation to users email
   * @param commit
   * @param payload
   */
  resendEmailCode({ commit }, payload) {
    return new Promise((resolve, reject) => {
      jwt
        .resendEmailCode(payload)
        .then((res) => {
          commit('user/SET_USER_DETAILS', payload);
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  },
};
