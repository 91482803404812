import * as Sentry from '@sentry/vue';

import store from '../store/store';

/**
 * Auth service
 */
class AuthService {
  normalLogout() {
    Sentry.setUser(null);
  }

  /**
   * Determine if the required auth tokens are set in local storage
   *
   * @returns {boolean|boolean}
   */
  isAuthenticated() {
    const authenticated =
      new Date(Date.now()) < new Date(Number(store.state.auth.tokenExpiry)) && store.getters['auth/accessToken'];

    return !!authenticated;
  }

  isFullUser() {
    return store.getters['userData'].full_sign_up;
  }
}

let authService = new AuthService();

export default authService;
