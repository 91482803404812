<template>
  <div>
    <b-modal
      v-bind="$attrs"
      centered
      :title="title"
      cancel-title="No"
      ok-title="Yes"
      ok-variant="warning"
      cancel-variant="primary"
    >
      <template #modal-title>
        {{ title }} <b-icon icon="exclamation-triangle-fill" variant="warning" font-scale="1.2"></b-icon>
      </template>
      <div v-html="message"></div>
      <div v-if="showProceedText">
        <br />
        <p>Are you sure you wish to proceed?</p>
      </div>

      <template #modal-footer="{ ok, cancel }">
        <b-button variant="warning" @click="onOk(ok)">{{ okText }}</b-button>
        <b-button v-if="!hideCancelBtn" variant="primary" @click="onCancel(cancel)">{{ cancelText }}</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Attention'
    },
    message: {
      type: String,
      default: ''
    },
    showProceedText: {
      type: Boolean,
      default: true
    },
    onOk: {
      type: Function,
      default: (ok) => {
        ok()
      }
    },
    onCancel: {
      type: Function,
      default: (cancel) => {
        cancel()
      }
    },
    hideCancelBtn: {
      type: Boolean,
      default: false
    },
    okText: {
      type: String,
      default: 'Yes'
    },
    cancelText: {
      type: String,
      default: 'No'
    }
  }
}
</script>
