<!-- =========================================================================================
    File Name: AgreementAnalyticsTab.vue
    Description: Agreement analytics tab
========================================================================================== -->

<template>
  <div class="mt-4" id="agreement-analytics">
    <div class="mb-10">
      <AnalyticsSummary v-on:updateIndex="setIndex()"></AnalyticsSummary>
    </div>

    <b-tabs pills card nav-class="text-secondary" active-nav-item-class="font-weight-bold bg-primary" justified>
      <b-tab title="Lifetime Profile" active>
        <div class="mb-6 mt-6">
          <LifetimeChart />
        </div>
      </b-tab>
      <b-tab title="Payment Profile" lazy>
        <div class="mb-6 mt-6">
          <PaymentProfileChart />
        </div>
      </b-tab>
      <b-tab title="Equity Profile %" lazy>
        <div class="mb-6 mt-6">
          <EquityProfile />
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import AnalyticsSummary from '../../../components/primary/home-provision-scheme/AnalyticsSummary.vue'
import LifetimeChart from '../../../components/primary/home-provision-scheme/LifetimeChart.vue'
import PaymentProfileChart from '../../../components/primary/home-provision-scheme/PaymentProfileChart.vue'
import EquityProfile from '../../../components/primary/home-provision-scheme/EquityProfile.vue'

export default {
  props: {
    index: {
      type: String,
      required: true
    }
  },
  components: {
    AnalyticsSummary,
    LifetimeChart,
    EquityProfile,
    PaymentProfileChart
  },
  methods: {
    setIndex() {
      this.$emit('updateIndex')
    }
  }
}
</script>

<style lang="scss">
.content-container {
  font-size: 16px;
  display: flex;
  justify-items: center;
  justify-content: center;
  margin-bottom: 40px;
}
</style>
