import { clientEnv } from './env';

// Add new tenant here and add a logic for it too
const tenants = {
  waafi: clientEnv.VITE_TENANT_ID === 'waafi-0hjE2',
  pfida: clientEnv.VITE_TENANT_ID === 'pfida-5a34D',
};

export type TenantsTypes = keyof typeof tenants;

/**
 * A function that allows us to check the tenant type
 *
 * @example
 * const isPfida = isTenant('pfida')
 */
export function isTenant<T extends TenantsTypes>(param: T): boolean {
  return tenants[param as TenantsTypes];
}
