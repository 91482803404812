import {
  SET_AFFORDABILITY_CALCULATOR_DETAILS,
  SET_AIP_DETAILS,
  SET_CALCULATOR_HISTORY,
  SET_COST_CALCULATOR_DETAILS,
  SET_COST_CALCULATOR_REPLACEMENT_TEXT,
  SET_COST_CALCULATOR_VISIBILITY,
  SET_COUNTRIES_LIST,
  SET_RENTAL_RATE,
} from './calculatorsMutationTypes.js';

const mutations = {
  [SET_AIP_DETAILS](state, payload) {
    state.AIP = { ...state.AIP, ...payload };
  },
  [SET_AFFORDABILITY_CALCULATOR_DETAILS](state, payload) {
    state.affordabilityCalculator = { ...state.affordabilityCalculator, ...payload };
  },
  [SET_COST_CALCULATOR_DETAILS](state, payload) {
    state.costCalculator = { ...state.costCalculator, ...payload };
  },
  [SET_COUNTRIES_LIST](state, payload) {
    state.countriesList = payload;
  },
  [SET_COST_CALCULATOR_VISIBILITY](state, payload) {
    state.showCostCalculator = payload;
  },
  [SET_COST_CALCULATOR_REPLACEMENT_TEXT](state, payload) {
    state.costCalculatorReplacementText = payload;
  },
  [SET_CALCULATOR_HISTORY](state, payload) {
    state.calculatorHistory = payload;
  },
  [SET_RENTAL_RATE](state, payload) {
    state.rentalRate = payload;
  },
};
export default mutations;
