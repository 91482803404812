<template>
  <b-form data-vv-scope="step-3">
    <div class="vx-row">
      <b-form-group
        class="vx-col md:w-1/2 w-full mt-2"
        id="input-propertyType-group"
        :invalid-feedback="propertyTypeFeedback"
        :state="propertyTypeInputState"
        label="Property type:"
      >
        <v-select
          name="property_type"
          v-model="propertyType"
          @input="propertyTypeChange"
          :reduce="(options) => options.value"
          :options="options.propertyTypeOptions"
          class="w-full select-large"
        />
      </b-form-group>

      <b-form-group
        class="vx-col md:w-1/2 w-full mt-2"
        id="input-financePurpose-group"
        :invalid-feedback="financePurposeFeedback"
        :state="financePurposeInputState"
        label="Purpose of finance"
      >
        <v-select
          name="finance_purpose"
          v-model="financePurpose"
          :reduce="(options) => options.value"
          :options="options.financePurposeOptions"
          class="w-full select-large"
        />
      </b-form-group>

      <b-form-group
        class="vx-col md:w-1/2 w-full mt-2"
        id="input-estimatedPropertyValue-group"
        :invalid-feedback="estimatedPropertyValueFeedback"
        :state="estimatedPropertyValueInputState"
        label="Estimated property value"
      >
        <CurrencyInput name="estimated_property_value" v-model="estimatedPropertyValue" :options="vCurrencyOptions" />
      </b-form-group>

      <b-form-group
        class="vx-col md:w-1/2 w-full mt-2"
        id="input-financeRequired-group"
        :invalid-feedback="financeRequiredFeedback"
        :state="financeRequiredInputState"
        label="Deposit"
      >
        <CurrencyInput name="deposit" v-model="financeRequired" :options="vCurrencyOptions" />
      </b-form-group>

      <b-form-group
        class="vx-col md:w-1/2 w-full mt-2"
        id="input-term-group"
        :invalid-feedback="termFeedback"
        :state="termInputState"
        label="Term"
      >
        <v-select
          name="term"
          v-model="term"
          :reduce="(options) => options.value"
          :options="options.termOptions"
          class="w-full select-large"
        />
      </b-form-group>

      <b-form-group
        class="vx-col md:w-1/2 w-full mt-2"
        id="input-isPropertyMainResidence-group"
        :invalid-feedback="isPropertyMainResidenceFeedback"
        :state="isPropertyMainResidenceInputState"
        label="Will the property be your main residence?"
      >
        <v-select
          name="is_main_residence"
          v-model="isPropertyMainResidence"
          :options="options.isPropertyMainResidenceOptions"
          :reduce="(options) => options.value"
          class="w-full select-large"
        />
      </b-form-group>

      <b-form-group
        class="vx-col md:w-1/2 w-full mt-2"
        id="input-isFirstTimeBuyer-group"
        :invalid-feedback="isFirstTimeBuyerFeedback"
        :state="isFirstTimeBuyerInputState"
        label="Are you a first time buyer?"
      >
        <v-select
          name="first_time_buyer"
          v-model="isFirstTimeBuyer"
          :options="options.isFirstTimeBuyerOptions"
          :reduce="(options) => options.value"
          class="w-full select-large"
        />
      </b-form-group>

      <b-form-group
        class="vx-col md:w-1/2 w-full mt-2"
        id="input-propertyLocation-group"
        :invalid-feedback="propertyLocationFeedback"
        :state="propertyLocationInputState"
        label="Where will the property be situated?"
      >
        <v-select
          name="property_location"
          v-model="propertyLocation"
          :options="options.propertyLocationOptions"
          :reduce="(options) => options.value"
          class="w-full select-large"
        />
      </b-form-group>

      <b-form-group
        class="vx-col md:w-1/2 w-full mt-2"
        id="input-isCommercialLet-group"
        v-if="propertyType === 'investment property'"
        :invalid-feedback="isCommercialLetFeedback"
        :state="isCommercialLetInputState"
        label="Please confirm your intention is to let the property out as a landlord for commercial gain"
      >
        <v-select
          name="commercial_let"
          v-model="isCommercialLet"
          :options="options.isLetOptions"
          :reduce="(options) => options.value"
          class="w-full select-large"
        />
      </b-form-group>

      <b-form-group
        class="vx-col md:w-1/2 w-full mt-2"
        id="input-willInhabit-group"
        v-if="propertyType === 'investment property'"
        :invalid-feedback="willInhabitFeedback"
        :state="willInhabitInputState"
        label="Please confirm you and any related persons will not live in the property for the duration of the agreement."
      >
        <v-select
          name="will_inhabit"
          v-model="willInhabit"
          :options="options.willInhabitOptions"
          :reduce="(options) => options.value"
          class="w-full select-large"
        />
      </b-form-group>

      <b-form-group
        class="vx-col md:w-1/2 w-full mt-2"
        id="input-haveInhabit-group"
        v-if="propertyType === 'investment property'"
        :invalid-feedback="haveInhabitFeedback"
        :state="haveInhabitInputState"
        label="Have you or a related person ever lived in the property?"
      >
        <v-select
          name="have_inhabited"
          v-model="HaveInhabit"
          :options="options.HaveInhabitOptions"
          :reduce="(options) => options.value"
          class="w-full select-large"
        />
      </b-form-group>

      <b-form-group
        class="vx-col md:w-1/2 w-full mt-2"
        id="input-ownOtherLets-group"
        v-if="propertyType === 'investment property'"
        :invalid-feedback="ownOtherLetsFeedback"
        :state="ownOtherLetsInputState"
        label="Do you own any other properties that you let out?"
      >
        <v-select
          name="own_other_lets"
          v-model="ownOtherLets"
          :options="options.ownOtherLetsOptions"
          :reduce="(options) => options.value"
          class="w-full select-large"
        />
      </b-form-group>
    </div>
  </b-form>
</template>

<script>
import createComputed from '../utils'
import vSelect from 'vue-select'
import CurrencyInput from '../../../components/primary/currency-input/CurrencyInput.vue'
import defaults from '../defaults'

export default {
  components: {
    vSelect,
    CurrencyInput
  },
  data() {
    return {
      vCurrencyOptions: {
        ...this.$constants.vCurrencyOptions,
        currencyDisplay: 'symbol'
      },
      options: {
        propertyTypeOptions: defaults.propertyTypeOptions,
        financePurposeOptions: defaults.financePurposeOptions,
        termOptions: defaults.termOptions,
        isPropertyMainResidenceOptions: defaults.yesNO,
        isFirstTimeBuyerOptions: defaults.yesNO,
        propertyLocationOptions: defaults.propertyLocationOptions,
        isLetOptions: defaults.yesNO,
        willInhabitOptions: defaults.willInhabitOptions,
        HaveInhabitOptions: defaults.yesNO,
        ownOtherLetsOptions: defaults.yesNO
      },
      touched: {
        propertyType: false,
        financePurpose: false,
        estimatedPropertyValue: false,
        financeRequired: false,
        term: false,
        isPropertyMainResidence: false,
        isFirstTimeBuyer: false,
        propertyLocation: false,
        isCommercialLet: false,
        willInhabit: false,
        HaveInhabit: false,
        ownOtherLets: false
      }
    }
  },

  methods: {
    propertyTypeChange(val) {
      //clear optional fields
      if (val != 'investment property') {
        this.isCommercialLet = null
        this.willInhabit = null
        this.HaveInhabit = null
        this.ownOtherLets = null
      }
    }
  },

  computed: {
    propertyType: createComputed('propertyType'),
    financePurpose: createComputed('financePurpose'),
    estimatedPropertyValue: createComputed('estimatedPropertyValue'),
    financeRequired: createComputed('financeRequired'),
    term: createComputed('term'),
    isPropertyMainResidence: createComputed('isPropertyMainResidence'),
    isFirstTimeBuyer: createComputed('isFirstTimeBuyer'),
    propertyLocation: createComputed('propertyLocation'),
    isCommercialLet: createComputed('isCommercialLet'),
    willInhabit: createComputed('willInhabit'),
    HaveInhabit: createComputed('HaveInhabit'),
    ownOtherLets: createComputed('ownOtherLets'),

    // Validation
    propertyTypeInputState() {
      if (!this.touched.propertyType && !this.propertyType) {
        return null
      }
      return !!this.propertyType
    },
    propertyTypeFeedback() {
      return 'This field is required'
    },
    financePurposeInputState() {
      if (!this.touched.financePurpose && !this.financePurpose) {
        return null
      }
      return !!this.financePurpose
    },
    financePurposeFeedback() {
      return 'This field is required'
    },
    estimatedPropertyValueInputState() {
      if (!this.touched.estimatedPropertyValue && !this.estimatedPropertyValue) {
        return null
      }
      return !!this.estimatedPropertyValue
    },
    estimatedPropertyValueFeedback() {
      return 'This field is required'
    },
    financeRequiredInputState() {
      if (!this.touched.financeRequired && !this.financeRequired) {
        return null
      }
      if (this.financeRequired < 10000) {
        return false
      }
      return !!this.financeRequired
    },
    financeRequiredFeedback() {
      if (this.financeRequired < 10000) {
        return 'Deposit must be £10,000'
      }
      return 'This field is required'
    },
    termInputState() {
      if (!this.touched.term && !this.term) {
        return null
      }
      return !!this.term
    },
    termFeedback() {
      return 'This field is required'
    },
    isPropertyMainResidenceInputState() {
      if (!this.touched.isPropertyMainResidence && !this.isPropertyMainResidence) {
        return null
      }
      return !!this.isPropertyMainResidence
    },
    isPropertyMainResidenceFeedback() {
      return 'This field is required'
    },
    isFirstTimeBuyerInputState() {
      if (!this.touched.isFirstTimeBuyer && !this.isFirstTimeBuyer) {
        return null
      }
      return !!this.isFirstTimeBuyer
    },
    isFirstTimeBuyerFeedback() {
      return 'This field is required'
    },
    propertyLocationInputState() {
      if (!this.touched.propertyLocation && !this.propertyLocation) {
        return null
      }
      return !!this.propertyLocation
    },
    propertyLocationFeedback() {
      return 'This field is required'
    },
    isCommercialLetInputState() {
      if (!this.touched.isCommercialLet && !this.isCommercialLet) {
        return null
      }
      return !!this.isCommercialLet
    },
    isCommercialLetFeedback() {
      return 'This field is required'
    },
    willInhabitInputState() {
      if (!this.touched.willInhabit && !this.willInhabit) {
        return null
      }
      return !!this.willInhabit
    },
    willInhabitFeedback() {
      return 'This field is required'
    },
    haveInhabitInputState() {
      if (!this.touched.HaveInhabit && !this.HaveInhabit) {
        return null
      }
      return !!this.HaveInhabit
    },
    haveInhabitFeedback() {
      return 'This field is required'
    },
    ownOtherLetsInputState() {
      if (!this.touched.ownOtherLets && !this.ownOtherLets) {
        return null
      }
      return !!this.ownOtherLets
    },
    ownOtherLetsFeedback() {
      return 'This field is required'
    },

    isValidated() {
      if (this.propertyType === 'investment property') {
        return (
          this.propertyTypeInputState &&
          this.financePurposeInputState &&
          this.estimatedPropertyValueInputState &&
          this.financeRequiredInputState &&
          this.termInputState &&
          this.isPropertyMainResidenceInputState &&
          this.isFirstTimeBuyerInputState &&
          this.propertyLocationInputState &&
          this.isCommercialLetInputState &&
          this.willInhabitInputState &&
          this.haveInhabitInputState &&
          this.ownOtherLetsInputState
        )
      } else {
        return (
          this.propertyTypeInputState &&
          this.financePurposeInputState &&
          this.estimatedPropertyValueInputState &&
          this.financeRequiredInputState &&
          this.termInputState &&
          this.isPropertyMainResidenceInputState &&
          this.isFirstTimeBuyerInputState &&
          this.propertyLocationInputState
        )
      }
    }
  }
}
</script>
