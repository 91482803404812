import { userBackendClient } from '@repo/api/services/userBackend';

import helpers from '../../../../helpers.js';

export default {
  /**
   * Signup to create a new account
   *
   * @param email
   * @param pwd
   * @returns {*}
   */
  signUp(payload) {
    return userBackendClient({
      method: 'post',
      url: `/sign_up`,
      data: JSON.stringify(payload),
    });
    // return userBackendClient.post(apiRoutes.backend('sign_up'), JSON.stringify(payload));
  },
  /**
   * confirm email code for signup
   *
   * @param email
   * @param pwd
   * @returns {*}
   */
  signUpConfirm(payload) {
    return userBackendClient({
      method: 'post',
      url: `/sign_up_confirm`,
      data: JSON.stringify(payload),
    });
    // return axios.post(apiRoutes.backend('sign_up_confirm'), JSON.stringify(payload));
  },

  /**
   * Validates the user's login credentials
   *
   * @param email
   * @param pwd
   * @returns {*}
   */
  async validateLoginCredentials({ username, password }) {
    const clientSecret = await helpers.generateUserSecret(username);

    return userBackendClient({
      method: 'post',
      url: `/login`,
      data: JSON.stringify({ username, password, client: 'web', client_secret: clientSecret }),
    });
    // return axios.post(
    //   apiRoutes.backend('login'),
    //   JSON.stringify({ username, password, client: 'web', client_secret: clientSecret })
    // );
  },

  deleteAccount(payload) {
    return userBackendClient({
      method: 'post',
      url: `/delete_account`,
      data: JSON.stringify(payload),
    });
    // return axios.post(apiRoutes.backend('delete_account'), JSON.stringify(payload), {
    //   headers: {
    //     'pf-access-token': store.getters['auth/accessToken'],
    //   },
    // });
  },

  /**
   * Validates the user's login MFA code
   * @param payload
   * @returns {*}
   */
  validateLoginMFA(payload) {
    // return axios.post("/api/auth/login-mfa", payload);
    return userBackendClient({
      method: 'post',
      url: `/login_auth_challenge`,
      data: JSON.stringify(payload),
    });
    // return axios.post(apiRoutes.backend('login_auth_challenge'), JSON.stringify(payload));
  },

  /**
   * Validates the user's register MFA code
   * @param payload
   * @returns {*}
   */
  validateRegisterMFA(payload) {
    return userBackendClient({
      method: 'post',
      url: `/sign_up_confirm`,
      data: JSON.stringify(payload),
    });
    // return axios.post(apiRoutes.backend('sign_up_confirm'), JSON.stringify(payload));
  },

  /**
   * Validates the user's forgot MFA code
   * @param payload
   * @returns {*}
   */
  validateForgotMFA(payload) {
    return userBackendClient({
      method: 'post',
      url: `/forgot_password_confirm`,
      data: JSON.stringify(payload),
    });
    // return axios.post(apiRoutes.backend('forgot_password_confirm'), JSON.stringify(payload));
  },

  /**
   * Resend confirmation code
   *
   * @param payload
   * @returns {*}
   */
  resendConfirmationCode(payload) {
    return userBackendClient({
      method: 'post',
      url: `/resend_confirmation_code`,
      data: JSON.stringify(payload),
    });
    // return axios.post(apiRoutes.backend('resend_confirmation_code'), JSON.stringify(payload));
  },
  /**
   * Send confirmation code to the users email
   * @param {*} payload
   * @returns
   */
  verifyEmail(payload) {
    const fullPayload = {
      attribute_name: 'email',
      code: payload.code,
    };
    return userBackendClient({
      method: 'post',
      url: `/verify_user_attribute`,
      data: JSON.stringify(fullPayload),
    });
    // return axios.post(apiRoutes.backend('verify_user_attribute'), JSON.stringify(fullPayload), {
    //   headers: {
    //     'pf-access-token': store.getters['auth/accessToken'],
    //   },
    // });
  },

  /**
   * Send confirmation code to the users email
   * @param {*} payload
   * @returns
   */
  async resendEmailCode(payload) {
    const clientSecret = await helpers.generateUserSecret(payload.email);

    const fullPayload = {
      email: payload.email,
      client: 'web',
      client_secret: clientSecret,
      attribute_name: 'email',
      is_user_confirmed: true,
    };

    return userBackendClient({
      method: 'post',
      url: `/resend_confirmation_code`,
      data: JSON.stringify(fullPayload),
    });
    // return axios.post(apiRoutes.backend('resend_confirmation_code'), JSON.stringify(fullPayload), {
    //   headers: {
    //     'pf-access-token': store.getters['auth/accessToken'],
    //   },
    // });
  },

  /**
   *
   * @returns {*}
   */
  async getUserPoolDetails() {
    return userBackendClient({
      method: 'get',
      url: '/user_pool_details',
    });
  },
};
