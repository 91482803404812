import { clientEnv } from '@repo/utils';

import { BaseUrl } from '../types';

const stage = clientEnv.VITE_APP_MODE;

export function getBaseUrl(baseUrl: BaseUrl) {
  if (typeof baseUrl === 'string') {
    return baseUrl;
  }
  return baseUrl[stage as keyof BaseUrl];
}
