<template>
  <b-form data-vv-scope="step-1">
    <div class="vx-row">
      <b-form-group
        class="vx-col md:w-1/2 w-full mt-5"
        id="input-fullName-group"
        :invalid-feedback="fullNameFeedback"
        :state="fullNameInputState"
        label="Full Name:"
        disabled
      >
        <b-form-input id="input-fullName" v-model="fullName" type="text" required></b-form-input>
      </b-form-group>

      <b-form-group
        class="vx-col md:w-1/2 w-full mt-5"
        id="input-email-group"
        :invalid-feedback="emailFeedback"
        :state="emailInputState"
        label="Email:"
        disabled
      >
        <b-form-input id="input-email" v-model="email" type="email" required></b-form-input>
      </b-form-group>

      <b-form-group
        class="vx-col md:w-1/2 w-full mt-5"
        id="input-nationality-group"
        :invalid-feedback="nationalityFeedback"
        :state="nationalityInputState"
        label="Nationality:"
      >
        <b-input-group size="lg">
          <v-select
            name="nationality"
            v-model="nationality"
            :options="options.nationalityOptions"
            :reduce="(options) => options.value"
            class="w-full select-large"
          />
        </b-input-group>
      </b-form-group>

      <b-form-group
        class="vx-col md:w-1/2 w-full mt-5"
        id="input-residence-group"
        :invalid-feedback="residenceFeedback"
        :state="residenceInputState"
        label="Country of residence:"
      >
        <b-input-group size="lg">
          <v-select
            name="country_residence"
            v-model="countryOfResidence"
            :options="options.countryOfResidenceOptions"
            :reduce="(options) => options.value"
            class="w-full select-large"
          />
        </b-input-group>
      </b-form-group>
    </div>
  </b-form>
</template>

<script>
import createComputed from '../utils'
import vSelect from 'vue-select'
import helpers from '../../../../helpers'

export default {
  components: {
    vSelect
  },
  data() {
    return {
      touched: {
        name: false,
        email: false,
        nationality: false,
        countryOfResidence: false
      },
      options: {
        nationalityOptions: this.$store.state.calculators.countriesList,
        countryOfResidenceOptions: this.$store.state.calculators.countriesList
      }
    }
  },
  mounted() {
    this.$store.dispatch('calculators/updateAIPData', {
      fullName: this.$store.state.calculators.AIP.fullName || this.$store.state.user.details.name,
      email: this.$store.state.calculators.AIP.email || this.$store.state.user.details.email,
      nationality: this.$store.state.calculators.AIP.nationality || 'United Kingdom',
      countryOfResidence: this.$store.state.calculators.AIP.countryOfResidence || 'United Kingdom'
    })
  },
  computed: {
    fullName: {
      get(vm) {
        return vm.$store.state.calculators.AIP?.fullName
      },
      set(val) {
        this.touched.name = true
        this.$store.dispatch('calculators/updateAIPData', {
          fullName: val || ''
        })
      }
    },
    email: {
      get(vm) {
        return vm.$store.state.calculators.AIP?.email
      },
      set(val) {
        this.$store.dispatch('calculators/updateAIPData', {
          email: val
        })
      }
    },
    nationality: createComputed('nationality'),
    countryOfResidence: createComputed('countryOfResidence'),
    // Validation
    fullNameInputState() {
      if (!this.touched.name && !this.fullName) {
        return null
      }
      return this.fullName?.length >= 1 && /^[a-zA-Z\-' ]+$/.test(this.fullName)
    },
    fullNameFeedback() {
      if (this.fullName?.length > 0) {
        return 'Please enter a valid Name'
      }
      return 'This field is required'
    },
    emailInputState() {
      if (!this.touched.email && !this.email) {
        return null
      }
      return this.email?.length > 4 && helpers.emailRegex().test(this.email)
    },
    emailFeedback() {
      if (this.email?.length > 0) {
        return 'Please enter a valid Email'
      }
      return 'This field is required'
    },
    nationalityInputState() {
      if (!this.touched.nationality && !this.nationality) {
        return null
      }
      return this.nationality?.length >= 1
    },
    nationalityFeedback() {
      return 'This field is required'
    },
    residenceInputState() {
      if (!this.touched.countryOfResidence && !this.countryOfResidence) {
        return null
      }
      return this.countryOfResidence?.length >= 1
    },
    residenceFeedback() {
      return 'This field is required'
    },

    isValidated() {
      return this.fullNameInputState && this.emailInputState && this.nationalityInputState && this.residenceInputState
    }
  }
}
</script>
