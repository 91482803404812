<template>
  <div>
    <!-- <iframe
      v-if="url"
      id="JotFormIFrame-221013721081338"
      title="Additional Uploads(Investor)"
      onload="window.parent.scrollTo(0,0)"
      allowtransparency="true"
      allowfullscreen="true"
      allow="geolocation; microphone; camera"
      :src="url"
      frameborder="0"
      style="min-width: 100%; height: 539px; border: none"
      scrolling="yes"
      data-hj-allow-iframe="true"
    >
    </iframe> -->
  </div>
</template>

<script>
import { clientEnv } from '@repo/utils'

export default {
  computed: {
    url() {
      const environment = clientEnv.VITE_APP_MODE
      const form =
        environment === 'production' ? 'https://form.jotform.com/221013721081338' : 'https://form.jotform.com/221812443183349'

      return form
    }
  },
  created() {
    document.title = 'Pfida - Additional Uploads (Investor)'

    const url = 'https://form.jotform.com/221013721081338'
    window.location = url
  }
}
</script>

<style lang="scss" scoped>
iframe {
  height: calc(100vh - 4px) !important;
  width: calc(100vw - 4px) !important;
  box-sizing: border-box !important;
}
</style>
