<!-- =========================================================================================
  File Name: SettingsTab.vue
  Description: Settings
========================================================================================== -->

<template>
  <div id="settings-tab">
    <div class="vx-row grid">
      <div class="vx-col w-full sm:w-3/4 mb-base place-self-center">
        <vx-card>
          <h4 class="text-center mb-6">Would you like to reinvest your dividends?</h4>
          <p class="text-center mb-4">
            Please note all dividends will be reinvested by default on the date of award. If you would like your
            dividends paid out instead you must change this toggle each time.
          </p>

          <div id="radio-btns" class="flex justify-center content-container mb-1">
            <vs-radio v-model="divedendReinvest" id="radio-yes" class="m-4" vs-name="divedendReinvest"
              :vs-value="true">Yes</vs-radio>
            <vs-radio v-model="divedendReinvest" id="radio-no" class="m-4" vs-name="divedendReinvest"
              :vs-value="false">No</vs-radio>
          </div>

          <div class="justify-center text-center">
            <b-button class="shadow-md lg:mt-0 mt-6" variant="outline-primary" @click="setDividendReinvestment"
              :disabled="disableSwitch">
              Confirm
            </b-button>
          </div>
        </vx-card>
      </div>
    </div>

    <b-modal static id="no-bank-details-modal" centered title="Set your Bank Details" @close="revertRadioSelection"
      @hide="revertRadioSelection" v-model="popupActiveNoPaymentDetails">
      <p class="text-lg">
        To be able to pay dividends out to you, we need your bank details. Follow the link below to add your bank
        details
      </p>

      <template #modal-footer="{ ok }">
        <b-button type="submit" form="getHistory" size="lg" class="shadow-md lg:mt-0 mb-2" variant="light"
          @click.prevent="ok()">Cancel</b-button>
        <b-button type="submit" form="getHistory" size="lg" class="shadow-md lg:mt-0 mb-2" variant="primary"
          @click="goToPaymentDetails()">Take me there
        </b-button>
      </template>
    </b-modal>

    <b-modal id="modal-center" centered>
      <template #modal-title>
        {{ responseType }}
        <b-icon v-if="responseType === 'Error'" icon="exclamation-circle-fill" variant="danger"
          font-scale="1.2"></b-icon>
        <b-icon v-else icon="check-circle-fill" variant="success" font-scale="1.2"></b-icon>
      </template>

      {{ modalMessage }}
      <template #modal-footer="{ ok }">
        <b-button size="lg" class="shadow-md lg:mt-0 mb-2" variant="primary" @click="ok()">OK</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import userAPI from '../../../../http/requests/user/userAPI'

export default {
  props: {
    divedendReinvestment: {
      type: Boolean,
      default: true
    }
  },
  data: (props) => {
    return {
      modalMessage: '',
      responseType: '',
      divedendReinvest: props.divedendReinvestment,
      submitted: false,
      submittedValue: false,
      popupActiveNoPaymentDetails: false
    }
  },
  computed: {
    disableSwitch() {
      if (!this.submitted) {
        if (this.divedendReinvest != this.divedendReinvestment) {
          return false
        } else {
          return true
        }
      }
      if (this.divedendReinvest != this.submittedValue) {
        return false
      } else {
        return true
      }
    },
    bankDetailsExist() {
      return this.$store.getters['user/bankDetailsExist']
    },
    investmentDetails() {
      return this.$store.state.investmentDetails?.pfhpp
    }
  },
  methods: {
    goToPaymentDetails() {
      this.$router.push({ name: 'bank-details' })
    },
    revertRadioSelection() {
      this.divedendReinvest = !this.divedendReinvest
    },
    setDividendReinvestment() {
      // ?BankDetails
      // if (!this.divedendReinvest && !this.bankDetailsExist) {
      //   this.popupActiveNoPaymentDetails = true
      // } else {

      this.$vs.loading()
      const investmentProduct = this.investmentDetails[this.$route.params.index - 1].investment_product
      userAPI
        .postDividendsReinvestemnts(investmentProduct)
        .then((res) => {
          this.$vs.loading.close()
          this.submitted = true
          this.submittedValue = this.divedendReinvest
          const response = res.data.response
          this.modalMessage = response.messages[0]
          this.responseType = response.type
          this.$bvModal.show('modal-center')
        })
        .catch((res) => {
          this.$vs.loading.close()
          this.modalMessage = res.response.data.response.messages[0]
          this.responseType = 'Error'
          this.$bvModal.show('modal-center')
          this.revertRadioSelection()
        })
      // }
    }
  }
}
</script>

<style lang="scss" scoped></style>
