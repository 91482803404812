<!-- =========================================================================================
    File Name: ForgotPasswordMFA.vue
    Description: ForgotPassword MFA Page
========================================================================================== -->
<template>
  <div id="forgot-password-mfa">
    <div class="vx-card__title mb-2 text-center">
      <h4 class="mb-2">Confirmation code from Email</h4>
      <p class="mb-4">
        We've sent an email to <b>{{ email }}</b> with a confirmation code. Please enter it below along with your new
        password to reset your password.
      </p>
    </div>

    <div class="vx-col w-full">
      <b-form id="forgotPasswordForm" @submit.prevent="submitForgot">
        <b-form-group id="input-group-confirmation-code" :invalid-feedback="confirmationCodeFeedback"
          :state="confirmationCodeState" label="Confirmation code:" label-for="input-confirmation-code">
          <b-input-group class="mb-2">
            <b-input-group-prepend is-text>
              <b-icon icon="phone"></b-icon>
            </b-input-group-prepend>
            <b-form-input :state="confirmationCodeState" id="input-confirmation-code" v-model="code" type="number"
              required></b-form-input>
          </b-input-group>
        </b-form-group>

        <b-form-group id="input-group-password" :invalid-feedback="passwordFeedback" :state="passwordInputState"
          label="New password:" label-for="input-password">
          <b-input-group class="mb-2">
            <b-input-group-prepend is-text>
              <b-icon icon="lock"></b-icon>
            </b-input-group-prepend>
            <b-form-input :state="passwordInputState" id="input-password" v-model="password" type="password"
              required></b-form-input>
          </b-input-group>
        </b-form-group>

        <b-form-group id="input-group-confirm-password" :invalid-feedback="confirmPasswordFeedback"
          :state="confirmPasswordInputState" label="Confirm new password:" label-for="input-confirm-password">
          <b-input-group class="mb-2">
            <b-input-group-prepend is-text>
              <b-icon icon="lock"></b-icon>
            </b-input-group-prepend>
            <b-form-input :state="confirmPasswordInputState" id="input-confirm-password" v-model="confirm_password"
              type="password" required></b-form-input>
          </b-input-group>
        </b-form-group>
        <PasswordRules :password="password" class="mt-4" />

        <div class="vx-col w-full flex justify-between mt-8">
          <b-button variant="outline-primary" to="/forgot-password"
            class="hover:shadow-lg mr-2 md:w-2/12">Back</b-button>
          <b-button type="submit" variant="primary" :disabled="!validateForm"
            class="hover:shadow-lg px-4 w-9/12">Submit</b-button>
        </div>
      </b-form>
    </div>

    <div class="vx-col w-full mt-5">
      <a @click="resendActivationCode">Resend activation code</a>
    </div>
  </div>
</template>

<script>
import { getErrorMessage } from '@repo/utils'
import helpers from '../../../helpers'
import PasswordRules from '../../components/PasswordRules.vue'

export default {
  components: {
    PasswordRules
  },
  data() {
    return {
      email: this.$store.state.auth.authEmail,
      code: '',
      password: '',
      confirm_password: ''
    }
  },
  computed: {
    validateForm() {
      return (
        this.code != '' && this.password != '' && this.confirm_password != '' && this.confirm_password === this.password
      )
    },
    confirmationCodeFeedback() {
      if (this.code?.length > 0) {
        return 'MFA Code must be at least 5 characters'
      }
      return 'MFA Code is required'
    },
    confirmationCodeState() {
      if (this.code?.length > 4 || this.code === '') {
        return null
      }
      return false
    },
    passwordInputState() {
      if (
        (this.password?.length > 7 &&
          /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]).{8,}$/.test(
            this.password
          )) ||
        this.password === ''
      ) {
        return null
      }
      return false
    },
    passwordFeedback() {
      if (this.password.length < 7) {
        return 'The password must be at least 8 characters'
      }
      if (
        !/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]).{8,}$/.test(this.password)
      ) {
        return 'Password must match password policy'
      }
      return 'Password is required'
    },
    confirmPasswordInputState() {
      if (
        (this.confirm_password?.length > 7 && this.confirm_password === this.password) ||
        this.confirm_password === ''
      ) {
        return null
      }
      return false
    },
    confirmPasswordFeedback() {
      if (this.confirm_password.length < 7) {
        return 'The password must be at least 8 characters'
      }
      if (this.confirm_password != this.password) {
        return 'Passwords must match'
      }
      return 'Password is required'
    }
  },
  methods: {
    async submitForgot() {
      const clientSecret = await helpers.generateUserSecret(this.email)

      const payload = {
        email: this.email,
        client: 'web',
        client_secret: clientSecret,
        confirmation_code: this.code,
        password: this.password
      }

      this.$vs.loading()
      this.$store
        .dispatch('auth/validateForgotMFA', payload)
        .then(() => {
          this.$store.commit('auth/SET_EMAIL', '')
          helpers.notifySuccess(this.$vs, 'Your password has been updated. You can now login.')
          this.$router.push('/login')
          this.$vs.loading.close()
        })
        .catch((error) => {
          this.$vs.loading.close()
          helpers.notifyError(this.$vs, getErrorMessage(error))
        })
    },
    async resendActivationCode() {
      const clientSecret = await helpers.generateUserSecret(this.email)

      const payload = {
        email: this.email,
        client: 'web',
        client_secret: clientSecret
      }

      this.$store
        .dispatch('auth/forgotPassword', payload)
        .then(() => {
          helpers.notifySuccess(this.$vs, 'We have resent the code')
          this.$vs.loading.close()
        })
        .catch((error) => {
          this.$vs.loading.close()
          helpers.notifyError(this.$vs, getErrorMessage(error))
        })
    },
  },
}
</script>

<style lang="scss">
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

a {
  cursor: pointer !important;
}

.padding-bottom {
  padding-bottom: 2rem !important;
}
</style>
