/*=========================================================================================
  File Name: actions.js
  Description: Vuex Store - actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import authService from '../auth/authService';
import dipAPI from '../http/requests/dip/dipAPI';
import userAPI from '../http/requests/user/userAPI';

const actions = {
  // /////////////////////////////////////////////
  // COMPONENTS
  // /////////////////////////////////////////////

  // Vertical NavMenu
  updateVerticalNavMenuWidth({ commit }, width) {
    commit('UPDATE_VERTICAL_NAV_MENU_WIDTH', width);
  },

  // /////////////////////////////////////////////
  // UI
  // /////////////////////////////////////////////

  updateTheme({ commit }, val) {
    commit('UPDATE_THEME', val);
  },

  // /////////////////////////////////////////////
  // User/Account
  // /////////////////////////////////////////////

  getInvestmentDetails({ commit, state }, payload) {
    if (state.investmentDetails.pfhpp && !payload?.force) {
      return state.investmentDetails;
    }
    return new Promise((resolve, reject) => {
      userAPI
        .getInvestmentDetails()
        .then((res) => {
          commit('SET_INVESTMENT_DETAILS', res.data.data);
          resolve(res.data.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  getFinanceDetails({ commit, state }, payload) {
    if (state.financeDetails.length && !payload?.force) {
      return state.financeDetails;
    }
    return new Promise((resolve, reject) => {
      userAPI
        .getFinanceDetails()
        .then((response) => {
          // ! Calculate chart data here
          commit('SET_CHART_DATA', response.data.data);
          commit('SET_FINANCE_DETAILS', response.data.data);
          resolve(response.data.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  getHearAboutUs() {
    return new Promise((resolve, reject) => {
      userAPI
        .getHearAboutUs()
        .then((res) => resolve(res.data.data))
        .catch((e) => reject(e));
    });
  },

  logOut({ commit }) {
    authService.normalLogout();
    commit('RESET_STATE');
  },

  // /////////////////////////////////////////////
  // DIP
  // /////////////////////////////////////////////

  getDipApplications({ commit }) {
    return new Promise((resolve, reject) => {
      dipAPI
        .getDipApplications()
        .then((response) => {
          commit('SET_DIP_APPLICATIONS', response.data.data);
          resolve(response.data.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
};

export default actions;
