export default {
  employmentTypeOptions: [
    { label: 'Employed', value: 'employed' },
    { label: 'Homemaker', value: 'homemaker' },
    { label: 'Student', value: 'student' },
    { label: 'Self Employed', value: 'self-employed' },
  ],
  propertyTypeOptions: [
    { label: 'Private Property', value: 'private property' },
    { label: 'Investment Property', value: 'investment property' },
    { label: 'Ex-Council Property', value: 'ex-council property' },
    { label: 'Shared Ownership', value: 'shared ownership' },
    { label: 'Housing Association', value: 'housing association' },
  ],
  financePurposeOptions: [
    { label: 'New purchase', value: 'new purchase' },
    { label: 'Refinance', value: 'refinance' },
  ],
  termOptions: [
    { label: '30 years', value: '30' },
    { label: '29 years', value: '29' },
    { label: '28 years', value: '28' },
    { label: '27 years', value: '27' },
    { label: '26 years', value: '26' },
    { label: '25 years', value: '25' },
    { label: '24 years', value: '24' },
    { label: '23 years', value: '23' },
    { label: '22 years', value: '22' },
    { label: '21 years', value: '21' },
    { label: '20 years', value: '20' },
    { label: '19 years', value: '19' },
    { label: '18 years', value: '18' },
    { label: '17 years', value: '17' },
    { label: '16 years', value: '16' },
    { label: '15 years', value: '15' },
    { label: '14 years', value: '14' },
    { label: '13 years', value: '13' },
    { label: '12 years', value: '12' },
    { label: '11 years', value: '11' },
    { label: '10 years', value: '10' },
    { label: '9 years', value: '9' },
    { label: '8 years', value: '8' },
    { label: '7 years', value: '7' },
    { label: '6 years', value: '6' },
    { label: '5 years', value: '5' },
  ],
  propertyLocationOptions: [
    { label: 'England and Northern Ireland', value: 'england and northern ireland' },
    { label: 'Wales', value: 'wales' },
    // { label: 'Northern Ireland', value: 'northern ireland' },
    { label: 'Scotland', value: 'scotland' },
    { label: 'Rest of UK', value: 'rest of uk' },
    { label: 'Rest of the World', value: 'rest of the world' },
  ],
  willInhabitOptions: [
    { label: 'Agree', value: 'no' },
    { label: 'Disagree', value: 'yes' },
  ],
  yesNO: [
    { label: 'Yes', value: 'yes' },
    { label: 'No', value: 'no' },
  ],
  dict: {
    custom: {
      full_name: {
        required: 'This field is required',
        alpha: 'Full name may only contain alphabetic characters',
      },
      email: {
        required: 'This field is required',
        email: 'Please enter valid email',
      },
      nationality: {
        required: 'This field is required',
      },
      country_residence: {
        required: 'This field is required',
      },
      employment_type: {
        required: 'This field is required',
      },
      continuous_employment: {
        required: 'This field is required',
      },
      have_years_accounts: {
        required: 'This field is required',
      },
      gross_annual_income: {
        required: 'This field is required',
      },
      monthly_financial_commitments: {
        required: 'This field is required',
      },
      net_monthly_income: {
        required: 'This field is required',
      },
      history_bankruptcy: {
        required: 'This field is required',
      },
      property_type: {
        required: 'This field is required',
      },
      finance_purpose: {
        required: 'This field is required',
      },
      estimated_property_value: {
        required: 'This field is required',
      },
      deposit: {
        required: 'This field is required',
        min_value: 'Deposit must be £10,000',
      },
      term: {
        required: 'This field is required',
      },
      is_main_residence: {
        required: 'This field is required',
      },
      first_time_buyer: {
        required: 'This field is required',
      },
      property_location: {
        required: 'This field is required',
      },
      will_inhabit: {
        required: 'This field is required',
      },
      commercial_let: {
        required: 'This field is required',
      },
      have_inhabited: {
        required: 'This field is required',
      },
      own_other_lets: {
        required: 'This field is required',
      },
    },
  },
};
