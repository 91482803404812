<template>
  <div class="single-chart">
    <svg viewBox="0 0 36 36" class="circular-chart color" :style="cssVars">
      <path
        class="circle"
        :stroke-dasharray="strokeDasharray"
        d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
      />
      <text x="18" y="20.5" class="percentage" :class="{ shares: isShares }" :style="cssVars">
        {{ number }}
      </text>
    </svg>
  </div>
</template>

<script>
import helpers from '../../../../helpers'
export default {
  props: {
    percentage: {
      type: Number,
      default: 100
    },
    isShares: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: '#3c9ee5'
    },
    text: {
      type: [String, Number],
      default: '100'
    }
  },
  data(props) {
    return {
      strokeDasharray: `${props.percentage}, 100`
    }
  },
  computed: {
    cssVars() {
      return {
        /* variables you want to pass to css */
        '--color': this.color
      }
    },
    number() {
      return this.isShares ? helpers.numberFormat(this.text, 0, false) : this.text
    }
  }
}
</script>

<style lang="scss" scoped>
.circular-chart {
  display: block;
  margin: 10px auto;
  max-width: 80%;
  max-height: 250px;
}
.circle {
  fill: none;
  stroke-width: 2;
  stroke-linecap: round;
}

.circular-chart.color .circle {
  stroke: var(--color);
}
.percentage {
  fill: var(--color);
  font-size: 0.315em;
  text-anchor: middle;
}
</style>
