<template>
  <div id="login-mfa">
    <div class="vx-card__title mb-4 text-center">
      <h4 class="mb-4">Enter MFA code</h4>
      <p>Enter the code generated in your Authenticator app in order to proceed with your login.</p>
    </div>
    <div class="vx-col w-full">
      <b-form id="loginForm" @submit.prevent="login">
        <b-form-group id="input-group-code" :invalid-feedback="MFAFeedback" :state="MFAInputState" label="MFA Code:"
          label-for="input-mfa">
          <b-input-group class="mb-2">
            <b-input-group-prepend is-text>
              <b-icon icon="phone"></b-icon>
            </b-input-group-prepend>
            <b-form-input :state="MFAInputState" id="input-mfa" v-model="code" type="text" required></b-form-input>
          </b-input-group>
        </b-form-group>

        <div class="vx-row">
          <div class="vx-col w-full mt-5">
            <b-button type="submit" variant="primary" block :disabled="!validateForm"
              class="hover:shadow-lg">Submit</b-button>
          </div>
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
import { getErrorMessage } from '@repo/utils'
import helpers from '../../../helpers'
import userAPI from '../../../http/requests/user/userAPI'

export default {
  mounted() {
    // check that 'auth session' and 'auth email' is in local storage. If not, redirect to login page with error460
    if (!this.$store.state.auth.authEmail || !this.$store.state.auth.authSession) {
      helpers.notifyError(this.$vs, 'MFA auth not set! Please login first.')

      this.$router.push('/login').catch(() => { })
    }
  },
  data() {
    return {
      code: ''
    }
  },
  computed: {
    validateForm() {
      return this.code != ''
    },
    MFAFeedback() {
      if (this.code?.length > 0) {
        return 'MFA Code must be at least 5 characters'
      }
      return 'MFA is required'
    },
    MFAInputState() {
      if (this.code?.length > 4 || this.code === '') {
        return null
      }
      return false
    }
  },
  methods: {
    onEnterClick() {
      if (this.validateForm) {
        this.login()
      }
    },
    /**
     * Checks if the user is logged in
     */
    isLoggedIn() {
      if (this.$store.state.auth.isUserLoggedIn()) {
        this.$vs.loading.close() // Close animation if passed as payload
        helpers.notifyWarning(this.$vs, 'You are already logged in!')

        this.$router.push(this.$router.currentRoute.query.to || '/').catch(() => { })
      }

      return false
    },

    /**
     * Logs in the user
     */
    async login() {
      const clientSecret = await helpers.generateUserSecret(this.$store.state.auth.username)

      const payload = {
        challenge_name: this.$store.state.auth.challengeName,
        client: 'web',
        client_secret: clientSecret,
        challenge_responses: {
          USERNAME: this.$store.state.auth.username,
          SOFTWARE_TOKEN_MFA_CODE: this.code
        },
        session: this.$store.state.auth.authSession
      }

      this.$vs.loading() // Loading
      // validate user login MFA
      this.$store
        .dispatch('auth/validateLoginMFA', payload)
        .then((response) => {
          response = response.data
          // fetch user details
          const expiresIn = response.data.AuthenticationResult.ExpiresIn * 1000
          let expiryDate = Date.now() + expiresIn // 2 hours from now
          // Set token expiry and user login status
          this.$store.commit('auth/SET_EXPIRY_DATE', expiryDate.toString())

          this.$store.dispatch('user/getDocuments')
          this.$store.dispatch('user/getReferrals')
          this.updateLoginState()

          this.$store
            .dispatch('user/getUser')
            .then(() => {
              this.$vs.loading?.close()
              if (this.$store.state.referral || this.$store.state.equityInvestmentRedirect) {
                userAPI
                  .setReferred({
                    company: this.$store.state.equityInvestmentRedirect || 'pfhpp',
                    referral: this.$store.state.referral || '',
                  })
                  .catch(() => {
                    this.$store.commit('SET_REFERRAL', '', { root: true });
                  });
              }
              if (this.$store.getters['showInvestorTypePage']) {
                this.$router.push('/investor-type')
              } else {
                // navigate to admin dashboard
                this.$router.push(this.$router.currentRoute.query.to || '/').catch(() => { })
              }
            })
          this.$eventBus.emit("mife:login", response.data.AuthenticationResult)
        })
        .catch((error) => {
          this.$vs.loading.close()
          if (error?.response?.data?.response?.messages[0] === 'Error') {
            this.$router.push('/login').catch(() => { })
          }

          helpers.notifyError(this.$vs, getErrorMessage(error))
        })
    },
    /**
     * Add user login params to storage.
     *
     * @param response
     */
    updateLoginState() {
      // remove auth email and session
      this.$store.commit('auth/SET_EMAIL', '')
      this.$store.commit('auth/SET_AUTH_SESSION', '')
    }
  }
}
</script>
