import pino from 'pino';

import { clientEnv } from '../env';

export const logger = pino({
  transport: {
    target: 'pino-pretty',
    options: {
      colorize: true,
    },
  },
  browser: {
    disabled: clientEnv.VITE_APP_MODE !== 'development',
    serialize: true,
    asObject: true,
  },
});
