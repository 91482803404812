import { getItem, invariant, removeItem } from '@repo/utils';
import { jwtDecode } from 'jwt-decode';

import type { VuexAuth } from './schema';
import { IdTokenSchema, VuexAuthSchema } from './schema';

export const authLocalStorageKey = 'vuex';

function parseVuexStore(value: string): VuexAuth {
  const vuexStore = JSON.parse(value);
  return VuexAuthSchema.parse(vuexStore);
}

export function getTokens() {
  const vuexStore = getItem(authLocalStorageKey);
  invariant(vuexStore, "Vuex store doesn't exist");

  const parsedVuexStore = parseVuexStore(vuexStore);

  invariant(parsedVuexStore.auth.idToken || parsedVuexStore.auth.accessToken, "idToken or accessToken doesn't exist");

  return parsedVuexStore.auth;
}

export function removeToken() {
  removeItem(authLocalStorageKey);
}

export function parseIdToken(token: string) {
  const parsedToken = jwtDecode(token);
  return IdTokenSchema.parse(parsedToken);
}
