<template>
  <div>
    <!-- <iframe
      v-if="url"
      id="JotFormIFrame-211445196314351"
      title="Investor Registration Form"
      onload="window.parent.scrollTo(0,0)"
      allowtransparency="true"
      allowfullscreen="true"
      allow="geolocation; microphone; camera"
      :src="url"
      frameborder="0"
      style="min-width: 100%; height: 539px; border: none"
      scrolling="yes"
      data-hj-allow-iframe="true"
    >
    </iframe> -->
  </div>
</template>

<script>
import { clientEnv } from '@repo/utils';

export default {
  computed: {
    url() {
      const environment = clientEnv.VITE_APP_MODE
      const form =
        environment === 'production' ? 'https://form.jotform.com/211445196314351' : 'https://form.jotform.com/221743181924354'

      return this.$route.query.referral ? `${form}?referral=${this.$route.query.referral}` : form
    }
  },
  created() {
    document.title = 'Pfida - Investor Registration Form'
    const url = 'https://form.jotform.com/211445196314351'

    window.location = this.$route.query.referral ? `${url}?referral=${this.$route.query.referral}` : url
  }
}
</script>

<style lang="scss" scoped>
iframe {
  height: calc(100vh - 4px) !important;
  width: calc(100vw - 4px) !important;
  box-sizing: border-box !important;
}
</style>
