import { userBackendClient } from '@repo/api/services/userBackend';

import userAPI from '../../http/requests/user/userAPI';
import {
  SET_AFFORDABILITY_CALCULATOR_DETAILS,
  SET_AIP_DETAILS,
  SET_CALCULATOR_HISTORY,
  SET_COST_CALCULATOR_DETAILS,
  SET_COST_CALCULATOR_REPLACEMENT_TEXT,
  SET_COST_CALCULATOR_VISIBILITY,
  SET_COUNTRIES_LIST,
  SET_RENTAL_RATE,
} from './calculatorsMutationTypes.js';

const actions = {
  updateAIPData({ commit }, payload) {
    commit(SET_AIP_DETAILS, payload);
  },

  getCountriesList({ commit, state }, payload) {
    if (!state.countriesList.length) {
      return new Promise((resolve, reject) => {
        userAPI
          .getCountriesList(payload)
          .then((response) => {
            const countries_list = response.data.data.countries;
            commit(SET_COUNTRIES_LIST, countries_list);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    }
  },
  updateAffordabilityCalculatorData({ commit }, payload) {
    commit(SET_AFFORDABILITY_CALCULATOR_DETAILS, payload);
  },
  updateCostCalculatorData({ commit }, payload) {
    commit(SET_COST_CALCULATOR_DETAILS, payload);
  },
  getCostCalculatorAuthenticated({ commit }) {
    return new Promise((resolve, reject) => {
      userAPI
        .getCostCalculatorAuthenticated()
        .then((res) => {
          const response = res.data.data;

          commit(SET_COST_CALCULATOR_VISIBILITY, response.show_cost_calculator);
          commit(SET_RENTAL_RATE, response.show_rental_rates);
          commit(SET_COST_CALCULATOR_REPLACEMENT_TEXT, response.replacement_text);
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  calculateAffordabilityLoggedIn({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      userBackendClient({
        method: 'post',
        url: '/affordability_calculator_authenticated',
        data: JSON.stringify(payload),
      })
        .then((res) => {
          const response = res.data.data;
          dispatch('getCostCalculatorAuthenticated');
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  calculateAffordability(_, payload) {
    return new Promise((resolve, reject) => {
      userBackendClient({
        method: 'post',
        url: '/affordability_calculator',
        data: JSON.stringify(payload),
      })
        .then((res) => {
          const response = res.data.data;
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  calculateCost(_, payload) {
    return new Promise((resolve, reject) => {
      userAPI
        .calculateCost(payload)
        .then((res) => {
          const response = res.data.data;
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getCostCalculator({ commit }, payload) {
    return new Promise((resolve, reject) => {
      userAPI
        .getCostCalculator(payload)
        .then((res) => {
          const response = res.data.data;
          commit(SET_RENTAL_RATE, response.show_rental_rates);
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  calculateCostLoggedIn(_, payload) {
    return new Promise((resolve, reject) => {
      userAPI
        .calculateCostLoggedIn(payload)
        .then((res) => {
          const response = res.data.data;
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getCalculatorHistory({ commit, state, rootState }, payload) {
    if (state.calculatorHistory.length && !payload?.force) {
      return state.calculatorHistory;
    }
    return new Promise((resolve, reject) => {
      userAPI
        .getCalculatorHistory()
        .then((response) => {
          const calculationHistory = response.data.data.results;
          let costCalculatorData;
          const filteredHistory = calculationHistory.filter((i) => {
            if (i.type === 'Cost Calculator') {
              costCalculatorData = i;
            }
            return i.type != 'Cost Calculator';
          });

          let filteredRes = calculationHistory.find((element) => {
            return element.type === 'Affordability Calculator' && element.annual_income;
          });
          // if affordability result exists  and user doesnt have a finance agreement show cost calculator
          if (!rootState.financeDetails.length) {
            commit(SET_COST_CALCULATOR_VISIBILITY, !!filteredRes);
          }
          if (costCalculatorData) {
            costCalculatorData = {
              ...costCalculatorData,
              buy_to_let:
                costCalculatorData.buy_to_let === 1 //check if buyToLet is 1 or 0
                  ? { label: 'Buy-To-Let Purchase Plan (BPP)', value: 'Buy-To-Let Purchase Plan' }
                  : { label: 'Home Provision Scheme (HPS)', value: 'Home Provision Scheme' },
            };
            commit(SET_COST_CALCULATOR_DETAILS, costCalculatorData);
          }
          commit(SET_CALCULATOR_HISTORY, filteredHistory);

          resolve(calculationHistory);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
export default actions;
