const calculatorInitState = {
  AIP: {},
  countriesList: [],
  calculatorHistory: {},
  affordabilityCalculator: {},
  costCalculator: {},
  showCostCalculator: false,
  rentalRate: null,
  costCalculatorReplacementText: '',
};
export default calculatorInitState;
