<!-- =========================================================================================
  File Name: CostCalculator.vue
  Description: Cost Calculator
========================================================================================== -->
<template>
  <div>
    <div class="vx-row" id="cost-calculator-container">
      <div class="vx-col w-full md:w-1/2 mb-base">
        <vx-card>
          <b-form id="AffordabilityForm" @submit.prevent="calculate">
            <b-form-group
              v-if="showRentalRate"
              id="input-rentalRate-type"
              :invalid-feedback="rentalRateFeedback"
              :state="rentalRateInputState"
            >
              <label id="label"
                >Rental rate:
                <span>
                  <b-icon icon="info-circle" id="popover-manual-1" />
                  <b-popover container="cost-calculator-container" target="popover-manual-1" placement="top" variant="secondary" triggers="hover">
                    Preferential rates are only available to eligible customers who have successfully maintained a GYS
                    Home account throughout their waiting period. Click
                    <a href="https://www.pfida.com/savings/grow-your-savings/" target="_blank"
                      >here <b-icon icon="box-arrow-up-right"></b-icon
                    ></a>
                    for more information.
                  </b-popover>
                </span>
              </label>
              <b-input-group size="lg">
                <v-select
                  name="rentalRate"
                  v-model="rentalRate"
                  :options="rentalRateOptions"
                  :reduce="(options) => options.value"
                  class="w-full select-medium"
                />
              </b-input-group>
            </b-form-group>

            <b-form-group
              id="input-group-finance-type"
              :invalid-feedback="financeTypeFeedback"
              :state="financeTypeInputState"
              label="Finance type :"
            >
              <b-input-group size="lg">
                <v-select
                  name="financeType"
                  v-model="buy_to_let"
                  :options="buyToLetOptions"
                  class="w-full select-medium"
                />
              </b-input-group>
            </b-form-group>

            <b-form-group
              id="input-group-property-value"
              :invalid-feedback="propertyValueFeedback"
              :state="propertyValueInputState"
              label="Property value :"
            >
              <b-input-group size="lg">
                <b-input-group-prepend is-text> £ </b-input-group-prepend>
                <CurrencyInput
                  name="property"
                  v-model="property_value"
                  :options="vCurrencyOptions"
                  :fullLength="false"
                />
              </b-input-group>
            </b-form-group>

            <b-form-group
              id="input-group-deposit"
              :invalid-feedback="depositFeedback"
              :state="depositInputState"
              label="Deposit (Minimum 20%) :"
            >
              <b-input-group size="lg">
                <b-input-group-prepend is-text> £ </b-input-group-prepend>
                <CurrencyInput name="deposit" v-model="deposit" :options="vCurrencyOptions" :fullLength="false" />
              </b-input-group>
            </b-form-group>

            <b-form-group
              id="input-group-term"
              :invalid-feedback="termFeedback"
              :state="termInputState"
              label="Term (5-30 Years)"
            >
              <b-input-group size="lg">
                <b-form-input name="term" v-model="term" :options="vCurrencyOptions" />
                <b-input-group-append is-text>Years</b-input-group-append>
              </b-input-group>
            </b-form-group>

            <div class="vx-col mt-2">
              <b-button
                class="shadow-md hover:shadow-lg lg:mt-0 mb-2 mt-2 mr-4 p-2"
                variant="primary"
                size="lg"
                :disabled="!validateForm"
                type="submit"
                >Calculate</b-button
              >
              <b-button
                variant="outline-warning"
                size="lg"
                @click="resetForm"
                class="shadow-sm lg:mt-0 mb-2 mt-2 mr-4 p-2"
                >Reset</b-button
              >
            </div>
          </b-form>
        </vx-card>
      </div>

      <div class="vx-col w-full md:w-1/2 mb-base">
        <CostCalculatorEstimate
          :is-calculated="isCalculated"
          :arrangement_fee="arrangement_fee"
          :estimate_total_payments="estimate_total_payments"
          :total_payment_best_case="total_payment_best_case"
          :total_payment_mid_range="total_payment_mid_range"
          :total_payment_worst_case="total_payment_worst_case"
          :monthly_payment="monthly_payment"
          :property_value="formatted_property_value"
          :isBuyToLet="isBuyToLet"
          :term="term"
          :finance_amount="estimate_finance_amount"
        />
      </div>
    </div>
  </div>
</template>

<script>
import helpers from '../../../helpers'
import CostCalculatorEstimate from '../../components/primary/calculators/CostCalculatorEstimate.vue'
import vSelect from 'vue-select'
import CurrencyInput from '../../components/primary/currency-input/CurrencyInput.vue'
import { getErrorMessage } from '@repo/utils'

function createComputed(readVariable, defaultVal = null) {
  return {
    get() {
      const storedVal = this.$store.state.calculators.costCalculator[readVariable]
      return storedVal || defaultVal
    },
    set(val) {
      this.$store.dispatch('calculators/updateCostCalculatorData', {
        [readVariable]: val
      })
    }
  }
}
export default {
  components: {
    CurrencyInput,
    CostCalculatorEstimate,
    vSelect
  },
  data() {
    return {
      isCalculated: false,
      buyToLetOptions: [
        { label: 'Home Provision Scheme (HPS)', value: 'Home Provision Scheme' },
        { label: 'Buy-To-Let Purchase Plan (BPP)', value: 'Buy-To-Let Purchase Plan' }
      ],
      rentalRateOptions: [
        { label: 'Standard Rate', value: 'standard' },
        { label: 'Preferential Rate', value: 'preferential' }
      ],
      isBuyToLet: false,
      monthly_payment: 0,
      arrangement_fee: 0,
      estimate_total_payments: 0,
      estimate_finance_amount: 0,
      formatted_property_value: '',
      total_payment_best_case: 0,
      total_payment_mid_range: 0,
      total_payment_worst_case: 0,
      vCurrencyOptions: {
        ...this.$constants.vCurrencyOptions
      }
    }
  },
  computed: {
    showRentalRate() {
      return this.$store.state.calculators.rentalRate
    },
    rentalRate: createComputed('rentalRate'),
    //buy_to_let is returned by backend as 1 or 0 we use this to check the finance type
    buy_to_let: createComputed('buy_to_let'),
    property_value: createComputed('property_value'),
    deposit: createComputed('deposit'),
    term: createComputed('term'),
    rentalRateInputState() {
      if (!this.rentalRate) {
        return null
      }
      return !!this.rentalRate
    },
    rentalRateFeedback() {
      return 'This field is required'
    },
    financeTypeInputState() {
      if (!this.buy_to_let) {
        return null
      }
      return !!this.buy_to_let
    },
    financeTypeFeedback() {
      return 'This field is required'
    },
    propertyValueInputState() {
      if (!this.property_value) {
        return null
      }
      return this.property_value > 10000
    },
    propertyValueFeedback() {
      if (this.property_value > 0) {
        return 'Property value should be at least £10,000'
      }
      return 'This field is required'
    },
    depositInputState() {
      if (!this.deposit) {
        return null
      }
      return this.deposit >= 100
    },
    depositFeedback() {
      if (this.deposit > 0) {
        return 'Deposit should be at least £100'
      }
      return 'This field is required'
    },
    termInputState() {
      if (!this.term) {
        return null
      }
      return this.term >= 5 && this.term <= 30
    },
    termFeedback() {
      if (this.term?.length > 0) {
        return 'Please enter a term between 5 and 30'
      }
      return 'This field is required'
    },

    validateForm() {
      return (
        this.financeTypeInputState &&
        this.propertyValueInputState &&
        this.depositInputState &&
        this.termInputState &&
        this.rentalRateInputState
      )
    },
    isLoggedIn() {
      return this.$store.state.auth.isUserLoggedIn()
    }
  },
  methods: {
    resetForm() {
      this.isCalculated = false
      this.property_value = this.deposit = this.term = this.buy_to_let = this.rentalRate = ''
      this.monthly_payment = this.arrangement_fee = this.estimate_total_payments = 0
    },
    calculate() {
      const payload = {
        buy_to_let: this.buy_to_let?.value === 'Buy-To-Let Purchase Plan',
        property_value: parseInt(this.property_value),
        deposit: parseInt(this.deposit),
        term: parseInt(this.term)
      }
      if (this.buy_to_let?.value != 'Buy-To-Let Purchase Plan') {
        payload.property_location = 'England and Northern Ireland'
      }
      if (this.showRentalRate) {
        payload.rental_rate = this.rentalRate
      }
      this.$vs.loading()
      const url = this.$store.state.auth.isUserLoggedIn() ? 'calculateCostLoggedIn' : 'calculateCost'
      this.$store
        .dispatch(`calculators/${url}`, payload)
        .then((response) => {
          this.$vs.loading.close()
          if (this.isLoggedIn) {
            this.$store.dispatch('calculators/getCalculatorHistory', { force: true })
          }
          this.isCalculated = true

          if (this.buy_to_let?.value === 'Home Provision Scheme') {
            this.isBuyToLet = false
          } else {
            this.isBuyToLet = true
          }
          this.monthly_payment = response.monthly_payment
          this.arrangement_fee = response.arrangement_fee
          this.estimate_total_payments = response.total_payment
          this.estimate_finance_amount = response.finance_amount
          this.formatted_property_value = this.property_value
          this.total_payment_best_case = response.total_payment_best_case
          this.total_payment_mid_range = response.total_payment_mid_range
          this.total_payment_worst_case = response.total_payment_worst_case
        })
        .catch((error) => {
          this.$vs.loading.close()
          helpers.notifyError(this.$vs, getErrorMessage(error))
        })
    }
  },
  created() {
    if (this.isLoggedIn) {
      this.$vs.loading()
      this.$store
        .dispatch('calculators/getCostCalculatorAuthenticated')
        .then(() => this.$vs.loading.close())
        .catch(() => this.$vs.loading.close())
    } else {
      this.$store
        .dispatch('calculators/getCostCalculator')
        .then(() => this.$vs.loading.close())
        .catch(() => this.$vs.loading.close())
    }
    // if user has not run the affordability calculator before redirect them there
    if (!this.$store.state.calculators.showCostCalculator && this.isLoggedIn) {
      this.$router.push('/calculators/affordability-calculator')
    }
    if (!this.$store.state.calculators.costCalculator?.property_value && this.isLoggedIn) {
      this.$vs.loading()
      // get latest result from history
      this.$store
        .dispatch('calculators/getCalculatorHistory')
        .then(() => this.$vs.loading.close())
        .catch(() => this.$vs.loading.close())
    }
    // if navigating from affordability calculator pre populate data with input/result from there
    if (this?.$route?.params?.propertyValue && this?.$route?.params?.deposit) {
      this.$store.dispatch('calculators/updateCostCalculatorData', {
        property_value: this?.$route?.params?.propertyValue,
        deposit: this?.$route?.params?.deposit
      })
    }
  }
}
</script>
