<!-- =========================================================================================
  File Name: Calculators.vue
  Description: Calculators
========================================================================================== -->

<template>
  <div>
    <div class="w-full mb-base" v-if="!loaded">
      <b-card>
        <b-skeleton animation="wave" width="85%"></b-skeleton>
        <b-skeleton animation="wave" width="55%"></b-skeleton>
        <b-skeleton animation="wave" width="70%"></b-skeleton>
        <b-skeleton animation="wave" width="80%"></b-skeleton>
      </b-card>
    </div>
    <div class="flex flex-col gap-6">
      <div class="flex flex-col sm:flex-row gap-6">
        <!-- Affordability calculator -->
        <div v-if="loaded" id="affordability-calc" class="w-full sm:w-1/2">
          <a href="/app/calculators/affordability-calculator"
            @click.prevent="$router.push('/calculators/affordability-calculator')" tabindex="-1">
            <vx-card class="hover-effect">
              <div class="flex items-center">
                <feather-icon icon="BriefcaseIcon" svgClasses="h-4 w-4"
                  class="cursor-pointer p-3 inline-flex rounded-full feather-icon select-none relative text-primary"
                  style="background: rgba(var(--vs-primary), 0.15)">
                </feather-icon>
                <h3 class="pl-2 mb-0">Affordability Calculator</h3>
              </div>
              <p class="mt-2">See how much you can afford.</p>
            </vx-card>
          </a>
        </div>
        <!-- Cost calculator card -->
        <div id="cost-calculator" class="w-full sm:w-1/2" v-if="showCostCalculator && loaded">
          <a href="/app/calculators/cost-calculator" @click.prevent="$router.push('/calculators/cost-calculator')"
            tabindex="-2">
            <vx-card class="hover-effect">
              <div class="flex items-center">
                <feather-icon icon="PercentIcon" svgClasses="h-4 w-4"
                  class="cursor-pointer p-3 inline-flex rounded-full feather-icon select-none relative text-primary"
                  style="background: rgba(var(--vs-primary), 0.15)">
                </feather-icon>
                <h3 class="pl-2 mb-0">Cost Calculator</h3>
              </div>
              <p class="mt-2">See how much it might cost.</p>
            </vx-card>
          </a>
        </div>
        <!-- Disablec calculator card -->
        <div id="disabled-cost-calculator" class="w-full sm:w-1/2 mb-base"
          v-if="!showCostCalculator && costCalculatorReplacementText && loaded">
          <a href="mailto:info@pfida.com" tabindex="-2">
            <vx-card class="disabled-card">
              <div class="flex items-center">
                <feather-icon icon="PercentIcon" svgClasses="h-4 w-4"
                  class="cursor-pointer p-3 inline-flex rounded-full feather-icon select-none relative text-primary"
                  style="background: rgba(var(--vs-primary), 0.15)">
                </feather-icon>
                <h3 class="pl-2 mb-0">Cost Calculator</h3>
              </div>
              <p class="mt-2">{{ costCalculatorReplacementText }}</p>
            </vx-card>
          </a>
        </div>
      </div>

      <!-- AIP -->
      <div id="aip" class="w-full sm:w-full mb-base">
        <a href="#" @click.prevent="checkRouter()" tabindex="-3">
          <vx-card class="hover-effect">
            <div class="flex items-center">
              <feather-icon icon="CrosshairIcon" svgClasses="h-4 w-4"
                class="cursor-pointer p-3 inline-flex rounded-full feather-icon select-none relative text-primary"
                style="background: rgba(var(--vs-primary), 0.15)">
              </feather-icon>
              <h3 class="pl-2 mb-0">Get an Acceptance in Principle</h3>
            </div>
            <p class="mt-2">Will not affect your credit rating.</p>
          </vx-card>
        </a>
      </div>

      <!-- Calculation History -->
      <div id="calc-history" class="w-full sm:w-full mb-base">
        <a href="/calculators/calculation-history" @click.prevent="$router.push('/calculators/calculation-history')"
          tabindex="-3">
          <vx-card class="hover-effect">
            <div class="flex items-center">
              <feather-icon icon="ArchiveIcon" svgClasses="h-4 w-4"
                class="cursor-pointer p-3 inline-flex rounded-full feather-icon select-none relative text-primary"
                style="background: rgba(var(--vs-primary), 0.15)">
              </feather-icon>
              <h3 class="pl-2 mb-0">Calculation History</h3>
            </div>
            <p class="mt-2">Check historic results.</p>
          </vx-card>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    checkRouter() {
      this.$router.push('/calculators/acceptance-principle')
    }
  },
  data() {
    return {
      loaded: false
    }
  },
  computed: {
    showCostCalculator() {
      return this.$store.state.calculators.showCostCalculator
    },
    costCalculatorReplacementText() {
      return this.$store.state.calculators.costCalculatorReplacementText
    }
  },
  created() {
    this.$store
      .dispatch('calculators/getCountriesList')
      .then(() => {
        this.$vs.loading()
        this.$store
          .dispatch('calculators/getCalculatorHistory')
          .then(() => {
            this.$vs.loading.close()
            this.$store
              .dispatch('calculators/getCostCalculatorAuthenticated')
              .then(() => {
                this.$vs.loading.close()
                this.loaded = true
              })
              .catch(() => {
                this.$vs.loading.close()
                this.loaded = true
              })
          })
          .catch(() => this.$vs.loading.close())
      })
      .catch(() => {
        this.$vs.loading.close()
      })
  }
}
</script>

<style lang="scss">
.hover-effect:hover {
  padding-left: 20px;
}

.disabled-card {
  background-color: lightgrey;
}
</style>
