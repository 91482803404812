import { clientEnv } from '@repo/utils';
import CryptoJS from 'crypto-js';
import md5 from 'md5';
import moment from 'moment';

export default {
  ErrorAlert(payload, text) {
    payload.dialog({
      buttonCancel: true,
      activeAlert: false,
      acceptText: 'OK',
      type: 'alert',
      color: 'danger',
      title: 'Oops',
      text: text,
    });
  },

  SuccessAlert(payload, text) {
    payload.dialog({
      buttonCancel: true,
      activeAlert: false,
      acceptText: 'OK',
      type: 'alert',
      color: 'success',
      title: 'Success',
      text: text,
    });
  },

  /**
   * Trigger success notification
   *
   * @param text
   */
  notifySuccess(payload, text, time = 4000) {
    payload.notify({
      title: 'Success',
      text: text,
      iconPack: 'feather',
      icon: 'icon-alert-check',
      color: 'success',
      time: time,
    });
  },

  /**
   * Trigger warning notification
   *
   * @param text
   */
  notifyWarning(payload, text) {
    payload.notify({
      title: 'Warning',
      text: text,
      iconPack: 'feather',
      icon: 'icon-alert-circle',
      color: 'warning',
    });
  },

  /**
   * Trigger error notification
   *
   * @param text
   */
  notifyError(payload, text, time = 4000) {
    payload.notify({
      title: 'Error',
      text: text,
      iconPack: 'feather',
      icon: 'icon-alert-circle',
      color: 'danger',
      time: time,
    });
  },

  /**
   * Derive the username from the given parameter (currently email)
   *
   * @param param
   * @returns {*}
   */
  getUsername(param) {
    return md5(param);
  },

  /**
   * Generate a client secret for a user
   *
   * @param param
   */
  async generateUserSecret(param) {
    const username = param;
    const message = username + clientEnv.VITE_CLIENT_ID;

    var hmac = CryptoJS.algo.HMAC.create(CryptoJS.algo.SHA256, clientEnv.VITE_CLIENT_SECRET);
    hmac.update(message);

    var hash = hmac.finalize();
    return CryptoJS.enc.Base64.stringify(hash);
  },

  generateMobile(mobile) {
    if (mobile.indexOf('0') === 0) return `+44${mobile.substring(1)}`;
    if (mobile.indexOf('44') === 0) return `+${mobile}`;
    return mobile;
  },

  localizeNumber(mobile) {
    if (mobile.indexOf('+44') === 0) return `0${mobile.substring(3)}`;
  },

  numberFormat(num, minimumFractionDigits = 0, currency = 'GBP') {
    let formatter;
    if (currency) {
      formatter = new Intl.NumberFormat('en-UK', {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: minimumFractionDigits,
      });
    } else {
      formatter = new Intl.NumberFormat('en-UK', {
        minimumFractionDigits: minimumFractionDigits,
      });
    }

    return formatter.format(num);
  },

  /**
   * Formats a number as currency
   * @param num
   * @returns {string}
   */
  formatCurrency(num) {
    return this.numberFormat(num);
  },

  /**
   * Formats a number as currency
   * @param num
   * @returns {string}
   */
  formatCurrencyDecimal(num) {
    return this.numberFormat(num, 2);
  },

  kFormatter(num) {
    return Math.abs(num) > 999
      ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + 'k'
      : Math.sign(num) * Math.abs(num);
  },

  formatNumber(num) {
    return parseFloat(num).toLocaleString();
  },
  /**
   * Calculates age based on dd/mm/yyyy birth date
   * @param dob
   * @returns {number}
   */
  calculateAge(dob) {
    let birth_date_parts = dob.split('/'),
      birth_date = new Date(birth_date_parts[2], birth_date_parts[1] - 1, birth_date_parts[0]);

    const diff_ms = Date.now() - birth_date.getTime(),
      age_dt = new Date(diff_ms);

    return Math.abs(age_dt.getUTCFullYear() - 1970);
  },

  /**
   * Formats date using to DD MM YYYY
   * @param value
   * @returns {*}
   */
  formatDate(value) {
    return moment(value).isValid() ? moment(value).format('DD/MM/YYYY') : 'Invalid date';
  },

  /**
   * returns Int when currency string passed
   * @param value
   * @returns {*}
   */
  currencyToInt(val) {
    return Number(val.replace(/[^0-9]+/g, ''));
  },
  emailRegex() {
    var reg = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
    return new RegExp(reg);
  },
};
