import helpers from '../helpers';

class filters {
  capitalize(value) {
    if (!value) return '';
    value = value.toString();
    let arr = value.split(' ');
    let capitalized_array = [];
    arr.forEach((word) => {
      let capitalized = word.charAt(0).toUpperCase() + word.slice(1);
      capitalized_array.push(capitalized);
    });
    return capitalized_array.join(' ');
  }
  currency_formatter_decimal(num) {
    return helpers.formatCurrencyDecimal(num);
  }
  currency_formatter(num) {
    return helpers.formatCurrency(num);
  }
}

export default new filters();
