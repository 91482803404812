import { getTokens } from '@repo/single-spa';
import { clientEnv, logger } from '@repo/utils';
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from 'axios';

async function onRequest(config: InternalAxiosRequestConfig): Promise<InternalAxiosRequestConfig> {
  const { method, url } = config;

  logger.info({
    type: 'REQUEST',
    method: method?.toUpperCase(),
    url,
  });

  // eslint-disable-next-line no-param-reassign
  config.headers['x-tenant-id'] = clientEnv.VITE_TENANT_ID;

  try {
    const { idToken, accessToken } = getTokens();
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${idToken}`;
    // eslint-disable-next-line no-param-reassign
    config.headers['pf-access-token'] = accessToken;
  } catch (e) {
    logger.error({
      type: 'REQUEST ERROR',
      errorMessage: e,
    });
  }

  return config;
}

function onResponse(response: AxiosResponse): AxiosResponse {
  const { method, url } = response.config;
  const { status } = response;

  logger.info({
    type: 'RESPONSE',
    method: method?.toUpperCase(),
    status,
    url,
  });

  return response;
}

function onErrorResponse(error: AxiosError | Error): Promise<AxiosError> {
  if (axios.isAxiosError(error)) {
    const { message } = error;
    const { method, url } = error.config as AxiosRequestConfig;
    const { status } = (error.response as AxiosResponse) ?? {};

    logger.error({
      type: 'RESPONSE ERROR',
      method: method?.toUpperCase(),
      url,
      status,
      message,
      errorMessage: error.message,
      errorResponse: error.response?.data,
    });

    // switch (status) {
    //   case 401: {
    //     // "Login required"
    //     break;
    //   }
    //   case 403: {
    //     // "Permission denied"
    //     break;
    //   }
    //   case 404: {
    //     // "Invalid request"
    //     break;
    //   }
    //   case 500: {
    //     // "Server error"
    //     break;
    //   }
    //   default: {
    //     // "Unknown error occurred"
    //     break;
    //   }
    // }
  }

  return Promise.reject(error);
}

export function setupInterceptors(instance: AxiosInstance): AxiosInstance {
  instance.interceptors.request.use(
    (config) => onRequest(config),
    (error) => onErrorResponse(error)
  );
  instance.interceptors.response.use(
    (config) => onResponse(config),
    (error) => onErrorResponse(error)
  );
  return instance;
}
