/// <reference types="vite/client" />

import { createEnv } from '@t3-oss/env-core';
import { z } from 'zod';

export const clientEnv = createEnv({
  clientPrefix: 'VITE_',
  client: {
    VITE_APP_MODE: z.enum(['development', 'production', 'staging', 'test']),
    VITE_CLIENT_ID: z.string().min(1),
    VITE_CLIENT_SECRET: z.string().min(1),
    VITE_CLIENT_AMPLIFY_USER_POOL_ID: z.string().min(1),
    VITE_TENANT_ID: z.enum(['waafi-0hjE2', 'pfida-5a34D']),
    VITE_GROWTHBOOK: z.string().catch('sdk-5bBlcI5DAZLjg9p'),
  },
  runtimeEnv: {
    ...import.meta.env,
    VITE_APP_MODE: import.meta.env.MODE,
  },
  emptyStringAsUndefined: true,
});

export type ClientEnv = typeof clientEnv;
