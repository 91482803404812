<template>
  <div class="mb-2">
    <h2 id="title" class="mt-4 mb-2 text-center">{{ title }}</h2>

    <div v-if="acceptance && acceptance.answer === 'yes'" id="answer-yes" class="mb-10">
      <div v-for="(message, index) in messages" :key="index">
        <div class="aip-results mb-2" v-html="message"></div>
      </div>
    </div>

    <div v-if="acceptance && acceptance.answer === 'no'" id="answer-no" class="mb-10">
      <div v-for="(message, index) in messages" :key="index">
        <div class="aip-results mb-2" v-html="message"></div>
      </div>
    </div>

    <div v-if="acceptance && acceptance.answer === 'maybe'" id="answer-maybe" class="mb-5">
      <div v-for="(message, index) in messages" class="mb-10" :key="index">
        <div class="aip-results mb-2" v-html="message"></div>
      </div>

      <p class="mb-2">Please get in touch with us, if you would like to discuss your needs.</p>
    </div>

    <div class="flex space-between align-center"></div>

    <div class="flex justify-between align-center items-start mt-2 mb-0">
      <div class="w-full">
        <b-button @click="goBack" id="edit-details" variant="outline-primary" class="w-full shadow-md"
          >Go back and edit your details <b-icon icon="pencil-square" class="ml-2" aria-hidden="true"></b-icon>
        </b-button>
      </div>
    </div>
    <div class="flex justify-between align-center items-start mt-2 mb-0">
      <div v-if="acceptance && acceptance.answer === 'maybe'" id="contact-us" class="w-full mr-2">
        <b-button href="mailto:Info@pfida.com" variant="outline-primary" class="w-full shadow-md"
          >Contact us <b-icon icon="envelope-fill" class="ml-2" aria-hidden="true"></b-icon>
        </b-button>
      </div>
      <div v-if="showButtons" id="check-historic-results-buttons" class="mr-2 w-full">
        <b-button
          variant="primary"
          id="check-historic-results"
          class="shadow-md w-full"
          block
          @click.prevent="() => goToPage('/calculators/calculation-history')"
          >Check historic results
        </b-button>
      </div>
      <div v-if="showButtons" class="w-full">
        <b-button
          id="finish-btn"
          class="shadow-md w-full"
          @click.prevent="() => goToPage('/dashboard')"
          variant="primary"
          >Finish
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AcceptanceInPrincipleResult',
  props: {
    title: {
      type: String,
      default: ''
    },
    acceptance: {
      type: Object,
      default: null
    },
    messages: {
      type: Array,
      default: null
    },
    showButtons: {
      type: Boolean,
      default: true
    },
    goToPage: {
      type: Function
    }
  },
  methods: {
    capitalize(str) {
      if (!str) {
        return ''
      }
      const arr = str.split(' ')
      for (let i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1)
      }
      return arr.join(' ')
    },
    setAIPDetails() {
      const { nationality, term } = this.acceptance

      this.$store.dispatch('calculators/updateAIPData', {
        fullName: this.$store.state.user.details.name,
        email: this.$store.state.user.details.email,
        nationality,
        countryOfResidence: this.acceptance.country,
        employmentType: this.acceptance.employment_type,
        continuousEmployment: this.acceptance.continuous_employment,
        haveYearsOfAccounts: this.acceptance.accounts_records,
        historyOfBankruptcy: this.acceptance.ccj_bankruptcy,
        propertyType: this.acceptance.property_type,
        financePurpose: this.acceptance.finance_purpose,
        isPropertyMainResidence: this.acceptance.main_residence,
        isFirstTimeBuyer: this?.acceptance?.first_time_buyer,
        propertyLocation: this.acceptance.property_location,
        term,
        grossAnnualIncome: this.acceptance.annual_income,
        netMonthlyIncome: this.acceptance.net_monthly_income,
        monthlyFinancialCommitments: this.acceptance.monthly_spend,
        estimatedPropertyValue: this.acceptance.property_value,
        financeRequired: this.acceptance.deposit,
        isCommercialLet:
          this.acceptance.property_type === 'investment property' && this.acceptance.commercial_let
            ? this.acceptance.commercial_let
            : undefined,
        willInhabit:
          this.acceptance.property_type === 'investment property' && this.acceptance.will_inhabit
            ? this.acceptance.will_inhabit
            : undefined,
        HaveInhabit:
          this.acceptance.property_type === 'investment property' && this.acceptance.have_inhabited
            ? this.acceptance.have_inhabited
            : undefined,
        ownOtherLets:
          this.acceptance.property_type === 'investment property' && this.acceptance.own_other_lets
            ? this.acceptance.own_other_lets
            : undefined
      })
    },
    goBack() {
      if (this.acceptance.deposit) {
        this.setAIPDetails()
      }
      this.$router.push({ name: 'acceptance-principle', params: { skipAIPCheck: true } })
    }
  }
}
</script>

<style>
.aip-results ul {
  margin: 0.5rem 0 0.5rem 2rem;
  list-style-type: initial !important;
}
.aip-results li {
  margin: 0.5rem 0 0.5rem 0;
}
</style>
