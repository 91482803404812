<template>
  <b-icon
    v-if="condition"
    icon="check-square-fill"
    class="rounded-circle border-primary rounded"
    variant="success"
    font-scale="1.2"
  ></b-icon>
  <b-icon
    v-else
    icon="x-circle-fill"
    class="rounded-circle border-primary rounded"
    variant="danger"
    font-scale="1.2"
  ></b-icon>
</template>

<script>
export default {
  props: {
    condition: {
      type: Boolean
    }
  }
}
</script>
