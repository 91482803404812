/*=========================================================================================
  File Name: moduleUserManagement.js
  Description: Calendar Module
==========================================================================================*/

import actions from './userModuleActions.js';
import userModuleGetters from './userModuleGetters.js';
import mutations from './userModuleMutations.js';
import state from './userModuleState.js';

export default {
  isRegistered: false,
  namespaced: true,
  state: state,
  getters: userModuleGetters,
  mutations: mutations,
  actions: actions,
};
