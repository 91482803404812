<template>
  <div id="signup-confirm">
    <div class="vx-col w-full">
      <div class="vx-card__title mb-2 text-center">
        <h4 class="mb-6 mt-4">Confirmation code</h4>
        <p class="mb-4">
          We have sent an email to <b>{{ email }}</b> with a confirmation code. Please enter it below
        </p>
      </div>
      <div class="vx-col w-full">
        <b-form id="loginForm" class="flex flex-col items-center w-100" @submit.prevent="signUp">
          <b-form-group
            id="input-group-confirmation-code"
            :invalid-feedback="confirmationCodeFeedback"
            :state="confirmationCodeState"
            label="Confirmation code:"
            label-for="input-confirmation-code"
          >
            <b-input-group class="mb-2">
              <b-input-group-prepend is-text>
                <b-icon icon="phone"></b-icon>
              </b-input-group-prepend>
              <b-form-input id="input-confirmation-code" v-model="code" size="lg" type="number"></b-form-input>
            </b-input-group>

            <div class="vx-col w-full mt-8">
              <b-button type="submit" :disabled="!validateForm" block class="hover:shadow-lg" variant="primary"
                >Confirm</b-button
              >
            </div>
          </b-form-group>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
import { getErrorMessage } from '@repo/utils'
import helpers from '../../../helpers'
import userAPI from '../../../http/requests/user/userAPI'
export default {
  data() {
    return {
      code: ''
    }
  },
  computed: {
    username() {
      return this.$store.state.auth.username
    },
    email() {
      return this.$store.state.auth.authEmail
    },
    password() {
      return this.$store.state.auth.password
    },
    confirmationCodeFeedback() {
      if (this.code?.length > 0) {
        return 'MFA Code must be at least 5 characters'
      }
      return 'MFA Code is required'
    },
    confirmationCodeState() {
      if (this.code === '') {
        return null
      }
      if (this.code?.length > 4) {
        return true
      }
      return false
    },
    validateForm() {
      return this.confirmationCodeState
    }
  },
  mounted() {
    //if no email in state redirect to signup
    if (!this.$store.state.auth.authEmail) {
      this.$router.push('/signup')
    }
  },
  methods: {
    async signUp() {
      const clientSecret = await helpers.generateUserSecret(this.username)

      const payload = {
        client: 'web',
        client_secret: clientSecret,
        confirmation_code: this.code,
        username: this.username
      }
      this.$vs.loading() // Loading

      this.$store
        .dispatch('auth/signUpConfirm', payload)
        .then(() => {
          const payload = {
            username: this.username,
            password: this.password
          }
          this.$store.dispatch(`auth/validateLoginCredentials`, payload).then((response) => {
            const accessToken = response?.data?.data?.AuthenticationResult?.AccessToken

            if (accessToken) {
              response = response.data
              const expiresIn = response.data.AuthenticationResult.ExpiresIn * 1000

              // fetch user details
              this.$store
                .dispatch('user/getUser')
                .then(() => {
                  this.$vs.loading.close()
                  this.$store.dispatch('user/getDocuments')
                  this.$store.dispatch('user/getReferrals')

                  let expiryDate = Date.now() + expiresIn // 2 hours from now

                  this.$store.commit('auth/SET_EXPIRY_DATE', expiryDate.toString())
                  this.$store.commit('auth/SET_USERNAME', this.$store.state.user.details.username)

                  // remove auth email and session
                  this.$store.commit('auth/SET_EMAIL', '')
                  this.$store.commit('auth/SET_AUTH_SESSION', '')

                  userAPI
                    .postMarketingPreferences({ value: this.$store.state.auth.marketing ? 'all' : 'none' })
                    .catch((err) => {
                      helpers.notifyError(this.$vs, getErrorMessage(err))
                    })

                    let redirectTo = "/"
                    if (this.$store.getters['showInvestorTypePage']) {
                      redirectTo = "/investor-type"
                    }
                    this.$eventBus.emit("mife:login", {...response.data.AuthenticationResult, redirectTo })
                })
                .catch((err) => {
                  this.$vs.loading.close()
                  helpers.notifyError(this.$vs, getErrorMessage(err))
                })
            }
          })
        })
        .catch((err) => {
          this.$vs.loading.close()
          helpers.notifyError(this.$vs, getErrorMessage(err))
        })
    }
  }
}
</script>

<style lang="scss" scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
</style>
