<template>
  <div class="layout--full-page">
    <b-card
      class="shadow vx-col flex items-center justify-center flex-col sm:w-3/4 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center"
    >
      <div style="font-size: 4rem" class="text-center">
        <b-icon icon="check2-circle" class="ml-2 text-center" variant="success" aria-hidden="true"></b-icon>
      </div>
      <h3 class="pl-2 mb-0 my-6 text-center">Thank You!</h3>
      <p>Your submission has been received</p>

      <b-button class="my-6" @click="$router.push('/')">Back to Home</b-button>
    </b-card>
  </div>
</template>

<script>
export default {}
</script>

<style lang="sass" scoped></style>
