/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  Object Structure:
                    path => router path
                    name => router name
                    component(lazy loading) => component to load
                    meta : {
                      rule => which user can have access (ACL)
                      breadcrumb => Add breadcrumb to specific page
                      pageTitle => Display title besides breadcrumb
                    }
==========================================================================================*/

import Vue from 'vue';
import Router from 'vue-router';

import FullPage from './layouts/full-page/FullPage.vue';
import Main from './layouts/main/Main.vue';
import Dashboard from './views/Dashboard.vue';
import AcceptanceInPrinciple from './views/pages/acceptance-in-principle/AcceptanceInPrinciple.vue';
import Result from './views/pages/acceptance-in-principle/Result.vue';
import AffordabilityCalculator from './views/pages/calculators/AffordabilityCalculator.vue';
import AnonymousAffordabilityCalculator from './views/pages/calculators/anonymousCalculators/AnonymousAffordabilityCalculator.vue';
import AnonymousCostCalculator from './views/pages/calculators/anonymousCalculators/AnonymousCostCalculator.vue';
import CalculationHistory from './views/pages/calculators/CalculationHistory.vue';
import Calculators from './views/pages/calculators/Calculators.vue';
import CostCalculator from './views/pages/calculators/CostCalculator.vue';
import Documents from './views/pages/documents/Documents.vue';
import ForgotPassword from './views/pages/forgot/ForgotPassword.vue';
import ForgotPasswordMFA from './views/pages/forgot/ForgotPasswordMFA.vue';
import GrowYourSavings from './views/pages/growYourSavings/GrowYourSavings.vue';
import HomeProvisionScheme from './views/pages/HomeProvisionScheme/HomeProvisionScheme.vue';
import EquityInvestorHub from './views/pages/investor-hub/EquityInvestorHub.vue';
import InvestorType from './views/pages/InvestorType/InvestorType.vue';
import LegalNotices from './views/pages/legalNotices/LegalNotices.vue';
import Login from './views/pages/login/Login.vue';
import LoginForceResetPassword from './views/pages/login/LoginForceResetPassword.vue';
import LoginMFA from './views/pages/login/LoginMFA.vue';
import AdditionalUploadsCustomer from './views/pages/other/AdditionalUploadsCustomer.vue';
import AdditionalUploadsInvestor from './views/pages/other/AdditionalUploadsInvestor.vue';
import BankDetails from './views/pages/other/BankDetails/BankDetails.vue';
import FinanceRequirements from './views/pages/other/FinanceRequirements.vue';
import IFAapproval from './views/pages/other/IFAapproval.vue';
import InvestorRegistration from './views/pages/other/InvestorRegistration.vue';
import SubscriptionForm from './views/pages/other/SubscriptionForm.vue';
import Referrals from './views/pages/referrals/Referrals.vue';
import SignUp from './views/pages/signUp/SignUp.vue';
import SignUpConfirm from './views/pages/signUp/SignUpConfirm.vue';
import UserSettings from './views/pages/user-settings/UserSettings.vue';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: '',
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      // =============================================================================
      // MAIN LAYOUT ROUTES (when your logged in)
      // =============================================================================
      path: '',
      component: Main,
      children: [
        // =============================================================================
        // Theme Routes
        // =============================================================================
        {
          // Done
          path: '/',
          // redirect: () => {
          //   if (!auth.isAuthenticated()) return '/login';
          //   return '/dashboard';
          // },
        },
        {
          // Done
          path: '/dashboard',
          name: 'dashboard',
          component: Dashboard,
          meta: {
            rule: 'editor',
            authRequired: true,
          },
        },

        // =============================================================================
        // Pages Routes
        // =============================================================================
        {
          // Done
          path: '/account-settings',
          name: 'account-settings',
          component: UserSettings,
          meta: {
            breadcrumb: [
              { title: 'Dashboard', url: '/' },
              { title: 'Account Settings', active: true },
            ],
            pageTitle: 'Settings',
            rule: 'editor',
            authRequired: true,
          },
        },
        {
          // DOne
          path: '/documents',
          name: 'documents',
          component: Documents,
          meta: {
            breadcrumb: [
              { title: 'Dashboard', url: '/' },
              { title: 'Documents', active: true },
            ],
            pageTitle: 'Documents',
            rule: 'editor',
            authRequired: true,
          },
        },
        {
          // DOne
          path: '/property-purchase-plan/:index',
          name: 'property-purchase-plan',
          component: HomeProvisionScheme,
          meta: {
            breadcrumb: [
              { title: 'Dashboard', url: '/' },
              { title: 'Property Purchase Plan', active: true },
            ],
            rule: 'editor',
            authRequired: true,
          },
        },
        {
          // Done
          path: '/grow-your-savings/:index',
          name: 'grow-your-savings',
          component: GrowYourSavings,
          meta: {
            breadcrumb: [
              { title: 'Dashboard', url: '/' },
              { title: 'Grow Your Savings', active: true },
            ],
            rule: 'editor',
            authRequired: true,
          },
        },
        {
          // Done
          path: '/calculators',
          name: 'calculators',
          component: Calculators,
          meta: {
            breadcrumb: [
              { title: 'Dashboard', url: '/' },
              { title: 'Calculators', active: true },
            ],
            pageTitle: 'Calculators',
            rule: 'editor',
            authRequired: true,
          },
        },

        {
          // DOne
          path: '/calculators/affordability-calculator',
          name: 'affordability-calculator',
          component: AffordabilityCalculator,
          meta: {
            breadcrumb: [
              { title: 'Dashboard', url: '/' },
              { title: 'Calculators', url: '/calculators' },
              { title: 'Affordability Calculator', active: true },
            ],
            pageTitle: 'Affordability Calculator',
            rule: 'editor',
            authRequired: true,
          },
        },

        {
          // DONE
          path: '/calculators/cost-calculator',
          name: 'cost-calculator',
          component: CostCalculator,
          meta: {
            breadcrumb: [
              { title: 'Dashboard', url: '/' },
              { title: 'Calculators', url: '/calculators' },
              { title: 'Cost Calculator', active: true },
            ],
            pageTitle: 'Cost Calculator',
            rule: 'editor',
            authRequired: true,
          },
        },

        {
          // DONE
          path: '/calculators/acceptance-principle',
          name: 'acceptance-principle',
          component: AcceptanceInPrinciple,
          meta: {
            breadcrumb: [
              { title: 'Dashboard', url: '/' },
              { title: 'Calculators', url: '/calculators' },
              { title: 'Acceptance in Principle', active: true },
            ],
            pageTitle: 'Acceptance in Principle',
            rule: 'editor',
            authRequired: true,
            fullUserRequired: true,
          },
        },
        {
          // DONE
          path: '/calculators/acceptance-principle/result',
          name: 'acceptance-principle-result',
          component: Result,
          meta: {
            breadcrumb: [
              { title: 'Dashboard', url: '/' },
              { title: 'Calculators', url: '/calculators' },
              { title: 'Acceptance in Principle', url: '/calculators/acceptance-principle' },
              { title: 'Result', active: true },
            ],
            pageTitle: 'AIP Result',
            rule: 'editor',
            authRequired: true,
            fullUserRequired: true,
          },
        },

        {
          path: '/calculators/calculation-history',
          name: 'calculation-history',
          component: CalculationHistory,
          meta: {
            breadcrumb: [
              { title: 'Dashboard', url: '/' },
              { title: 'Calculators', url: '/calculators' },
              { title: 'Calculation History', active: true },
            ],
            pageTitle: 'Calculation History',
            rule: 'editor',
            authRequired: true,
          },
        },
        {
          // DONE
          path: '/referrals',
          name: 'referrals',
          component: Referrals,
          meta: {
            breadcrumb: [
              { title: 'Dashboard', url: '/' },
              { title: 'Refer a Friend', active: true },
            ],
            pageTitle: 'Refer a Friend',
            rule: 'editor',
            authRequired: true,
          },
        },
        {
          // DONE
          path: '/legal-notices',
          name: 'legal-notices',
          component: LegalNotices,
          meta: {
            pageTitle: 'Privacy Statement',
            rule: 'editor',
            authRequired: true,
          },
        },
        {
          // DONE
          path: '/ifa-approval',
          name: 'ifa-approval',
          component: IFAapproval,
          meta: {
            breadcrumb: [
              { title: 'Dashboard', url: '/' },
              { title: 'IFA Approval', active: true },
            ],
            pageTitle: 'IFA Approval',
            rule: 'editor',
            authRequired: true,
          },
        },
        {
          // DONE
          path: '/investor-type',
          name: 'investor-type',
          component: InvestorType,
          meta: {
            breadcrumb: [
              { title: 'Dashboard', url: '/' },
              { title: 'Investor Type', active: true },
            ],
            pageTitle: 'Investor Type',
            rule: 'editor',
            authRequired: true,
          },
        },
        {
          path: '/bank-details',
          name: 'bank-details',
          component: BankDetails,
          meta: {
            breadcrumb: [
              { title: 'Dashboard', url: '/' },
              { title: 'Bank Details', active: true },
            ],
            pageTitle: 'Bank Details',
            rule: 'public',
          },
        },
        {
          path: '/investor-hub',
          name: 'investor-hub',
          component: EquityInvestorHub,
          meta: {
            rule: 'editor',
            authRequired: true,
          },
        },
      ],
    },

    // =============================================================================
    // FULL PAGE LAYOUTS (when not logged in)
    // =============================================================================
    {
      path: '',
      component: FullPage,
      children: [
        // =============================================================================
        // PAGES
        // =============================================================================
        {
          // DONE
          path: '/signup',
          name: 'signup',
          component: SignUp,
          meta: {
            rule: 'public',
          },
        },
        {
          //DONE
          path: '/signup/confirm',
          name: 'signup-confirm',
          component: SignUpConfirm,
          meta: {
            rule: 'public',
          },
        },
        {
          // DONE
          path: '/login',
          name: 'page-login',
          component: Login,
          meta: {
            rule: 'public',
          },
        },
        {
          // DONE
          path: '/login/confirm',
          name: 'page-login-confirm',
          component: LoginMFA,
          meta: {
            rule: 'public',
          },
        },
        {
          // DONE
          path: '/login/challenge-password',
          name: 'page-login-reset-password',
          component: LoginForceResetPassword,
          meta: {
            rule: 'public',
          },
        },
        {
          // DONE
          path: '/forgot-password',
          name: 'page-forgot-password',
          component: ForgotPassword,
          meta: {
            rule: 'public',
          },
        },
        {
          // DONE
          path: '/forgot-password/confirm',
          name: 'page-forgot-password-confirm',
          component: ForgotPasswordMFA,
          meta: {
            rule: 'public',
          },
        },
      ],
    },

    {
      // DONE
      path: '/subscription-form',
      name: 'subscription-form',
      component: SubscriptionForm,
      meta: {
        pageTitle: 'Subscription Form',
        rule: 'public',
      },
    },
    {
      // DONE
      path: '/investor-registration',
      name: 'investor-registration',
      component: InvestorRegistration,
      meta: {
        pageTitle: 'Investor Registration Form',
        rule: 'public',
      },
    },
    {
      // Done
      path: '/additional-uploads-investor',
      name: 'additional-uploads-investor',
      component: AdditionalUploadsInvestor,
      meta: {
        rule: 'public',
      },
    },
    {
      // DONE
      path: '/additional-uploads-customer',
      name: 'additional-uploads-customer',
      component: AdditionalUploadsCustomer,
      meta: {
        rule: 'public',
      },
    },
    {
      // DONE
      path: '/finance-requirements',
      name: 'finance-requirements',
      component: FinanceRequirements,
      meta: {
        rule: 'public',
      },
    },
    {
      // DONE
      path: '/cost-calculator',
      name: 'open-cost-calculator',
      component: AnonymousCostCalculator,
      meta: {
        breadcrumb: [{ title: 'Home', url: '/' }, { title: 'Pages' }, { title: 'Cost Calculator', active: true }],
        pageTitle: 'Cost Calculator',
        rule: 'public',
      },
    },
    {
      // DONE
      path: '/affordability-calculator',
      name: 'open-affordability-calculator',
      component: AnonymousAffordabilityCalculator,
      meta: {
        breadcrumb: [
          { title: 'Home', url: '/' },
          { title: 'Pages' },
          { title: 'Affordability Calculator', active: true },
        ],
        pageTitle: 'Affordability Calculator',
        rule: 'public',
      },
    },

    {
      path: '/app',
      redirect: '/',
    },
    {
      path: '/app/*',
      redirect: '/',
    },
  ],
});

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg');
  if (appLoading) {
    appLoading.style.display = 'none';
  }
});

// router.beforeEach((to, from, next) => {
//   // Specify the current path as the customState parameter, meaning it
//   // will be returned to the application after auth
//   // auth.login({ target: to.path });

//   // If auth required, check login. If login fails redirect to login page
//   if (to.meta.authRequired && !auth.isAuthenticated()) {
//     const loginpath = location.pathname + location.search;
//     store.dispatch('logOut');
//     next({ name: 'page-login', query: { to: loginpath } });
//   } else {
//     return next();
//   }
// });

export default router;
