<script>
import CurrencyInput from '../../components/primary/currency-input/CurrencyInput.vue';
import helpers from '../../../helpers';
import Logo from '../../../layouts/components/pics/Logo.vue';
import ThankYouVue from '../../components/ThankYou.vue';
import { userBackendClient } from '@repo/api/services/userBackend';
import { getErrorMessage } from '@repo/utils';

export default {
  components: { CurrencyInput, Logo, ThankYouVue },
  data() {
    return {
      vCurrencyOptions: {
        ...this.$constants.vCurrencyOptions,
      },
      options: {
        employmentStatusOptions: [
          { text: 'Permanent Employment', value: 'Permanent Employment' },
          { text: 'Part-time Employment', value: 'Part-time Employment' },
          { text: 'Self-Employed', value: 'Self-Employed' },
          { text: 'Unemployed', value: 'Unemployed' },
        ],
        financeTypeOptions: [
          { text: 'Home Provision Scheme', value: 'Home Provision Scheme' },
          { text: 'Buy-To-Let Purchase Plan', value: 'Buy-To-Let Purchase Plan' },
          { text: 'Other', value: 'other' },
        ],
        needsOptions: [
          { text: 'First Time Buyer', value: 'First Time Buyer' },
          { text: 'Refinancing', value: 'Refinancing' },
          { text: 'Second Property', value: 'Second Property' },
          { text: 'Other', value: 'other' },
        ],
        maxDepositOptions: [
          { text: '10%', value: '10%' },
          { text: '15%', value: '15%' },
          { text: '20%', value: '20%' },
          { text: '30%', value: '30%' },
          { text: '50%', value: '50%' },
          { text: 'Other', value: 'other' },
        ],
        financeTimeOptions: [
          { text: 'ASAP', value: '0-1 years' },
          { text: '1-2 years', value: '1-2 years' },
          { text: '2-3 years', value: '2-3 years' },
          { text: '4+ years', value: '4+ years' },
        ],
        earlyFinanceRequestedOptions: [
          { text: 'Yes', value: true },
          { text: 'No', value: false },
        ],
      },

      employmentStatus: '',
      financeType: '',
      financeTypeOther: '',
      needs: '',
      needsOther: '',
      propertyValue: 0,
      maxDeposit: '',
      maxDepositOther: '',
      financeTime: '',
      privacyStatementCheck: '',
      earlyFinanceRequested: null,
      FormSubmitSuccess: false,
      touched: {
        earlyFinanceRequested: false,
      },
    };
  },

  computed: {
    employmentStatusFeedback() {
      return 'This field is required';
    },
    employmentStatusInputState() {
      return this.employmentStatus?.length >= 0;
    },
    financeTypeFeedback() {
      return 'This field is required';
    },
    financeTypeInputState() {
      if (!this.financeType) {
        return null;
      }
      if (this.financeType === 'other') {
        return this.financeTypeOther?.length >= 1;
      }
      return this.financeType?.length >= 0;
    },
    needsFeedback() {
      return 'This field is required';
    },
    needsInputState() {
      if (!this.needs) {
        return null;
      }
      if (this.needs === 'other') {
        return this.needsOther?.length >= 1;
      }
      return this.needs?.length >= 0;
    },
    propertyValueFeedback() {
      if (this.propertyValue > 0) {
        return 'Property value should be at least £50,000';
      }
      return 'This field is required';
    },
    propertyValueInputState() {
      if (!this.propertyValue) {
        return null;
      }
      return this.propertyValue >= 50000;
    },
    maxDepositFeedback() {
      if (this.maxDeposit === 'other' && this.maxDepositOther?.length > 2) {
        return 'Invalid input';
      }
      return 'This field is required';
    },
    maxDepositInputState() {
      if (!this.maxDeposit) {
        return null;
      }
      if (this.maxDeposit === 'other') {
        return this.maxDepositOther?.length >= 1 && this.maxDepositOther?.length <= 2;
      }
      return this.maxDeposit?.length >= 0;
    },
    financeTimeFeedback() {
      return 'This field is required';
    },
    financeTimeInputState() {
      if (!this.financeTime) {
        return null;
      }
      return this.financeTime?.length >= 0;
    },
    privacyAgreementFeedback() {
      return 'Please confirm you have read and understood the data privacy policy';
    },
    earlyFinanceRequestedFeedback() {
      return 'This field is required';
    },
    privacyAgreementInputState() {
      return this.privacyStatementCheck;
    },
    earlyFinanceRequestedInputState() {
      if (!this.touched.earlyFinanceRequested) return null;
      return this.earlyFinanceRequested !== null;
    },
    validateForm() {
      return (
        this.employmentStatusInputState &&
        this.financeTypeInputState &&
        this.needsInputState &&
        this.propertyValueInputState &&
        this.maxDepositInputState &&
        this.financeTimeInputState &&
        this.earlyFinanceRequestedInputState
      );
    },
  },
  methods: {
    markTouched(field) {
      this.touched[field] = true;
    },
    submit(event) {
      const data = new FormData(event.target);
      const values = Object.fromEntries(data.entries());
      const payload = {
        deposit_percentage: this.maxDeposit === 'other' ? values.maxDepositOther : values.maxDeposit,
        employment: values.employmentStatus,
        finance_purpose: this.needs === 'other' ? values.needsOther : values.needs,
        finance_time: values.financeTime,
        product_type: this.financeType === 'other' ? values.financeTypeOther : values.financeType,
        property_value: parseInt(this.propertyValue),
        early_finance_requested: this.earlyFinanceRequested,
      };
      console.log({ payload });
      this.$vs.loading();

      userBackendClient({
        method: 'post',
        url: '/finance_requirements',
        data: JSON.stringify(payload),
      })
        .then((res) => {
          const message = res.data.response.messages[0];
          helpers.notifySuccess(this.$vs, message);
          this.FormSubmitSuccess = true;
          this.clearInput();
          this.$vs.loading.close();
        })
        .catch((err) => {
          helpers.notifyError(this.$vs, getErrorMessage(err));
          this.$vs.loading.close();
        });
    },
    clearInput() {
      this.employmentStatus = '';
      this.financeType = '';
      this.financeTypeOther = '';
      this.needs = '';
      this.needsOther = '';
      this.propertyValue = 0;
      this.maxDeposit = '';
      this.maxDepositOther = '';
      this.financeTime = '';
      this.earlyFinanceRequested = null;
    },
  },
};
</script>

<template>
  <b-container>
    <ThankYouVue v-if="FormSubmitSuccess" />
    <b-row class="justify-center" v-if="!FormSubmitSuccess">
      <b-card class="shadow m-4">
        <div class="text-center my-6">
          <Logo width="100" />
        </div>
        <h3 class="pl-2 my-6 text-center">Please let us know your finance requirements.</h3>

        <b-form id="FinanceRequirementsForm" class="flex flex-col items-center" @submit.prevent="submit">
          <!-- Employment status -->
          <b-form-group
            class="vx-col lg:w-1/2 w-full mt-5"
            label-class="font-weight-bold"
            id="input-employmentStatus-group"
            :invalid-feedback="employmentStatusFeedback"
            :state="employmentStatusInputState"
            label="Employment Status:"
          >
            <b-form-radio-group
              size="lg"
              id="employmentStatus-radios"
              v-model="employmentStatus"
              :options="options.employmentStatusOptions"
              name="employmentStatus"
            ></b-form-radio-group>
          </b-form-group>

          <b-form-group
            class="vx-col lg:w-1/2 w-full mt-5"
            label-class="font-weight-bold"
            id="input-financeType-group"
            :invalid-feedback="financeTypeFeedback"
            :state="financeTypeInputState"
            label="What kind of property finance are you looking for?"
          >
            <b-form-radio-group
              size="lg"
              id="financeType-radios"
              v-model="financeType"
              :options="options.financeTypeOptions"
              name="financeType"
            ></b-form-radio-group>
            <b-form-input
              size="lg"
              class="mt-2"
              name="financeTypeOther"
              v-if="financeType === 'other'"
              v-model="financeTypeOther"
            />
          </b-form-group>

          <b-form-group
            class="vx-col lg:w-1/2 w-full mt-5"
            label-class="font-weight-bold"
            id="input-needs-group"
            :invalid-feedback="needsFeedback"
            :state="needsInputState"
            label="Which option best describes your needs?"
          >
            <b-form-radio-group
              size="lg"
              id="needs-radios"
              v-model="needs"
              :options="options.needsOptions"
              name="needs"
            ></b-form-radio-group>
            <b-form-input size="lg" class="mt-2" name="needsOther" v-if="needs === 'other'" v-model="needsOther" />
          </b-form-group>

          <b-form-group
            class="vx-col lg:w-1/2 w-full mt-5"
            id="input-group-property-value"
            label-class="font-weight-bold"
            :invalid-feedback="propertyValueFeedback"
            :state="propertyValueInputState"
            label="What is the approximate value of the property you would be looking to get finance on?"
          >
            <b-input-group size="lg" name="propertyValue">
              <b-input-group-prepend is-text> £ </b-input-group-prepend>
              <CurrencyInput
                name="propertyValue"
                v-model="propertyValue"
                :options="vCurrencyOptions"
                :fullLength="false"
              />
            </b-input-group>
          </b-form-group>

          <b-form-group
            class="vx-col lg:w-1/2 w-full mt-5"
            label-class="font-weight-bold"
            id="input-maxDeposit-group"
            :invalid-feedback="maxDepositFeedback"
            :state="maxDepositInputState"
            label="What is the maximum deposit amount you could provide?"
          >
            <b-form-radio-group
              size="lg"
              id="maxDeposit-radios"
              v-model="maxDeposit"
              :options="options.maxDepositOptions"
              name="maxDeposit"
            ></b-form-radio-group>
            <b-form-input
              size="lg"
              class="mt-2"
              type="number"
              name="maxDepositOther"
              v-if="maxDeposit === 'other'"
              min="0"
              oninput="validity.valid||(value='');"
              v-model="maxDepositOther"
            />
          </b-form-group>

          <b-form-group
            class="vx-col lg:w-1/2 w-full mt-5"
            label-class="font-weight-bold"
            id="input-financeTime-group"
            :invalid-feedback="financeTimeFeedback"
            :state="financeTimeInputState"
            label="How soon would you be looking to get finance?"
          >
            <b-form-radio-group
              size="lg"
              id="financeTime-radios"
              v-model="financeTime"
              :options="options.financeTimeOptions"
              name="financeTime"
            ></b-form-radio-group>
          </b-form-group>

          <b-form-group
            class="vx-col lg:w-1/2 w-full mt-5"
            label-class="font-weight-bold"
            id="input-earlyFinanceRequested-group"
            :invalid-feedback="earlyFinanceRequestedFeedback"
            :state="earlyFinanceRequestedInputState"
            label="Finally, if an opportunity arose for you to obtain finance earlier than your current waiting list position, would this be of interest?"
          >
            <b-form-radio-group
              size="lg"
              id="earlyFinanceRequested-radios"
              v-model="earlyFinanceRequested"
              :options="options.earlyFinanceRequestedOptions"
              name="earlyFinanceRequested"
              @change="markTouched('earlyFinanceRequested')"
            ></b-form-radio-group>
          </b-form-group>

          <div class="vx-col mt-2">
            <b-button
              class="shadow-md hover:shadow-lg lg:mt-0 mb-2 mt-2 mr-4 p-2"
              variant="primary"
              size="lg"
              :disabled="!validateForm"
              type="submit"
              >Submit</b-button
            >
          </div>
        </b-form>
      </b-card>
    </b-row>
  </b-container>
</template>
