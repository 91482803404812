/*=========================================================================================
  File Name: store.js
  Description: Vuex store
==========================================================================================*/

import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';

import { plugin as eventBusPlugin } from '../plugins/eventBus.js';
import actions from './actions';
import moduleAuth from './authModule/AuthModule.js';
import moduleCalculators from './calculatorsModule/calculatorsModule';
import getters from './getters';
import mutations from './mutations';
import state from './state';
import userModule from './userModule/userModule.js';

Vue.use(Vuex);

const persister = new VuexPersistence();

export default new Vuex.Store({
  getters,
  mutations,
  state,
  actions,
  modules: {
    auth: moduleAuth,
    user: userModule,
    calculators: moduleCalculators,
  },
  strict: process.env.NODE_ENV !== 'production',
  plugins: [persister.plugin, eventBusPlugin],
});
