import { clientEnv } from '@repo/utils';

export default {
  vCurrencyOptions: {
    valueRange: { min: 0 },
    currency: 'GBP',
    currencyDisplay: 'hidden',
    hideCurrencySymbolOnFocus: false,
    hideGroupingSeparatorOnFocus: false,
    hideNegligibleDecimalDigitsOnFocus: false,
    autoDecimalDigits: false,
    autoSign: false,
    useGrouping: true,
    accountingSign: true,
  },
  colors: {
    pfidaDustBlue: '#a4c5d0',
    pfidaPurple: '#4b3e6d',
    pfidaSalmon: '#E58074',
    pfGray: '#d4d4d4',
    danger: '#eb4d38',
  },
  intercom_app_id: clientEnv.VITE_APP_MODE === 'production' ? 'q5x7zrlu' : 'mdu2156j',
};
