import { dipComponentClient } from '@repo/api/services/dipComponent';

export default {
  async getDipApplications() {
    return await dipComponentClient({
      method: 'get',
      url: '/user/applications',
    });
  },
};
