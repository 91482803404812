import Vue from 'vue';
import Vuesax from 'vuesax';

/*=========================================================================================
  File Name: themeConfig.js
  Description: Theme configuration
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: hhttp://www.themeforest.net/user/pixinvent
==========================================================================================*/

// var OldColors = {
//   primary: '#203556',
//   success: '#28C76F',
//   danger: '#EA5455',
//   warning: '#FF9F43',
//   dark: '#1E1E1E'
// }

// DEFAULTS
export const black = '#171717';
export const white = '#FFFFFF';
export const paper = '#F3F2F8';

// MAIN COLORS - VUESAX THEME COLORS
let colors = {
  primary: '#4B3E6D',
  success: '#28C76F',
  danger: '#EA5455',
  warning: '#F7DD6D',
  dark: '#1E1E1E',
};

// CONFIGS
const themeConfig = {
  disableCustomizer: true, // options[Boolean] : true, false(default)
  footerType: 'static', // options[String]  : static(default) / sticky / hidden
  hideScrollToTop: false, // options[Boolean] : true, false(default)
  mainLayoutType: 'vertical', // options[String]  : vertical(default) / horizontal
  navbarColor: '#fff', // options[String]  : HEX color / rgb / rgba / Valid HTML Color name - (default: #fff)
  navbarType: 'floating', // options[String]  : floating(default) / static / sticky / hidden
  routerTransition: 'zoom-fade', // options[String]  : zoom-fade / slide-fade / fade-bottom / fade / zoom-out / none(default)
  rtl: false, // options[Boolean] : true, false(default)
  sidebarCollapsed: false, // options[Boolean] : true, false(default)
  theme: 'semi-dark', // options[String]  : "light"(default), "dark", "semi-dark"
};

Vue.use(Vuesax, { theme: { colors }, rtl: themeConfig.rtl });

export default themeConfig;
