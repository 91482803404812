<template>
  <div class="mb-12" id="payment-profile">
    <div class="text-xl text-center font-bold">Payment Profile (Yrs)</div>
    <div class="content-container" style="font-weight: normal">Excludes initial equity amount</div>
    <apexchart height="500" :options="options" :series="chartSeries" />
  </div>
</template>

<script>
import helpers from '../../../../helpers'
export default {
  computed: {
    financeDetails() {
      const index = this.$route.params.index - 1
      return this.$store.state.financeDetails[index]
    },
    isSmallerScreen() {
      return this.$store.state.windowWidth < 768
    },
    options: {
      get: function name(props) {
        const colors = props.$constants.colors
        const options = {
          chart: {
            type: 'bar',
            height: 500,
            stacked: true,
            toolbar: {
              show: false
            },
            animations: {
              enabled: true,
              easing: 'easeinout',
              speed: 800,
              animateGradually: {
                enabled: true,
                delay: 150
              },
              dynamicAnimation: {
                enabled: true,
                speed: 350
              }
            }
          },
          plotOptions: {
            bar: {
              horizontal: false,
              borderRadius: 4,
              dataLabels: {
                orientation: 'vertical',
                hideOverflowingLabels: true
              }
            }
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            width: 4
          },
          yaxis: {
            max: this.chartData.paymentProfile.maxPayment,
            forceNiceScale: true,
            labels: {
              style: {
                fontSize: '16px',
                fontFamily: 'Figtree Variable'
              },
              formatter: function (val) {
                return '£' + helpers.kFormatter(val)
              }
            }
          },
          xaxis: {
            categories: props.isSmallerScreen
              ? props.chartData.paymentProfile.yearsResponsive
              : props.chartData.paymentProfile.years,
            axisBorder: {
              show: true,
              color: '#8e9699',
              height: 1,
              width: '100%',
              offsetX: 0,
              offsetY: 0
            },
            axisTicks: {
              show: true,
              borderType: 'solid',
              color: '#8e9699',
              height: 5,
              offsetX: 0,
              offsetY: 0
            },
            labels: {
              hideOverlappingLabels: true,
              rotate: -90,
              style: {
                fontSize: '16px',
                fontFamily: 'Figtree Variable'
              },
              formatter: function (val) {
                return val + 1
              },
              offsetX: 0,
              offsetY: 0
            }
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return helpers.formatCurrency(val)
              }
            },
            x: {
              formatter: function (val) {
                return `Year ${val + 1}`
              }
            },
            style: {
              fontSize: '16px',
              fontFamily: undefined
            },
            followCursor: true
          },
          colors: [colors.pfidaPurple, colors.pfidaDustBlue, colors.pfidaSalmon],
          legend: {
            position: 'bottom',
            horizontalAlign: 'center',
            offsetX: 40,
            fontSize: '16px',
            fontFamily: 'Figtree Variable',
            markers: { fillColors: [colors.pfidaPurple, colors.pfidaDustBlue, colors.pfidaSalmon] }
          },
          fill: {
            colors: [
              ({ seriesIndex, dataPointIndex }) => {
                const dataindex = props.isSmallerScreen
                  ? props.chartData.paymentProfile.yearsResponsive[dataPointIndex] + 1
                  : dataPointIndex + 1
                if (dataindex <= props.financeDetails.term_elapsed_years) {
                  if (seriesIndex === 0) {
                    return colors.pfidaPurple
                  } else if (seriesIndex === 1) {
                    return colors.pfidaDustBlue
                  }
                } else {
                  if (seriesIndex === 0) {
                    return '#725FA3'
                  } else if (seriesIndex === 1) {
                    return colors.pfidaDustBlue
                  }
                }
              }
            ],
            opacity: 1
          }
        }
        return options
      }
    },
    chartData() {
      const index = this.$route.params.index - 1
      return this.$store.state.chartsData[index]
    },
    chartSeries() {
      if (this.isSmallerScreen) {
        return this.chartData.paymentProfile.paymentsResponsive
      }
      return this.chartData.paymentProfile.payments
    }
  }
}
</script>
