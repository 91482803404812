import { userBackendClient } from '@repo/api/services/userBackend';

import store from '../../../store/store.js';

export default {
  /**
   * Get the user's details
   *
   * @param payload
   * @returns {*}
   */
  getUser(payload) {
    return userBackendClient({
      method: 'get',
      url: '/user_details',
      data: JSON.stringify(payload),
    });
  },

  /**
   * Update the user's details
   *
   * @param payload
   * @returns {*}
   */
  updateUser(payload) {
    return userBackendClient({
      method: 'post',
      url: '/update_profile',
      data: JSON.stringify(payload),
      headers: {
        'pf-access-token': store.getters['auth/accessToken'],
      },
    });
  },

  /**
   * Check if the email already exists
   * @param payload
   * @returns {*}
   */
  async checkEmailExists(payload) {
    return userBackendClient({
      method: 'get',
      url: '/list_users',
      data: JSON.stringify({ filter: 'email = "' + payload.email + '"' }),
    });
  },
  /**
   * Get the user's retirement details
   *
   * @returns {*}
   */
  getRetirementAge() {
    return userBackendClient({
      method: 'get',
      url: '/change_retirement_date',
    });
  },
  updateRetirementAge(payload) {
    return userBackendClient({
      method: 'post',
      url: '/change_retirement_date',
      data: JSON.stringify(payload),
    });
  },

  /**
   * Updates the user's password
   * @param payload
   * @returns {*}
   */
  updatePassword(payload) {
    return userBackendClient({
      method: 'post',
      url: '/change_password',
      data: JSON.stringify(payload),
    });
  },

  /**
   * Initiate forgot password
   *
   * @param email
   * @returns {*}
   */

  forgotPassword(payload) {
    return userBackendClient({
      method: 'post',
      url: '/forgot_password',
      data: JSON.stringify(payload),
    });
  },

  /**
   * getMFAStatus
   *
   * @param email
   * @returns {*}
   */

  getMFAStatus(payload) {
    return userBackendClient({
      method: 'get',
      url: '/toggle_mfa',
      ...payload,
    });
  },

  /**
   * toggleMFA
   *
   * @param email
   * @returns {*}
   */

  toggleMFA(payload) {
    return userBackendClient({
      method: 'post',
      url: '/toggle_mfa',
      data: JSON.stringify(payload),
    });
  },

  /**
   * getInvestmentDetails
   *
   * @param payload
   * @returns {*}
   */
  getInvestmentDetails() {
    return userBackendClient({
      method: 'get',
      url: '/investment_details',
    });
  },

  /**
   * getFinanceDetails
   *
   * @param payload
   * @returns {*}
   */
  getFinanceDetails(payload) {
    return userBackendClient({
      method: 'get',
      url: '/finance_details',
      data: JSON.stringify(payload),
    });
  },

  /** getZakatDate
   * @param payload
   * @returns {*}
   */
  getZakatDate(payload) {
    return userBackendClient({
      method: 'get',
      url: '/change_zakat_date',
      data: JSON.stringify(payload),
    });
  },

  /** updateZakatDate
   * @param payload
   * @returns {*}
   */
  updateZakatDate(payload) {
    return userBackendClient({
      method: 'post',
      url: '/change_zakat_date',
      data: JSON.stringify(payload),
    });
  },

  /** getCountriesList
   * @returns {*}
   */
  getCountriesList() {
    return userBackendClient({
      method: 'get',
      url: '/list_countries',
    });
  },

  /** getCalculatorHistory
   * @returns {*}
   */
  getCalculatorHistory() {
    return userBackendClient({
      method: 'get',
      url: '/calculator_history',
    });
  },
  calculateCostLoggedIn(payload) {
    return userBackendClient({
      method: 'post',
      url: '/cost_calculator_authenticated',
      data: JSON.stringify(payload),
    });
  },
  getCostCalculatorAuthenticated() {
    return userBackendClient({
      method: 'get',
      url: '/cost_calculator_authenticated',
    });
  },
  getCostCalculator() {
    return userBackendClient({
      method: 'get',
      url: '/cost_calculator',
    });
  },
  calculateCost(payload) {
    return userBackendClient({
      method: 'post',
      url: '/cost_calculator',
      data: JSON.stringify(payload),
    });
  },
  getDividendsReinvestemnts(investment_product) {
    return userBackendClient({
      method: 'get',
      url: '/toggle_dividends_reinvest',
      params: {
        investment_product: investment_product,
      },
    });
  },
  postDividendsReinvestemnts(investment_product) {
    return userBackendClient({
      method: 'post',
      url: '/toggle_dividends_reinvest',
      data: JSON.stringify({
        investment_product: investment_product,
      }),
    });
  },

  getUserDocuments() {
    return userBackendClient({
      method: 'get',
      url: '/list_user_objects',
    });
  },
  downloadDocument(payload) {
    return userBackendClient({
      method: 'get',
      url: '/get_user_object',
      params: {
        relative_key: payload,
      },
      headers: {
        Accept: 'application/pdf;base64,',
      },
    });
  },
  toggleDocumentsUnread() {
    return userBackendClient({
      method: 'post',
      url: '/toggle_document_unread',
    });
  },
  updateOneOffPayment(payload) {
    return userBackendClient({
      method: 'post',
      url: '/one_off_payment_notice',
      data: JSON.stringify(payload),
    });
  },
  getOneOffPayment(payload) {
    return userBackendClient({
      method: 'get',
      url: '/one_off_payment_notice',
      ...payload,
    });
  },
  deleteOneOffPayment(payload) {
    return userBackendClient({
      method: 'delete',
      url: '/one_off_payment_notice',
      ...payload,
    });
  },
  changeTargetPayment(payload) {
    return userBackendClient({
      method: 'post',
      url: '/change_monthly_payment',
      data: JSON.stringify(payload),
    });
  },
  changeExpectedPaymentSettings() {
    return userBackendClient({
      method: 'get',
      url: '/change_expected_payment_setting',
    });
  },
  postChangeExpectedPaymentSettings(payload) {
    return userBackendClient({
      method: 'post',
      url: '/change_expected_payment_setting',
      data: JSON.stringify(payload),
    });
  },
  changeUnexpectedPaymentSettings() {
    return userBackendClient({
      method: 'get',
      url: '/change_unexpected_payment_outcome',
    });
  },
  postChangeUnexpectedPaymentSettings(payload) {
    return userBackendClient({
      method: 'post',
      url: '/change_unexpected_payment_outcome',
      data: JSON.stringify(payload),
    });
  },
  getBankDetails() {
    return userBackendClient({
      method: 'get',
      url: '/bank_details',
    });
  },
  postBankDetails(payload) {
    return userBackendClient({
      method: 'post',
      url: '/bank_details',
      data: JSON.stringify(payload),
    });
  },
  downloadTransactionStatement(payload, headers) {
    return userBackendClient({
      method: 'post',
      url: '/download_transactions_statement',
      data: JSON.stringify(payload),
      ...headers,
    });
  },

  async getHearAboutUs() {
    return await userBackendClient({
      method: 'get',
      url: '/hear_about',
    });
  },
  async getReferrals() {
    return await userBackendClient({
      method: 'get',
      url: '/referrals',
    });
  },
  async setReferred(payload) {
    return await userBackendClient({
      method: 'post',
      url: '/referrals',
      data: JSON.stringify(payload),
    });
  },
  async setReferralSplit(payload) {
    return await userBackendClient({
      method: 'post',
      url: '/change_investor_credit_split',
      data: JSON.stringify({
        company: 'pfhpp',
        value: payload,
      }),
    });
  },
  async getReferralSettings() {
    return await userBackendClient({
      method: 'get',
      url: '/change_investor_credit_split',
      params: {
        company: 'pfhpp',
      },
    });
  },
  async postEquityInvestmentVisited() {
    return await userBackendClient({
      method: 'post',
      url: '/equity_investment_page_visited',
      data: {},
    });
  },
  async getIFAApproval(payload) {
    return await userBackendClient({
      method: 'get',
      url: '/ifa_approval',
      params: {
        company: 'pfhpp',
        ...payload,
      },
    });
  },
  async postIFAApproval(payload) {
    return await userBackendClient({
      method: 'post',
      url: '/ifa_approval',
      data: JSON.stringify(payload),
    });
  },
  async postMarketingPreferences(payload) {
    return await userBackendClient({
      method: 'post',
      url: '/marketing_preferences',
      data: JSON.stringify(payload),
    });
  },
  async getInvestorDeclaration() {
    return await userBackendClient({
      method: 'get',
      url: '/investor_declaration',
    });
  },
  async submitInvestorDeclaration(payload) {
    return await userBackendClient({
      method: 'post',
      url: '/investor_declaration',
      data: JSON.stringify({
        company: store.state.equityInvestmentRedirect || 'pfhpp',
        investor_type: payload,
      }),
    });
  },
  async sendSubscriptionForm() {
    return await userBackendClient({
      method: 'post',
      url: '/send_subscription_form',
    });
  },
};
