<template>
  <b-form data-vv-scope="step-2">
    <div class="vx-row">
      <b-form-group
        class="vx-col md:w-1/2 w-full mt-2"
        id="input-employmentType-group"
        :invalid-feedback="employmentTypeFeedback"
        :state="employmentTypeInputState"
        label="Employment Type:"
      >
        <v-select
          name="employment_type"
          v-model="employmentType"
          :options="options.employmentTypeOptions"
          :reduce="(options) => options.value"
          class="w-full select-large"
        />
      </b-form-group>

      <b-form-group
        class="vx-col md:w-1/2 w-full mt-2"
        id="input-continuousEmployment-group"
        :invalid-feedback="continuousEmploymentFeedback"
        :state="continuousEmploymentInputState"
        label="Have you had six months continuous employment, or 3 years self-employment?"
      >
        <v-select
          name="continuous_employment"
          v-model="continuousEmployment"
          :options="options.continuousEmploymentOptions"
          :reduce="(options) => options.value"
          class="w-full select-large"
        />
      </b-form-group>

      <b-form-group
        class="vx-col md:w-1/2 w-full mt-2"
        id="input-haveYearsOfAccounts-group"
        v-if="employmentType === 'self-employed'"
        :invalid-feedback="haveYearsOfAccountsFeedback"
        :state="haveYearsOfAccountsInputState"
        label="If self-employed, do you have 3 years of accounts?"
      >
        <v-select
          name="have_years_accounts"
          v-model="haveYearsOfAccounts"
          :options="options.haveYearsOfAccountsOptions"
          :reduce="(options) => options.value"
          class="w-full select-large"
        />
      </b-form-group>

      <b-form-group
        class="vx-col md:w-1/2 w-full mt-2"
        id="input-grossAnnualIncome-group"
        :invalid-feedback="grossAnnualIncomeFeedback"
        :state="grossAnnualIncomeInputState"
        label="Gross annual household income:"
      >
        <CurrencyInput name="gross_annual_income" v-model="grossAnnualIncome" :options="vCurrencyOptions" />
      </b-form-group>

      <b-form-group
        class="vx-col md:w-1/2 w-full mt-2"
        id="input-netMonthlyIncome-group"
        :invalid-feedback="netMonthlyIncomeFeedback"
        :state="netMonthlyIncomeInputState"
        label="Net monthly household income"
      >
        <CurrencyInput name="net_monthly_income" v-model="netMonthlyIncome" :options="vCurrencyOptions" />
      </b-form-group>

      <b-form-group
        class="vx-col md:w-1/2 w-full mt-2"
        id="input-monthlyFinancialCommitments-group"
        :invalid-feedback="monthlyFinancialCommitmentsFeedback"
        :state="monthlyFinancialCommitmentsInputState"
        label="Monthly financial commitments (excluding rent or mortgage)"
      >
        <CurrencyInput
          name="monthly_financial_commitments"
          v-model="monthlyFinancialCommitments"
          :options="vCurrencyOptions"
        />
      </b-form-group>

      <b-form-group
        class="vx-col md:w-1/2 w-full mt-5"
        id="input-historyOfBankruptcy-group"
        :invalid-feedback="historyOfBankruptcyFeedback"
        :state="historyOfBankruptcyInputState"
        label="Any history of Adverse Credit such as CCJs, Arrangements, Bankruptcy etc?"
      >
        <b-input-group size="lg">
          <v-select
            name="history_bankruptcy"
            v-model="historyOfBankruptcy"
            :options="options.historyOfBankruptcyOptions"
            :reduce="(options) => options.value"
            class="w-full select-large"
          />
        </b-input-group>
      </b-form-group>
    </div>
  </b-form>
</template>

<script>
import createComputed from '../utils'
import vSelect from 'vue-select'
import CurrencyInput from '../../../components/primary/currency-input/CurrencyInput.vue'
import defaults from '../defaults'

export default {
  components: {
    vSelect,
    CurrencyInput
  },
  data() {
    return {
      vCurrencyOptions: {
        ...this.$constants.vCurrencyOptions,
        currencyDisplay: 'symbol'
      },
      options: {
        employmentTypeOptions: defaults.employmentTypeOptions,
        continuousEmploymentOptions: defaults.yesNO,
        haveYearsOfAccountsOptions: defaults.yesNO,
        historyOfBankruptcyOptions: defaults.yesNO
      },
      touched: {
        employmentType: false,
        continuousEmployment: false,
        haveYearsOfAccounts: false,
        historyOfBankruptcy: false,
        grossAnnualIncome: false,
        monthlyFinancialCommitments: false,
        netMonthlyIncome: false
      }
    }
  },
  computed: {
    employmentType: createComputed('employmentType'),
    continuousEmployment: createComputed('continuousEmployment'),
    haveYearsOfAccounts: createComputed('haveYearsOfAccounts'),
    historyOfBankruptcy: createComputed('historyOfBankruptcy'),
    grossAnnualIncome: createComputed('grossAnnualIncome'),
    monthlyFinancialCommitments: createComputed('monthlyFinancialCommitments'),
    netMonthlyIncome: createComputed('netMonthlyIncome'),

    // Validation
    employmentTypeInputState() {
      if (!this.touched.employmentType && !this.employmentType) {
        return null
      }
      return !!this.employmentType
    },
    employmentTypeFeedback() {
      return 'This field is required'
    },
    continuousEmploymentInputState() {
      if (!this.touched.continuousEmployment && !this.continuousEmployment) {
        return null
      }
      return !!this.continuousEmployment
    },
    continuousEmploymentFeedback() {
      return 'This field is required'
    },
    haveYearsOfAccountsInputState() {
      if (!this.touched.haveYearsOfAccounts && !this.haveYearsOfAccounts) {
        return null
      }
      return !!this.haveYearsOfAccounts
    },
    haveYearsOfAccountsFeedback() {
      return 'This field is required'
    },
    grossAnnualIncomeInputState() {
      if (!this.touched.grossAnnualIncome && !this.grossAnnualIncome) {
        return null
      }
      return !!this.grossAnnualIncome
    },
    grossAnnualIncomeFeedback() {
      return 'This field is required'
    },
    netMonthlyIncomeInputState() {
      if (!this.touched.netMonthlyIncome && !this.netMonthlyIncome) {
        return null
      }
      return !!this.netMonthlyIncome
    },
    netMonthlyIncomeFeedback() {
      return 'This field is required'
    },
    monthlyFinancialCommitmentsInputState() {
      if (!this.touched.monthlyFinancialCommitments && !this.monthlyFinancialCommitments) {
        return null
      }
      return !!this.monthlyFinancialCommitments
    },
    monthlyFinancialCommitmentsFeedback() {
      return 'This field is required'
    },

    historyOfBankruptcyInputState() {
      if (!this.touched.historyOfBankruptcy && !this.historyOfBankruptcy) {
        return null
      }
      return !!this.historyOfBankruptcy
    },
    historyOfBankruptcyFeedback() {
      return 'This field is required'
    },

    isValidated() {
      if (this.employmentType === 'self-employed') {
        return (
          this.employmentTypeInputState &&
          this.continuousEmploymentInputState &&
          this.haveYearsOfAccountsInputState &&
          this.grossAnnualIncomeInputState &&
          this.netMonthlyIncomeInputState &&
          this.monthlyFinancialCommitmentsInputState &&
          this.historyOfBankruptcyInputState
        )
      } else {
        return (
          this.employmentTypeInputState &&
          this.continuousEmploymentInputState &&
          this.grossAnnualIncomeInputState &&
          this.netMonthlyIncomeInputState &&
          this.monthlyFinancialCommitmentsInputState &&
          this.historyOfBankruptcyInputState
        )
      }
    }
  }
}
</script>
