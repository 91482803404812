<template>
  <div>
    <vx-card>
      <h4>Your Affordability Estimate</h4>
      <vs-divider class="mb-4" />

      <div v-if="isCalculated">
        <p class="text-lg">
          Based on a gross annual income of
          <strong id="annual-income">{{ annual_income ? $filters.currency_formatter(annual_income) : 'N/A' }}</strong>
          and assuming an average level of monthly expenditure:
        </p>
      </div>

      <div class="vx-col w-full mb-8">
        <div class="mt-8 mb-8 m-auto text-center">
          <p>Your rough property budget is</p>
          <p id="estimate_property_budget" class="text-4xl">
            {{ $filters.currency_formatter(estimate_property_budget) }}
          </p>
        </div>

        <div class="text-center pt-4 mb-4 h-24">
          <span class="float-left w-1/2 text-center">
            <p>Deposit amount</p>
            <p id="estimate_deposit_amount" class="text-3xl">
              {{ $filters.currency_formatter(estimate_deposit_amount) }}
            </p>
          </span>

          <span class="float-right w-1/2 text-center">
            <p>Finance amount</p>
            <p id="estimate_finance_amount" class="text-3xl">
              {{ $filters.currency_formatter(estimate_finance_amount) }}
            </p>
          </span>
        </div>

        <div class="mt-4 mb-8 m-auto text-sm">
          <p>
            The level of finance we can provide will be based on a full affordability assessment and may be subject to a
            cap.
          </p>
        </div>
      </div>

      <div class="vx-col w-full mt-8">
        <div class="w-full mb-3">
          <b-button
            v-if="isLoggedIn && showEdit"
            id="show-edit"
            @click="editDetails"
            variant="outline-primary"
            class="w-full shadow-md"
            >Go back and edit your details <b-icon icon="pencil-square" class="ml-2" aria-hidden="true"></b-icon>
          </b-button>
        </div>
        <div class="w-full mb-3">
          <b-button
            v-if="showCostCalcLink"
            id="cost-calc-link"
            @click.prevent="goToCostCalculator"
            variant="primary"
            class="w-full shadow-md"
            >See how much it would cost
          </b-button>
        </div>
        <div class="w-full mb-3">
          <b-button
            v-if="isLoggedIn && showButtons"
            id="aipLink"
            @click.prevent="$router.push('/calculators/acceptance-principle')"
            variant="primary"
            class="w-full shadow-md"
            >Get an Acceptance in Principle
          </b-button>
        </div>
        <div class="w-full mb-3">
          <b-button
            v-if="isLoggedIn && showButtons"
            id="historyLink"
            @click.prevent="$router.push('/calculators/calculation-history')"
            variant="primary"
            class="w-full shadow-md"
            >Check historic results
          </b-button>
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>
export default {
  name: 'AffordabilityCalculatorEstimate',
  methods: {
    goToCostCalculator() {
      const url = this.isLoggedIn ? 'cost-calculator' : 'open-cost-calculator'
      this.$router.push({
        name: url,
        params: { deposit: this.estimate_deposit_amount, propertyValue: this.estimate_property_budget }
      })
    },
    setAffordabilityCalculatorDetails() {
      this.$store.dispatch('calculators/updateAffordabilityCalculatorData', {
        deposit: this.estimate_deposit_amount,
        gross_annual_income: this.annual_income
      })
    },
    editDetails() {
      this.setAffordabilityCalculatorDetails()
      this.$router.push({ name: 'affordability-calculator' })
    }
  },
  props: {
    estimate_property_budget: {
      type: Number,
      default: 0
    },
    estimate_deposit_amount: {
      type: Number,
      default: 0
    },
    estimate_finance_amount: {
      type: Number,
      default: 0
    },
    isCalculated: {
      type: Boolean,
      default: false
    },
    annual_income: {
      type: [Number, String],
      default: 0
    },
    showButtons: {
      type: Boolean,
      default: true
    },
    showEdit: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.auth.isUserLoggedIn()
    },
    showCostCalcLink() {
      if (this.isLoggedIn) {
        return this.$store.state.calculators.showCostCalculator && this.showButtons && this.isCalculated
      } else {
        return true
      }
    }
  }
}
</script>
