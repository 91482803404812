<!-- =========================================================================================
  File Name: SavingsTab.vue
  Description: Savings
========================================================================================== -->

<template>
  <div id="savings-tab">
    <p class="my-4" v-if="breakdown && breakdown.length > 0 && !!total_investment && !!expected_profit">
      Your target annual profit rate is currently {{ expected_profit_aer }}
      <b-icon
        icon="info-circle"
        v-b-popover.hover.bottom="{
          variant: 'secondary',
          content: `Dividends are paid on a monthly basis. The target annual profit rate represents the total effective return you would achieve over one year if your monthly target dividends were reinvested. This is based on a gross target return of ${expected_profit} per annum, paid monthly`,
          container: 'savings-tab'
        }"
      />
    </p>
    <div class="mt-4">
      <InvestmentListTable
        v-if="breakdown && breakdown.length > 0"
        :breakdown="breakdown"
        :perPage="10"
      ></InvestmentListTable>
    </div>

    <div id="loading-text" v-if="loaded && breakdown.length === 0">You do not have any current transactions</div>
  </div>
</template>

<script>
import InvestmentListTable from '../../../components/primary/transaction-history/InvestmentListTable.vue'

export default {
  props: {
    breakdown: {
      type: Array,
      required: true
    },
    total_investment: {
      type: String,
      required: true
    },
    expected_profit: {
      type: String,
      required: true
    },
    expected_profit_aer: {
      type: String,
      required: true
    },
    loaded: {
      type: Boolean,
      required: true
    }
  },
  components: {
    InvestmentListTable
  }
}
</script>
