import store from '../../../store/store';

const dataMapper = (data) => {
  const payload = {
    name: data.fullName,
    email: data.email.toLowerCase().replace(/ /g, ''),
    nationality: data.nationality,
    country: data.countryOfResidence,
    employment_type: data.employmentType,
    continuous_employment: data.continuousEmployment,
    accounts_records: data.employmentType === 'self-employed' ? data.haveYearsOfAccounts : undefined,
    ccj_bankruptcy: data.historyOfBankruptcy,
    property_type: data.propertyType,
    finance_purpose: data.financePurpose,
    main_residence: data.isPropertyMainResidence,
    first_time_buyer: data.isFirstTimeBuyer,
    property_location: data.propertyLocation,
    term: parseInt(data.term),
    annual_income: parseInt(data.grossAnnualIncome),
    net_monthly_income: parseInt(data.netMonthlyIncome),
    monthly_spend: parseInt(data.monthlyFinancialCommitments),
    property_value: parseInt(data.estimatedPropertyValue),
    deposit: parseInt(data.financeRequired),
    commercial_let:
      data.propertyType === 'investment property' && data.isCommercialLet ? data.isCommercialLet : undefined,
    will_inhabit: data.propertyType === 'investment property' && data.willInhabit ? data.willInhabit : undefined,
    have_inhabited: data.propertyType === 'investment property' && data.HaveInhabit ? data.HaveInhabit : undefined,
    own_other_lets: data.propertyType === 'investment property' && data.ownOtherLets ? data.ownOtherLets : undefined,
    access_token: store.getters['auth/accessToken'],
  };
  return payload;
};

export default dataMapper;
