<template>
  <div>
    <b-card>
      <b-form id="userDetailsForm" @submit.prevent="submitForm">
        <h4 class="text-center mb-6">What is your expected retirement age?</h4>
        <p class="text-center mb-8">
          We use this to help manage any finance agreements you have with us. It’s very important to keep this
          information up to date.
        </p>
        <b-form-group
          id="input-group-retirementAge"
          :invalid-feedback="retirementAgeFeedback"
          :state="retirementAgeState"
          label-for="input-retirementAge"
        >
          <b-form-input id="input-retirementAge" type="number" v-model="retirement_age" required></b-form-input>
        </b-form-group>

        <div class="flex flex-wrap items-center justify-end mt-4">
          <b-button
            class="shadow-md lg:mt-0 mb-2 mt-2 p-2"
            variant="primary"
            :disabled="!validateForm || !inputChanged"
            @click.prevent="submitForm"
            >Save changes</b-button
          >
        </div>
      </b-form>
    </b-card>

    <b-modal id="modal-center" centered :title="modalType">
      <div class="my-4" v-html="modalMessage"></div>
      <template #modal-footer="{ ok }">
        <b-button size="lg" class="shadow-md lg:mt-0 mb-2" variant="primary" @click="ok()">OK</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import helpers from '../../../../helpers'

export default {
  data() {
    return {
      retirement_age: this.$store.state.user.RetirementAge,
      modalMessage: '',
      modalType: ''
    }
  },
  computed: {
    retirementAgeState() {
      return this.retirement_age >= 11
    },
    retirementAgeFeedback() {
      if (this.retirement_age.length > 1) {
        return 'The field must be at least 2 characters'
      }
      return 'This field is required'
    },
    validateForm() {
      return this.retirementAgeState
    },
    inputChanged() {
      return this.retirement_age != this.$store.state.user.RetirementAge
    }
  },
  methods: {
    submitForm() {
      const payload = {
        age: this.retirement_age
      }

      this.$vs.loading()
      this.$store
        .dispatch('user/updateRetirementAge', payload)
        .then((res) => {
          this.$vs.loading.close()
          helpers.notifySuccess(this.$vs, res.data.response.messages[0])
        })
        .catch((error) => {
          this.$vs.loading.close()
          const response = error.response.data.response
          const status = response.status
          this.retirement_age = +this.$store.state.user.RetirementAge
          if (status === 701) {
            this.modalMessage = response.messages[0]
            this.modalType = response.type
            this.$bvModal.show('modal-center')
          }
        })
    }
  }
}
</script>
