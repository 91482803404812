<template>
  <div>
    <div>
      Pfida understands the value of your privacy, and will therefore only use the information you give us in the
      manner(s) you have given consent for. We do not sell mailing lists to other companies, or divulge any of your
      personal details to other companies or individuals.
    </div>
    <div class="underline">Use of personal information</div>

    <div>We may use the information you provide for the following purposes, with your consent:</div>
    <ul class="list-disc m-4">
      <li>To send newsletters and details of offers and promotions which will be of interest</li>

      <li>To improve the design and content of the website</li>

      <li>To perform other general marketing and promotional activities focused on our products and services</li>
    </ul>
    <div class="underline">Your Consent</div>
    <div>
      When you register with Pfida or or use our contact or sign-up forms you will be submitting information giving us
      the consent to collect and use this information by Pfida Ltd and it’s Service Providers. If we decide to change
      our privacy policy, we will post those changes on this page so that you are aware of what information we collect,
      how we use it and under what circumstances we disclose it. If there are any features that you would like to see
      available on this site let us know using the contact form on this website. You can request a copy of our full
      privacy policy using the contact form on this website. You can also download it using the link below.
    </div>

    <a href="https://www.pfida.com/privacy-policy/" target="_blank">
      Privacy Policy <b-icon icon="box-arrow-up-right"></b-icon
    ></a>

    <div>
      All content on this site is protected under the UK and international copyright laws. No part of this site may be
      copied, reproduced, distributed, redistributed, modified displayed or performed without prior written consent by
      Pfida Ltd. This site is provided as an informational and contact resource by Pfida Ltd. The site is provided on an
      "as is" basis. Pfida Ltd makes no express or implied warranties of any kind relating to the provision of
      information or service on or by this site. Pfida Ltd will not be liable for any damages of any kind arising from
      the use or misuse of this site or any of the information contained within it. We reserve the right to make changes
      to this site, disclaimers, and terms and conditions at any time.
    </div>
    <div>Registered Office Pfida Ltd, 124 City Road, London EC1V 2NX</div>
    <span class="md:flex-wrap items-center">
      <p>
        Latest audited accounts of Primary HPP PLC are available
        <a
          href="https://primary-finance-public.s3.eu-west-2.amazonaws.com/documents/abdc822051ab7e332b99e4bfc9927e629b4bbfebc10583cfcd69c946573c7a91/primary_hpp_plc_annual_report.pdf"
          target="_blank"
          >here <b-icon icon="box-arrow-up-right"></b-icon
        ></a>
      </p>
    </span>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
div {
  font-size: 14px;
  padding: 5px 0px;
}
</style>
