<template>
  <!-- <input ref="inputRef" type="text" /> -->
  <b-form-input ref="inputRef" :name="name" :class="[{ 'w-full': fullLength }]" :disabled="disabled" />
</template>

<script>
import { watch } from 'vue'
import { useCurrencyInput } from 'vue-currency-input'
export default {
  name: 'CurrencyInput',
  props: {
    value: Number, // Vue 2: value
    options: Object,
    name: String,
    fullLength: {
      default: true,
      type: Boolean
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const { inputRef, setOptions, setValue } = useCurrencyInput(props.options)

    watch(
      () => props.value,
      (value) => {
        setValue(value)
      }
    )

    watch(
      () => props.options,
      (options) => {
        setOptions(options)
      }
    )

    return { inputRef }
  }
}
</script>
