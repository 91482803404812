<template>
  <div id="referrals-page">
    <b-card class="mt-4">
      <b-skeleton-wrapper :loading="loading">
        <template #loading>
          <b-card>
            <b-skeleton width="85%"></b-skeleton>
            <b-skeleton width="55%"></b-skeleton>
            <b-skeleton width="70%"></b-skeleton>
            <b-skeleton width="95%"></b-skeleton>
            <div class="my-8"></div>
            <b-skeleton type="input"></b-skeleton>
          </b-card>
        </template>

        <div v-if="!loading" id="content">
          <div class="text-container">
            <p class="my-3">
              Share your personalised referral link with your friends and family to earn referral points* when they
              invest in a <i>GYS Home</i> account
            </p>
            <p class="my-3">
              You can choose where these points go – towards increasing your place on the priority waiting list, shared
              between you and your referral or towards getting them ahead on the waiting list.
            </p>

            <p class="text-base my-4 italic">*These points do not form part of your invested funds</p>
          </div>

          <div class="text-center my-base">
            <h2>Referral Link</h2>
            <b-alert id="referral-link" show variant="info">
              <p class="break-words text-base text-blue-800 cursor-pointer" @click="copyLink">
                {{ referrals.referral_link }}
                <b-icon icon="clipboard-check" class="ml-3 text-2xl" size aria-hidden="true"></b-icon>
              </p>
            </b-alert>
            <div>
              <b-button @click="shareWhatsApp" class="mx-2" variant="success">
                <b-icon icon="whatsapp"></b-icon>
              </b-button>
              <b-button @click="shareEmail" variant="info">
                <b-icon icon="envelope"></b-icon>
              </b-button>
            </div>
          </div>

          <div class="my-base py-4 text-center">
            <b-form-group>
              <p for="split-options" class="font-medium mb-6">
                Choose how you would like your referral points to be allocated:
              </p>

              <b-form-radio-group v-model="selected" @change="settingChanged" size="lg" name="referral-radios">
                <div class="flex justify-center">
                  <span class="inline-flex flex-column items-center m-2">
                    <b-icon
                      icon="info-circle"
                      @click.prevent=""
                      v-b-popover.hover.top="{ variant: 'secondary', content: helpText, container: 'referrals-page' }"
                    ></b-icon>
                    <b-form-radio :value="referralSplitOptions[0]">{{ referralSplitOptions[0] }} </b-form-radio>
                  </span>
                  <span class="inline-flex flex-column items-center m-2">
                    <b-icon
                      icon="info-circle"
                      @click.prevent=""
                      v-b-popover.hover.top="{ variant: 'secondary', content: helpText, container: 'referrals-page' }"
                    ></b-icon>
                    <b-form-radio :value="referralSplitOptions[1]">{{ referralSplitOptions[1] }} </b-form-radio>
                  </span>
                  <span class="inline-flex flex-column items-center justify-end m-2">
                    <b-form-radio :value="referralSplitOptions[2]">{{ referralSplitOptions[2] }}</b-form-radio>
                  </span>
                </div>
              </b-form-radio-group>
            </b-form-group>
          </div>
          <hr />

          <h3 class="text-center">Your Referrals</h3>
          <div class="mb-base grid-container text-center">
            <div>
              <PercentageCircle
                v-if="referralData"
                :text="referralData.total_referral_credit"
                :color="$constants.colors.pfidaPurple"
              />
              <p class="font-bold">Total Referral Points</p>
            </div>
            <div>
              <PercentageCircle
                v-if="referralData"
                :text="referralData.pending_referrals"
                :color="$constants.colors.pfidaSalmon"
              />
              <p class="font-bold">Pending Referrals</p>
            </div>
            <div>
              <PercentageCircle
                v-if="referralData"
                :text="referralData.total_referrals"
                :color="$constants.colors.pfidaDustBlue"
              />
              <p class="font-bold">Successful Referrals</p>
            </div>
          </div>
        </div>
      </b-skeleton-wrapper>
    </b-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import helpers from '../../../helpers'
import PercentageCircle from '../../components/primary/PercentageCircle/PercentageCircle.vue'

export default {
  data() {
    return {
      selected: '',
      helpText:
        'Your referred friends will only earn their referral points if they also apply to invest in a regular GYS Home account.'
    }
  },
  components: {
    PercentageCircle
  },
  computed: {
    ...mapState({
      referrals: (state) => state.user.referrals.data,
      referralData: (state) => state.user?.referrals?.data.pfhpp,
      loading: (state) => state.user.status.referrals.loading && state.user.status.referralSettings.loading,
      referralSplitOptions: (state) => state.user.referrals.settings.options,
      shareTxt: function () {
        return `I am growing my savings with a Pfida investment account. They use my money to help everyday people get on the property ladder ethically, without creating debt. I get a competitive, ethical return on my savings and you can too!%0D%0DWhat’s more, by investing with Pfida we can both get prioritised on their waiting list for buying a home debt-free. Use my link to grow your savings with Pfida: %0D%0D${this.referrals?.referral_link}`
      }
    })
  },
  methods: {
    copyLink() {
      /* Copy the text inside the text field */
      navigator.clipboard.writeText(this.referrals.referral_link)

      setTimeout(() => {
        this.toast('Copied!')
      }, 400)
    },
    toast(text) {
      this.$bvToast.toast(text, {
        toaster: 'b-toaster-bottom-center',
        noCloseButton: true,
        solid: true,
        id: 'copy-success',
        toastClass: 'rounded-full',
        bodyClass: 'text-center font-bold',
        appendToast: false
      })
    },
    shareWhatsApp() {
      var isMobile = /Android|webOS|iPhone|iPad|iPod/i.test(navigator.userAgent)

      const shareTxt = `I am growing my savings with a Pfida investment account. They use my money to help everyday people get on the property ladder ethically, without creating debt. I get a competitive, ethical return on my savings and you can too! %0A%0AWhat’s more, by investing with Pfida we can both get prioritised on their waiting list for buying a home debt-free. Use my link to grow your savings with Pfida: %0A%0A${this.referrals?.referral_link}`

      let url = `https://wa.me/?text=${shareTxt}`
      if (isMobile) {
        url = `whatsapp://send?text=${shareTxt}`
      }
      window.open(url)
    },
    shareEmail() {
      const subject = 'Use my link to grow your savings with PF'
      const email = ''
      window.open('mailto:' + email + '?subject=' + subject + '&body=' + this.shareTxt)
    },
    settingChanged(val) {
      this.$vs.loading()
      this.$store
        .dispatch('user/setReferralSplit', val)
        .then((res) => {
          this.$vs.loading.close()
          const message = res.data.response.messages[0]
          helpers.notifySuccess(this.$vs, message)
        })
        .catch(() => {
          this.$vs.loading.close()
        })
    }
  },
  created() {
    if (!this.$store.state.user.referrals.data.show_referrals) {
      this.$router.back()
    } else {
      this.$vs.loading()
      this.$store.dispatch('user/getReferrals')
      this.$store
        .dispatch('user/getReferralSettings')
        .then((res) => {
          this.$vs.loading.close()
          this.selected = res?.data?.data?.value
        })
        .catch(() => {
          this.$vs.loading.close()
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.grid-container {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
}
</style>
