<template>
  <div>
    <b-modal
      id="investment-closed-modal"
      centered
      ok-only
      header-class="border-0"
      content-class="pt-0"
    >
      <template #modal-title>
        <div class="d-flex align-items-center">
          <b-icon 
            icon="exclamation-circle" 
            variant="warning"
            class="mr-2"
          ></b-icon>
          Investments and GYS account update
        </div>
      </template>
      <div class="text-container">
        <p class="py-1 text-left">
          The investment window is scheduled to reopen in the coming weeks. Once available, you will have the opportunity to apply again.
        </p>
        <p class="py-1 text-left">
          Please ensure that your investor declaration has been completed to avoid any delays. Keep an eye on your inbox for updates and be ready to take the next step!
        </p>
      </div>
      <template #modal-footer="{ ok }">
        <b-button 
          class="shadow-md lg:mt-0" 
          @click="ok()"
          variant="primary"
        >
          Okay, got it!
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    onOk: {
      type: Function,
      default: (ok) => {
        ok()
      }
    }
  },
  computed: {
    isVisible: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    }
  }
}
</script>
