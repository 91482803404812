<template>
  <div>
    <div v-if="!error">
      <div v-if="!loaded" class="vx-row">
        <div class="vx-col w-full sm:w-1/2 lg:w-1/3 mb-base" v-for="n in 9" :key="n.id">
          <b-card class="shadow">
            <b-skeleton animation="wave" width="40%"></b-skeleton>
            <b-skeleton animation="wave" width="100%"></b-skeleton>
            <b-skeleton animation="wave" width="100%"></b-skeleton>
          </b-card>
        </div>
      </div>
      <div v-else>
        <div class="flex items-baseline my-4 mx-2 flex-col-reverse sm:flex-row" v-if="filterOptionsLength > 1">
          <div class="flex items-baseline sm:flex-row">
            <div>
              <b-dropdown menu-class="menu-shadow" variant="filter" class="mr-4" no-caret>
                <b-dropdown-header id="dropdown-header-label">Calculator Type </b-dropdown-header>
                <template #button-content>
                  Filter<b-icon icon="filter" class="cursor-pointer" font-scale="1.5" />
                </template>
                <b-dropdown-item-button
                  v-for="(filterOption, index) in filterOptions"
                  :active="filter === filterOption"
                  :key="index"
                  @click="ondropDownfilter"
                  class="dropdown-filter-btn"
                  >{{ filterOption }}</b-dropdown-item-button
                >
              </b-dropdown>
            </div>
            <div>
              <h3>
                <b-badge
                  v-if="filter"
                  class="pr-2 p-2 text-base font-normal text-black filter-badge"
                  font-scale="1.5"
                  pill
                  >{{ filter }}<b-icon class="cursor-pointer" @click="clearFilter" icon="x"
                /></b-badge>
              </h3>
            </div>
          </div>
        </div>

        <div class="vx-row">
          <div
            id="calc-cards"
            class="vx-col w-full sm:w-1/2 lg:w-1/3 mb-base"
            v-for="calculated in filteredHistory"
            :key="calculated.id"
          >
            <a href="#" @click.prevent="() => loadHistory(calculated)" tabindex="-1">
              <b-card class="shadow">
                <div class="duration-300 ease-in-out hover-slide-right">
                  <h5 class="mb-2">
                    <b>{{ calculated.type }}</b> ({{ formattedDate(calculated.created_at) }})
                  </h5>
                  <!-- <div v-if="calculated.type === 'cost_calculator'">
                <p class="text-black text-base">
                  Property value: {{ $filters.currency_formatter(calculated.property_value) }}
                </p>
                <p class="text-black text-base">Deposit: {{ $filters.currency_formatter(calculated.deposit) }}</p>
                <p class="text-black text-base">Term: {{ $filters.currency_formatter(calculated.term) }}</p>
              </div> -->
                  <div v-if="calculated.type === 'Affordability Calculator'">
                    <p class="text-black text-base">
                      Gross Annual Income:
                      {{ calculated.annual_income ? $filters.currency_formatter(calculated.annual_income) : 'N/A' }}
                    </p>
                    <p class="text-black text-base">Deposit: {{ $filters.currency_formatter(calculated.deposit) }}</p>
                  </div>
                  <div v-if="calculated.type === 'AIP'">
                    <p class="text-black text-base">
                      Net Monthly Income: {{ $filters.currency_formatter(calculated.net_monthly_income) }}
                    </p>
                    <p class="text-black text-base">
                      Monthly Spend: {{ $filters.currency_formatter(calculated.monthly_spend) }}
                    </p>
                    <p class="text-black text-base">Property Type: {{ calculated.property_type }}</p>
                    <p class="text-black text-base">
                      Property Value: {{ $filters.currency_formatter(calculated.property_value) }}
                    </p>
                    <p class="text-black text-base">Deposit: {{ $filters.currency_formatter(calculated.deposit) }}</p>
                    <p class="text-black text-base">Term: {{ calculated.term }}</p>
                  </div>
                </div>
              </b-card>
            </a>
          </div>
        </div>

        <div v-if="history.length === 0" class="grid justify-center align center w-full h-full">
          <div>You don't have any calculation history with us at the moment</div>
        </div>
      </div>

      <!-- <b-modal v-model="showCostCalculatorPopup" :hide-footer="true" centered title="Cost calculator">
        <CostCalculatorEstimate
          :is-calculated="true"
          :arrangement_fee="calculator.arrangement_fee"
          :estimate_total_payments="calculator.total_payment"
          :total_payment_best_case="calculator.total_payment_best_case"
          :total_payment_mid_range="calculator.total_payment_mid_range"
          :total_payment_worst_case="calculator.total_payment_worst_case"
          :monthly_payment="calculator.monthly_payment"
          :property_value="calculator.property_value"
          :term="calculator.term"
          :deposit="calculator.deposit"
          :finance_amount="calculator.finance_amount"
          :isBuyToLet="!!calculator.buy_to_let"
          :showButtons="false"
          :showEdit="true"
        />
      </b-modal> -->

      <b-modal v-model="showAffordabiliityPopup" :hide-footer="true" centered title="Affordability calculator">
        <AffordabilityCalculatorEstimate
          :estimate_deposit_amount="affordabiliity.deposit"
          :estimate_finance_amount="affordabiliity.finance_amount"
          :estimate_property_budget="affordabiliity.finance_amount + (affordabiliity.deposit || 0)"
          :net_monthly_income="affordabiliity.net_monthly_income"
          :annual_income="affordabiliity.annual_income"
          :isCalculated="true"
          :showButtons="false"
          :showEdit="true"
        />
      </b-modal>
      <b-modal v-model="showAIPPopup" :hide-footer="true" centered :title="AIPPopupTitle">
        <AcceptanceInPrincipleResult :acceptance="acceptance" :messages="messages" :showButtons="false" />
      </b-modal>
    </div>
    <ServerError500 v-else />
  </div>
</template>

<script>
import helpers from '../../../helpers'
import AffordabilityCalculatorEstimate from '../../components/primary/calculators/AffordabilityCalculatorEstimate.vue'
import AcceptanceInPrincipleResult from '../../components/primary/calculators/AcceptanceInPrincipleResult.vue'
import ServerError500 from '../../components/error-pages/ServerError500.vue'
import _ from 'lodash'

export default {
  name: 'CalculationHistory',
  components: {
    AffordabilityCalculatorEstimate,
    AcceptanceInPrincipleResult,
    ServerError500
  },
  data() {
    return {
      loaded: false,
      showCostCalculatorPopup: false,
      AIPPopupTitle: '',
      showAffordabiliityPopup: false,
      showAIPPopup: false,
      calculator: {},
      affordabiliity: {},
      acceptance: {},
      messages: [],
      history: [],
      error: false,
      filter: null
    }
  },
  computed: {
    filteredHistory() {
      if (this.filter) {
        const filteredHist = this.history.filter((i) => {
          return i.type === this.filter
        })
        return filteredHist
      }
      return this.history
    },
    filterOptionsLength() {
      return _.uniq(this.history?.map((i) => i.type))?.length
    },
    filterOptions() {
      const options = []
      this.history.forEach((element) => {
        if (!options.includes(element.type) && element.type != '') {
          options.push(element.type)
        }
      })
      return options
    }
  },
  methods: {
    ondropDownfilter(e) {
      this.filter = e.target.innerHTML
    },
    loadHistory(calculated) {
      if (calculated.type === 'AIP') {
        this.showAIPPopup = true
        this.acceptance = calculated
        this.messages = [calculated.message]
        switch (this?.acceptance?.answer) {
          case 'yes':
            this.AIPPopupTitle = 'Congratulations'
            break
          case 'no':
            this.AIPPopupTitle = 'Sorry'
            break
          case 'maybe':
            this.AIPPopupTitle = 'We need more information'
            break
        }
      } else if (calculated.type === 'Affordability Calculator') {
        this.showAffordabiliityPopup = true
        this.affordabiliity = calculated
      } // else if (calculated.type === 'cost_calculator') {
      //   this.showCostCalculatorPopup = true
      //   this.calculator = calculated
      // }
    },
    clearFilter() {
      this.filter = null
    },
    formattedDate(date) {
      return helpers.formatDate(date)
    }
  },
  created() {
    this.$store
      .dispatch('calculators/getCalculatorHistory')
      .then((response) => {
        this.loaded = true
        this.history = response
      })
      .catch(() => {
        this.loaded = true
        this.error = true
      })
  }
}
</script>

<style lang="scss">
.hover-slide-right:hover {
  padding-left: 20px;
}
</style>
