declare global {
  interface Window {
    [key: string]: Record<string, any>;
  }
}

export function singleton<T>(name: string, value: () => T) {
  const yolo = window;
  yolo.singleton ??= {};
  yolo.singleton[name] ??= value();
  return yolo.singleton[name] as ReturnType<typeof value>;
}
