<template>
  <div id="agreement-settings">
    <div class="grid">
      <b-alert
        :show="dismissCountDown"
        class="vx-col w-full sm:w-3/4 mb-4 place-self-center align-center text-center"
        dismissible
        variant="warning"
        @dismissed="dismissCountDown = 0"
      >
        <p>Your Target payment can only be changed if paying the Target payment as your next payment</p>
      </b-alert>
    </div>

    <div class="vx-row grid">
      <!-- chose Target payment amount -->
      <div id="target-payment-amount" class="vx-col w-full sm:w-3/4 mb-4 place-self-center align-center">
        <b-card class="shadow" v-bind:class="{ disabledCard: isTargetPaymentSelected }" @click="clickTargetCard">
          <div class="mb-base">
            <h3 class="card--title">What would you like your target payment to be?</h3>
            <p class="card-subtext">
              If you wish to change this please first select "Target" in the next payment settings below
            </p>

            <b-container>
              <b-row class="justify-center">
                <b-col sm="12" md="6" lg="5">
                  <CurrencyInput
                    v-model="targetPaymentAmount"
                    placeholder="Enter amount"
                    :options="vCurrencyOptions"
                    class="mt-4"
                    :disabled="isTargetPaymentSelected"
                  />
                </b-col>
              </b-row>
            </b-container>

            <div class="content-container vx-col mt-4">
              <b-button
                class="shadow-md"
                variant="outline-primary"
                @click="confirmTargetPaymentChange"
                :disabled="validatTargetPaymentChanged"
              >
                Confirm
              </b-button>
            </div>
          </div>
        </b-card>
      </div>

      <!-- Choose next payment -->
      <div id="next-payment-type" class="vx-col w-full sm:w-3/4 mb-4 place-self-center">
        <b-card class="shadow">
          <div class="mb-base">
            <h3 class="card--title mb-4">What would you like your next payment to be?</h3>

            <form data-vv-scope="step-1">
              <div id="payment-type-radio-btns" class="content-container mb-1">
                <vs-radio
                  v-model="expectedPaymentMethod"
                  class="radio-button"
                  vs-name="expectedPaymentMethod"
                  :vs-value="this.labels.expectedPaymentMethod[1].setting"
                  >{{ this.labels.expectedPaymentMethod[1].display_name }}</vs-radio
                >
                <vs-radio
                  v-model="expectedPaymentMethod"
                  class="radio-button"
                  vs-name="expectedPaymentMethod"
                  :vs-value="this.labels.expectedPaymentMethod[0].setting"
                  >{{ this.labels.expectedPaymentMethod[0].display_name }}</vs-radio
                >
                <vs-radio
                  v-model="expectedPaymentMethod"
                  class="radio-button"
                  :disabled="inRentArrears"
                  vs-name="expectedPaymentMethod"
                  :vs-value="this.labels.expectedPaymentMethod[2].setting"
                  >{{ this.labels.expectedPaymentMethod[2].display_name }}
                </vs-radio>
                <b-icon
                  v-if="inRentArrears"
                  icon="info-circle"
                  v-b-popover.hover.top="{
                    variant: 'secondary',
                    content:
                      'You cannot pay a Custom amount for your next payment until you have cleared your rent arrears',
                    container: 'agreement-settings',
                  }"
                />
              </div>

              <b-container>
                <b-row class="justify-center">
                  <b-col sm="12" md="6" lg="5">
                    <CurrencyInput
                      v-if="expectedPaymentMethod === 'custom'"
                      v-model="customAmount"
                      placeholder="Enter amount"
                      class="mt-4"
                      :options="vCurrencyOptions"
                    />
                  </b-col>
                </b-row>
              </b-container>
            </form>
            <!-- <span class="text-danger text-sm">{{ errors.first('income') }}</span> -->

            <div class="content-container mt-4">
              <b-button
                class="shadow-md"
                variant="outline-primary"
                @click="confirmPaymentMethod"
                :disabled="!validateCustomPaymentChanged"
                >Confirm</b-button
              >
            </div>
          </div>
          <div class="mb-base" v-if="oneOffPayments.length">
            <h3 class="content-container card--title mb-4">
              You have the following one-off custom payments scheduled:
            </h3>

            <b-table
              v-if="oneOffPayments.length"
              class="md:w-1/2 mx-auto"
              hover
              bordered
              outlined
              small
              head-variant="light"
              :items="oneOffPaymentsData"
              :fields="fields"
            >
              <template #head(cancel)>
                <span class="text-info"></span>
              </template>
              <template #cell(cancel)="data">
                <div>
                  <b-button
                    variant="outline-primary"
                    @click.prevent="showCancelOneOffPaymentModal(data.item)"
                    v-bind:id="`document-${data.index}`"
                    ><b-icon variant="danger" icon="x"></b-icon> Cancel</b-button
                  >
                </div>
              </template>
            </b-table>
          </div>
        </b-card>
      </div>

      <!-- Chose what it affects  -->
      <div id="payment-affects" class="vx-col w-full sm:w-3/4 mb-4 place-self-center">
        <b-card class="shadow">
          <h3 class="card--title">Choose what one-off payments should affect</h3>
          <div>
            <p class="card-subtext">
              If you make a payment that is not your target payment, this can either result in:
            </p>
            <ul class="unexctedPayment-list">
              <li>Your future target payments changing whilst keeping your projected term the same</li>
              <li class="list-none">OR</li>
              <li>Your projected term changing whilst keeping your future target payments the same</li>
            </ul>
            <p class="card-subtext">You can choose which should occur using the options below:</p>

            <div class="content-container mb-1">
              <vs-radio
                v-model="unexpectedPaymentMethod"
                class="radio-button"
                vs-name="unexpectedPaymentMethod"
                :vs-value="this.labels.unexpectedPaymentMethod[0].setting"
                >{{ this.labels.unexpectedPaymentMethod[0].display_name }}</vs-radio
              >
              <vs-radio
                v-model="unexpectedPaymentMethod"
                class="radio-button"
                vs-name="unexpectedPaymentMethod"
                :vs-value="this.labels.unexpectedPaymentMethod[1].setting"
                >{{ this.labels.unexpectedPaymentMethod[1].display_name }}</vs-radio
              >
            </div>
            <!-- <span class="text-danger text-sm">{{ errors.first('income') }}</span> -->
          </div>

          <div class="content-container vx-col mt-2">
            <b-button
              class="shadow-sm lg:mt-0 mt-6"
              variant="outline-primary"
              @click="confirmUnexpectedPaymentMethod"
              :disabled="!validateUnexpectedPaymentForm"
              >Confirm</b-button
            >
          </div>
        </b-card>
      </div>
    </div>

    <!-- consolidate thses into one modal -->
    <AttentionModal
      id="change-target-payment"
      okText="Yes, change my target payment"
      :message="changeTargetPaymentWarning"
      :onOk="openTargetPaymentLength"
    />

    <b-modal centered title="Target Payment" id="target-payment-length">
      <p>Please confirm for how long you would like to make this target payment for:</p>

      <div class="content-container flex mb-1">
        <b-form-radio
          v-model="targetPaymentLength"
          class="radio-button"
          size="md"
          name="payment-length-radios"
          value="3"
          >3 months</b-form-radio
        >
        <b-form-radio
          v-model="targetPaymentLength"
          class="radio-button"
          size="md"
          name="payment-length-radios"
          value="-1"
          >More than 3 months</b-form-radio
        >
      </div>

      <template #modal-footer="{ ok, cancel }">
        <div class="flex justify-center mb-0">
          <b-button variant="outline-primary" @click="cancel()">Cancel</b-button>
          <b-button
            class="shadow-md ml-1"
            :disabled="!targetPaymentLength"
            @click.prevent="selectTargetPaymentLength(ok)"
            variant="primary"
            >Confirm</b-button
          >
        </div>
      </template>
    </b-modal>

    <AttentionModal
      id="cancel-oneOff-payment-warning"
      okText="Cancel one-off payment"
      cancelText="Cancel"
      :message="cancelPaymentText"
      :onOk="cancelOneOffPayment"
    />
    <AttentionModal
      id="custom-payment-notice"
      okText="Proceed with one-off payment"
      cancelText="Cancel"
      :message="customPaymentMessage"
      :onOk="changeExpectedPaymentSettings"
    />
    <AttentionModal
      id="large-customPayment-warning"
      okText="Proceed with one-off payment notice"
      cancelText="Cancel"
      :message="errorMessage"
      :onOk="popupBtnClick"
    />
    <b-modal centered ok-only :ok-title="ErrorOkBtnText" @ok="popupBtnClick()" v-model="errorPopupActive">
      <template #modal-title>
        Oops!
        <b-icon icon="exclamation-circle-fill" variant="danger" font-scale="1.2"></b-icon>
      </template>
      <div v-html="errorMessage"></div>
    </b-modal>
    <b-modal centered v-model="successPopupActive" ok-only>
      <template #modal-title>
        Success
        <b-icon icon="check-circle-fill" variant="success" font-scale="1.2"></b-icon>
      </template>
      <div>{{ successMessage }}</div>
    </b-modal>
  </div>
</template>

<script>
import { getErrorMessage } from '@repo/utils';
import userAPI from '../../../../http/requests/user/userAPI';
import AttentionModal from '../../../components/modals/AttentionModal.vue';
import CurrencyInput from '../../../components/primary/currency-input/CurrencyInput.vue';

export default {
  props: {
    labels: {
      type: Object,
      required: true,
    },
    index: {
      type: String,
      required: true,
    },
  },
  components: {
    CurrencyInput,
    AttentionModal,
  },
  computed: {
    validateCustomPaymentChanged() {
      if (this.expectedPaymentMethod === 'custom') {
        if (this.customAmount == '') return false;
        else if (
          this.financeDetails.rent_arrears &&
          parseInt(this.financeDetails.next_total_monthly_payment_ex_rent_arrears) === parseInt(this.customAmount)
        ) {
          return false;
        } else if (
          parseInt(this.financeDetails.next_total_monthly_payment_ex_rent_arrears) === parseInt(this.customAmount)
        ) {
          return false;
        }

        return true;
      }
      return this.financeDetails.expected_payment_setting != this.expectedPaymentMethod;
      //! Error here This is used for both forms
    },
    oneOffPaymentsData() {
      return this.oneOffPayments.map((payment) => {
        return {
          date: payment.date,
          payment: this.$filters.currency_formatter(payment.amount),
          cancel: '',
        };
      });
    },
    cancelPaymentText() {
      return `You are about to cancel the one-off custom payment request for ${this.selectedOneOffData.payment} in
            ${this.selectedOneOffData.date}.`;
    },
    validateUnexpectedPaymentForm() {
      return this.unexpectedPaymentMethod != this.financeDetails.unexpected_payment_outcome;
    },
    ErrorOkBtnText() {
      return this.PaymentMethodStatus === 700 ? 'OK' : 'Contact us';
    },
    financeDetails() {
      return this.$store.state.financeDetails[this.index - 1];
    },
    isTargetPaymentSelected() {
      return this.financeDetails.expected_payment_setting != 'rent_and_invest';
    },
    validatTargetPaymentChanged() {
      if (this.financeDetails.expected_payment_setting === 'rent_and_invest') {
        if (
          this.targetPaymentAmount ===
          Number(this.financeDetails.next_total_monthly_recommended_payment.replace(/[^0-9.-]+/g, ''))
        ) {
          return true;
        }
        return false;
      }
      return true;
    },
    inRentArrears() {
      return !!this.financeDetails.rent_arrears;
    },
  },
  data() {
    return {
      customAmount: '',
      dismissCountDown: 0,
      targetPaymentAmount: '',
      targetPaymentLength: '',
      changeTargetPaymentWarning:
        'You will not be able to change your target payment again for 3 months starting from your next payment date. However you are still free to change it any time before your next payment date.',
      customPaymentMessage:
        'Please note, by making a one-off payment you will not be eligible for your rental discount for this month. You could instead request from us to change your target payment for a minimum of 3 months and still get your rental discount.',
      expectedPaymentMethod: '',
      unexpectedPaymentMethod: '',
      errorPopupActive: false,
      successPopupActive: false,
      errorMessage: '',
      successMessage: '',
      PaymentMethodStatus: '',
      vCurrencyOptions: {
        ...this.$constants.vCurrencyOptions,
        currencyDisplay: 'symbol',
        precision: 2,
      },
      oneOffPayments: [],
      fields: [
        { key: 'payment', tdClass: 'align-middle text-center', thClass: 'text-center' },
        { key: 'date', tdClass: 'align-middle text-center', thClass: 'text-center' },
        { key: 'cancel', tdClass: 'align-middle text-center', thClass: 'text-center' },
      ],
      selectedOneOffData: {},
    };
  },
  methods: {
    toast(text) {
      this.$bvToast.toast(text, {
        toaster: 'b-toaster-bottom-center',
        noCloseButton: true,
        solid: false,
        id: 'dupa-success',
        toastClass: 'rounded-full',
        bodyClass: 'text-center font-bold',
        appendToast: false,
      });
    },
    confirmTargetPaymentChange() {
      this.$bvModal.show('change-target-payment');
    },
    changeTargetPaymentRentOnlyOk() {
      this.$bvModal.show('change-target-payment');
    },
    selectTargetPaymentLength(ok) {
      this.$bvModal.hide('change-target-payment');
      const payload = {
        reference: this.financeDetails.reference,
        recommended_payment: this.targetPaymentAmount,
        num_months: this.targetPaymentLength,
      };
      this.$vs.loading();
      userAPI
        .changeTargetPayment(payload)
        .then((res) => {
          const response = res.data.response;
          this.PaymentMethodStatus = response.status;
          this.successMessage = response.messages[0];

          this.$store.dispatch('getFinanceDetails', { force: true }).then(() => {
            ok();
            this.successPopupActive = true;
            this.$vs.loading.close();
          });
        })
        .catch((err) => {
          ok();

          this.errorMessage = getErrorMessage(err);
          this.errorPopupActive = true;
          this.PaymentMethodStatus = err.response.data.response.status;
        })
        .finally(() => {
          this.$vs.loading.close();
        });
    },
    openTargetPaymentLength() {
      this.$bvModal.hide('change-target-payment');
      this.$bvModal.show('target-payment-length');
    },
    confirmPaymentMethod() {
      if (this.expectedPaymentMethod === 'custom') {
        this.$bvModal.show('custom-payment-notice');
      } else {
        this.changeExpectedPaymentSettings();
      }
    },
    changeExpectedPaymentSettings() {
      this.$bvModal.hide('custom-payment-notice');
      const payload = {
        reference: this.financeDetails.reference,
        value: this.expectedPaymentMethod,
      };
      if (this.expectedPaymentMethod === 'custom') {
        payload.payment = this.customAmount;
      }
      this.$vs.loading();
      userAPI
        .postChangeExpectedPaymentSettings(payload)
        .then((result) => {
          const response = result.data.response;
          this.PaymentMethodStatus = response.status;
          this.successMessage = response.messages[0];
          this.$store.dispatch('getFinanceDetails', { force: true }).then(() => {
            this.$vs.loading.close();
            this.customAmount = this.financeDetails.next_total_monthly_payment_ex_rent_arrears;
            this.successPopupActive = true;
          });
        })
        .catch((error) => {
          const errorMessage = getErrorMessage(error);
          this.toast(errorMessage);
          this.PaymentMethodStatus = error.code;
          this.errorMessage = errorMessage;
          if (error.code === 702) {
            this.$bvModal.show('large-customPayment-warning');
          } else {
            this.$store.dispatch('getFinanceDetails', { force: true }).then((res) => {
              this.financeDetails = res[this.index - 1];
              this.expectedPaymentMethod = this.financeDetails.expected_payment_setting;
              this.customAmount = this.financeDetails.next_total_monthly_payment_ex_rent_arrears;
              if (error) {
                this.errorPopupActive = true;
              }
            });
          }
        })
        .finally(() => {
          this.$vs.loading.close();
        });
    },
    confirmUnexpectedPaymentMethod() {
      this.$vs.loading();
      const payload = {
        reference: this.financeDetails.reference,
        value: this.unexpectedPaymentMethod,
      };

      userAPI
        .postChangeUnexpectedPaymentSettings(payload)
        .then((result) => {
          this.$store
            .dispatch('getFinanceDetails', { force: true })
            .then(() => this.$vs.loading.close())
            .catch(() => this.$vs.loading.close());
          const response = result.data.response;
          this.successPopupActive = true;
          this.PaymentMethodStatus = response.status;
          this.successMessage = response.messages[0];
        })
        .catch((error) => {
          this.$store
            .dispatch('getFinanceDetails', { force: true })
            .then((res) => {
              this.$vs.loading.close();
              this.financeDetails = res[this.index - 1];
              this.unexpectedPaymentMethod = this.financeDetails.unexpected_payment_outcome;
              if (error.response) {
                const errorRes = error.response.data.response;
                this.errorPopupActive = true;
                this.PaymentMethodStatus = errorRes.code;
                this.errorMessage = getErrorMessage(errorRes);
              }
            })
            .catch(() => this.$vs.loading.close());
        });
    },
    popupBtnClick() {
      if (this.PaymentMethodStatus === 702) {
        const payload = {
          reference: this.financeDetails.reference,
          amount: this.customAmount,
        };
        this.$vs.loading();
        userAPI
          .updateOneOffPayment(payload)
          .then((res) => {
            const response = res.data.response;
            this.$store
              .dispatch('getFinanceDetails', { force: true })
              .then(() => {
                userAPI
                  .getOneOffPayment({
                    params: {
                      reference: this.financeDetails.reference,
                    },
                  })
                  .then((res) => {
                    const payments = res.data.data.one_off_payment_notices.filter(
                      (i) => i.reference === this.financeDetails.reference
                    );
                    this.oneOffPayments = payments;

                    this.$vs.loading.close();
                    this.successMessage = response.messages[0];
                    this.successPopupActive = true;
                  })
                  .catch(() => this.$vs.loading.close());
              })
              .catch(() => {
                this.$vs.loading.close();
              });
          })
          .catch(() => {
            this.$vs.loading.close();
          });
      }
      if (this.PaymentMethodStatus === 701) {
        window.open('mailto:info@pfida.com', '_blank');
      }
      this.successPopupActive = false;
      this.errorPopupActive = false;
      this.$bvModal.hide('large-customPayment-warning');
    },
    showCancelOneOffPaymentModal(data) {
      this.selectedOneOffData = data;
      this.$bvModal.show('cancel-oneOff-payment-warning');
    },
    cancelOneOffPayment(ok) {
      const payload = {
        data: {
          date: this.selectedOneOffData.date,
          reference: this.financeDetails.reference,
        },
      };
      this.$vs.loading();
      userAPI
        .deleteOneOffPayment(payload)
        .then(() => {
          this.$store
            .dispatch('getFinanceDetails', { force: true })
            .then(() => {
              userAPI
                .getOneOffPayment({
                  params: {
                    reference: this.financeDetails.reference,
                  },
                })
                .then((res) => {
                  const response = res.data.data.one_off_payment_notices.filter(
                    (i) => i.reference === this.financeDetails.reference
                  );
                  this.oneOffPayments = response;
                  ok();
                  this.$vs.loading.close();
                })
                .catch(() => this.$vs.loading.close());
            })
            .catch(() => {
              this.$vs.loading.close();
            });
        })
        .catch(() => {
          this.$vs.loading.close();
        });
    },
    revertTargetPaymentAmount(cancel) {
      this.targetPaymentAmount = Number(
        this.financeDetails.next_total_monthly_recommended_payment.replace(/[^0-9.-]+/g, '')
      );
      cancel();
    },
    clickTargetCard() {
      if (this.isTargetPaymentSelected) {
        this.dismissCountDown = 10;
      }
    },
  },
  created() {
    // TODO: move all to store
    this.expectedPaymentMethod = this.financeDetails.expected_payment_setting;
    this.unexpectedPaymentMethod = this.financeDetails.unexpected_payment_outcome;
    this.customAmount = this.financeDetails?.next_total_monthly_payment_ex_rent_arrears;
    this.targetPaymentAmount = Number(
      this.financeDetails.next_total_monthly_recommended_payment.replace(/[^0-9.-]+/g, '')
    );

    userAPI
      .getOneOffPayment({
        params: {
          reference: this.financeDetails.reference,
        },
      })
      .then((res) => {
        const response = res.data.data.one_off_payment_notices.filter(
          (i) => i.reference === this.financeDetails.reference
        );
        this.oneOffPayments = response;
      });
  },
};
</script>

<style lang="scss" scoped>
.card--title {
  text-align: center;
  font-size: 16px;
  margin-bottom: 0.5rem;
}

.list-none {
  list-style-type: none;
}

.unexctedPayment-list {
  list-style-type: disc;
  list-style-position: inside;
  font-size: 14px;
  display: table;
  padding: 0 10px;
  margin: 0 auto;
}

.card-subtext {
  text-align: center;
  font-size: 14px;
  margin-block: 1rem;
}

.radio-button {
  margin: 10px;
  font-size: 14px;
}

.settings-card-content {
  font-size: 16px;
  text-align: center;
  align-content: center;
}

.disabledCard {
  opacity: 0.6;
  color: grey;

  .card--title {
    color: grey;
  }
}
</style>
