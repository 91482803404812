import { z } from 'zod';

import { createEventBus } from './utils/createEventBus';
import { singleton } from './utils/singleton';

export const LoginEventSchema = z.object({
  AccessToken: z.string(),
  IdToken: z.string(),
  redirectTo: z.string().optional(),
});

export const LogoutEventSchema = z.object({
  success: z.boolean(),
});

const eventConfig = {
  'mife:login': LoginEventSchema,
  'mife:logout': LogoutEventSchema,
};

export const initEventBus = () => singleton('eventBus', () => createEventBus(eventConfig));
