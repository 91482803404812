<!-- =========================================================================================
  File Name: AnonymousAffordabilityCalculator.vue
  Description: Affordability Calculator for non-logged in users
========================================================================================== -->

<template>
  <div class="page-wrapper">
    <div class="mt-8 flex items-center justify-center">
      <a href="https://pfida.com" target="_blank" rel="nofollow">
        <Logo width="100" />
      </a>
    </div>

    <div class="lg:px-18 md:px-16 sm:px-8 pt-4">
      <div class="mb-6 text-center">
        <h3>Affordability Calculator</h3>
      </div>
      <AffordabilityCalculator />
    </div>
  </div>
</template>

<script>
import Logo from '../../../../layouts/components/pics/Logo.vue';
import AffordabilityCalculator from '../AffordabilityCalculator.vue';

export default {
  components: {
    AffordabilityCalculator,
    Logo
  }
}
</script>
