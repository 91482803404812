<template>
  <div>
    <div v-if="!error" id="user-settings">
      <vs-tabs
        :position="isSmallerScreen ? 'top' : 'left'"
        class="profile-tabs"
        id="profile-tabs"
        :key="isSmallerScreen"
        :value="tabIndex"
      >
        <!-- EVERT TIME WE CHANGE THE ORDER OF THIS MAKE SURE TO CHANGE ZAKAT DATE INDEX ON DASHBOARD -->
        <!-- GENERAL -->
        <vs-tab icon-pack="feather" icon="icon-user" :label="'Personal details'">
          <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
            <UserSettingsPersonal />
          </div>
        </vs-tab>
        <vs-tab icon="phonelink_lock" :label="'Account and security'">
          <div class="tab-change-pwd md:ml-4 md:mt-0 mt-4 ml-0">
            <UserSettingsSecurity />
          </div>
        </vs-tab>
        <vs-tab icon="event" :label="'Zakat details'" v-if=this.$pfidaTenant>
          <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
            <UserSettingsZakat />
          </div>
        </vs-tab>
        <vs-tab icon="beach_access" :label="'Retirement details'">
          <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
            <UserSettingsRetired />
          </div>
        </vs-tab>
      </vs-tabs>
    </div>
    <ServerError500 v-else />
  </div>
</template>

<script>
import UserSettingsPersonal from './Personal/UserSettingsPersonal.vue'
import UserSettingsRetired from './RetirementDetails/UserSettingsRetired.vue'
import UserSettingsZakat from './Zakat/UserSettingsZakat.vue'
import UserSettingsSecurity from './Security/UserSettingsSecurity.vue'
import ServerError500 from '../../components/error-pages/ServerError500.vue'

export default {
  components: {
    UserSettingsPersonal,
    UserSettingsRetired,
    UserSettingsZakat,
    UserSettingsSecurity,
    ServerError500
  },
  data(props) {
    return {
      tabIndex: props.$route.params.index || 0,
      error: false
    }
  },
  methods: {
    getData() {
      this.$vs.loading()
      this.$store
        .dispatch('user/getUser')
        .then(() => {
          this.$store
            .dispatch('user/getRetirementAge')
            .then(() => {
              this.$vs.loading.close()
            })
            .catch(() => {
              this.$vs.loading.close()
            })
        })
        .catch(() => {
          this.error = true
          this.$vs.loading.close()
        })
    }
  },
  computed: {
    isSmallerScreen() {
      return this.$store.state.windowWidth < 768
    }
  },
  mounted() {
    this.getData()
  }
}
</script>

<style lang="scss">
#profile-tabs {
  .vs-tabs--content {
    padding: 0;
  }
}
</style>
