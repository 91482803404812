/*=========================================================================================
  File Name: moduleAuthMutations.js
  Description: Auth Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  SET_BEARER(state, idToken) {
    state.idToken = idToken;
  },
  SET_ACCESS_TOKEN(state, payload) {
    state.accessToken = payload;
  },
  SET_EMAIL(state, payload) {
    state.authEmail = payload;
  },
  SET_PASSWORD(state, payload) {
    state.password = payload;
  },
  SET_EXPIRY_DATE(state, payload) {
    state.tokenExpiry = payload;
  },
  SET_USERNAME(state, payload) {
    state.username = payload;
  },
  SET_AUTH_SESSION(state, payload) {
    state.authSession = payload;
  },
  SET_CHALLENGE_NAME(state, payload) {
    state.challengeName = payload;
  },
  SET_MARKETING(state, payload) {
    state.marketing = payload;
  },
};
