import { z } from 'zod';

export const VuexAuthSchema = z.object({
  auth: z.object({
    accessToken: z.string(),
    idToken: z.string(),
  }),
});

export type VuexAuth = z.infer<typeof VuexAuthSchema>;

export const IdTokenSchema = z.object({
  sub: z.string(),
  address: z
    .object({
      formatted: z.string(),
    })
    .optional(),
  birthdate: z.string(),
  iss: z.string(),
  'cognito:username': z.string().optional(),
  'custom:city': z.string().optional(),
  'custom:postcode': z.string().optional(),
  aud: z.string(),
  event_id: z.string(),
  token_use: z.string(),
  auth_time: z.number(),
  name: z.string(),
  phone_number: z.string(),
  exp: z.number(),
  iat: z.number(),
  email: z.string().email(),
});

export type IdToken = z.infer<typeof IdTokenSchema>;
