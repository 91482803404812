/*=========================================================================================
  File Name: globalComponents.js
  Description: Here you can register components globally
==========================================================================================*/

import Vue from 'vue';
import VueApexCharts from 'vue-apexcharts';
// v-select component
import vSelect from 'vue-select';

import FeatherIcon from './components/FeatherIcon.vue';
import VxCard from './components/vx-card/VxCard.vue';
import VxBreadcrumb from './layouts/components/VxBreadcrumb.vue';

Vue.component('apexchart', VueApexCharts);
Vue.component(VxCard.name, VxCard);
Vue.component(VxBreadcrumb.name, VxBreadcrumb);
Vue.component(FeatherIcon.name, FeatherIcon);

// Set the components prop default to return our fresh components
vSelect.props.components.default = () => ({
  Deselect: {
    render: (createElement) =>
      createElement('feather-icon', {
        props: {
          icon: 'XIcon',
          svgClasses: 'w-4 h-4 mt-1',
        },
      }),
  },
  OpenIndicator: {
    render: (createElement) =>
      createElement('feather-icon', {
        props: {
          icon: 'ChevronDownIcon',
          svgClasses: 'w-5 h-5',
        },
      }),
  },
});

Vue.component(vSelect);
