<!-- =========================================================================================
    File Name: LoginForceResetPassword.vue
    Description: Login Reset Password Page
========================================================================================== -->
<template>
  <div id="force-reset-password">
    <div class="vx-card__title mb-4 text-center">
      <h4 class="mb-4">Reset your password</h4>
      <p>Please create a new password.</p>
    </div>

    <div class="vx-col w-full mt-5">
      <b-form id="resetPwdForm" @submit.prevent="submitNewPassword">
        <b-form-group
          id="input-group-new-password"
          :invalid-feedback="newPwdFeedback"
          :state="newPwdInputState"
          label="New password:"
          label-for="input-password"
        >
          <b-input-group class="mb-2">
            <b-input-group-prepend is-text>
              <b-icon icon="person"></b-icon>
            </b-input-group-prepend>
            <b-form-input
              :state="newPwdInputState"
              id="input-new-password"
              v-model="newPassword"
              type="password"
              required
            ></b-form-input>
          </b-input-group>
        </b-form-group>

        <b-form-group
          id="input-group-confirm-password"
          :invalid-feedback="confirmPwdFeedback"
          :state="confirmPwdInputState"
          label="Confirm new password:"
          label-for="input-confirm-password"
        >
          <b-input-group class="mb-2">
            <b-input-group-prepend is-text>
              <b-icon icon="lock"></b-icon>
            </b-input-group-prepend>
            <b-form-input
              id="input-confirm-password"
              :state="confirmPwdInputState"
              v-model="confirmPassword"
              type="password"
              required
            ></b-form-input>
          </b-input-group>
        </b-form-group>

        <PasswordRules :password="newPassword" class="mt-4" />

        <div class="vx-col w-full mt-8">
          <b-button type="submit" :disabled="!validateForm" block class="hover:shadow-lg" variant="primary"
            >Reset Password</b-button
          >
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
import { getErrorMessage } from '@repo/utils'
import helpers from '../../../helpers'
import PasswordRules from '../../components/PasswordRules.vue'

export default {
  components: {
    PasswordRules
  },
  data() {
    return {
      newPassword: '',
      confirmPassword: ''
    }
  },
  computed: {
    validateForm() {
      return this.newPassword != '' && this.confirmPassword != '' && this.newPassword === this.confirmPassword
    },
    newPwdFeedback() {
      if (this.newPassword.length < 8) {
        return 'Password must be at least 8 characters'
      }
      if (
        !/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]).{8,}$/.test(
          this.newPassword
        )
      ) {
        return 'Password must match password policy'
      }
      return 'Password is required'
    },
    newPwdInputState() {
      if (
        (this.newPassword?.length > 7 &&
          /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]).{8,}$/.test(
            this.newPassword
          )) ||
        this.newPassword === ''
      ) {
        return null
      }
      return false
    },
    confirmPwdInputState() {
      if (
        (this.confirmPassword?.length > 7 && this.confirmPassword === this.newPassword) ||
        this.confirmPassword === ''
      ) {
        return null
      }
      return false
    },
    confirmPwdFeedback() {
      if (this.confirmPassword.length < 7) {
        return 'Password must be at least 8 characters'
      }
      if (this.confirmPassword != this.newPassword) {
        return 'Passwords must match'
      }
      return 'Password is required'
    }
  },
  methods: {
    /**
     * Checks if the user is logged in
     */
    isLoggedIn() {
      if (this.$store.state.auth.isUserLoggedIn()) {
        this.$vs.loading.close() // Close animation if passed as payload
        helpers.notifyWarning(this.$vs, 'You are already logged in!')

        this.$router.push(this.$router.currentRoute.query.to || '/').catch(() => {})
      }

      return false
    },

    async submitNewPassword() {
      if (this.isLoggedIn()) return
      if (!this.validateForm) return

      const clientSecret = await helpers.generateUserSecret(this.$store.state.auth.username)

      const payload = {
        challenge_name: this.$store.state.auth.challengeName,
        session: this.$store.state.auth.authSession,
        challenge_responses: {
          USERNAME: this.$store.state.auth.username,
          NEW_PASSWORD: this.confirmPassword
        },
        client: 'web',
        client_secret: clientSecret
      }
      this.$vs.loading() // Loading
      this.$store
        .dispatch('auth/validateLoginMFA', payload)
        .then((response) => {
          const accessToken = response?.data?.data?.AuthenticationResult?.AccessToken
          if (accessToken) {
            response = response.data

            // Set bearer token in axios
            this.$store.commit('auth/SET_BEARER', response.data.AuthenticationResult.IdToken)
            // store access token in storage
            this.$store.commit('auth/SET_ACCESS_TOKEN', response.data.AuthenticationResult.AccessToken)

            const expiresIn = response.data.AuthenticationResult.ExpiresIn * 1000

            // fetch user details
            this.$store
              .dispatch('user/getUser')
              .then(() => {
                this.updateLoginState(expiresIn)
                this.$vs.loading.close()

                // navigate to admin dashboard
                this.$router.push(this.$router.currentRoute.query.to || '/').catch(() => {})
              })
              .catch((error) => {
                this.$vs.loading.close()
                helpers.notifyError(this.$vs, getErrorMessage(error))
              })
          }
        })
        .catch((error) => {
          this.$vs.loading.close()
          this.$router.push('/login').catch(() => {})
          getErrorMessage(error)
        })
    },

    /**
     * Add user login params to storage.
     *
     * @param response
     */
    updateLoginState(expiresIn) {
      let expiryDate = Date.now() + expiresIn // 2 hours from now
      // Set token expiry and user login status
      this.$store.commit('auth/SET_EXPIRY_DATE', expiryDate.toString())

      // remove auth email and session
      this.$store.commit('auth/SET_EMAIL', '')
      this.$store.commit('auth/SET_AUTH_SESSION', '')
    }
  },
  mounted() {
    this.isLoggedIn()

    // check that 'auth session' and 'auth email' is in local storage. If not, redirect to login page with error
    if (!this.$store.state.auth.authEmail || !this.$store.state.auth.authSession) {
      this.$router.push('/login').catch(() => {})
    }
  }
}
</script>
