<template>
  <div>
    <vx-card no-shadow>
      <div class="vx-row grid">
        <div class="vx-col w-full sm:w-3/4 mb-base flex flex-column items-center place-self-center">
          <h4>Bank Details</h4>
          <p class="mb-6">
            Please provide your bank account information. This must be the same bank account from which you make payment
            to us, and we will only pay any redemption and dividends to this bank account.
          </p>
          <div class="w-full">
            <b-button
              class="float-right underline"
              v-if="$store.state.user.bankDetails?.account_number"
              variant="text"
              @click="enableFields"
              >{{ editText }}</b-button
            >
            <b-form id="userDetailsForm" @submit.prevent="setBankDetails">
              <b-form-group
                id="input-group-accountNumber"
                :invalid-feedback="accountNumberFeedback"
                :state="accountNumberInputState"
                label="Account Number:"
                label-for="input-accountNumber"
                :disabled="isDisabled"
              >
                <b-form-input
                  name="input-accountNumber"
                  v-model="accountNumber"
                  type="tel"
                  size="lg"
                  @keypress="onPhoneKeydown($event)"
                  :disabled="isDisabled"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                id="input-group-sortCode"
                :invalid-feedback="sortCodeFeedback"
                :state="sortCodeInputState"
                label="Sort Code:"
                label-for="input-sortCode"
              >
                <b-form-input
                  name="input-sortCode"
                  v-model="sortCode"
                  size="lg"
                  type="number"
                  :disabled="isDisabled"
                ></b-form-input>
              </b-form-group>

              <div class="justify-center text-center">
                <b-button class="p-2 lg:mt-0 mb-2" variant="primary" type="submit" :disabled="!validateBankDetails"
                  >Confirm</b-button
                >
              </div>
            </b-form>
          </div>
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>
import { getErrorMessage } from '@repo/utils'
import helpers from '../../../../helpers'

export default {
  data() {
    return {
      accountNumber: null,
      sortCode: null,
      isDisabled: true
    }
  },
  computed: {
    editText() {
      if (this.isDisabled) {
        return 'Edit'
      }
      return 'Cancel'
    },
    accountNumberFeedback() {
      if (this.accountNumber?.length <= 6 || this.accountNumber?.length >= 8) {
        return 'Please enter a valid Account Number'
      }
      return 'This field is required'
    },
    accountNumberInputState() {
      if (this.accountNumber === null) {
        return true
      }
      return this.accountNumber?.length >= 6 && this.accountNumber?.length <= 8
    },
    sortCodeFeedback() {
      if (this.sortCode?.length > 0) {
        return 'Please enter a valid Sort Code'
      }
      return 'This field is required'
    },
    sortCodeInputState() {
      if (this.sortCode === null) {
        return true
      }

      return this.sortCode?.length === 6
    },
    validateBankDetails() {
      if (!this.isDisabled && this.sortCode != null && this.accountNumber != null) {
        return this.accountNumberInputState && this.sortCodeInputState
      }
      return false
    }
  },
  methods: {
    onPhoneKeydown(e) {
      let char = String.fromCharCode(e.keyCode) // Get the character
      if (/^[0-9 +]+$/i.test(char)) return true
      // Match with regex
      else e.preventDefault() // If not match, don't add to input text
    },
    enableFields() {
      if (this.isDisabled) {
        this.isDisabled = false
        this.accountNumber = null
        this.sortCode = null
      } else {
        this.isDisabled = true
        this.accountNumber = `xxxx${this.$store.state.user.bankDetails?.account_number}`
        this.sortCode = this.$store.state.user.bankDetails?.sort_code
      }
    },
    setBankDetails() {
      this.$vs.loading()
      const payload = { account_number: this.accountNumber, sort_code: this.sortCode }
      this.$store
        .dispatch(`user/postBankDetails`, payload)
        .then((res) => {
          this.$vs.loading.close()
          const message = res.data.response.messages[0]
          helpers.notifySuccess(this.$vs, message)

          this.getBankDetails(true)
          this.isDisabled = true
        })
        .catch((error) => {
          this.$vs.loading.close()
          helpers.notifyError(this.$vs, getErrorMessage(error))
        })
    },
    getBankDetails(force = false) {
      this.$vs.loading()

      this.$store
        .dispatch(`user/getBankDetails`, { force: force })
        .then((res) => {
          this.$vs.loading.close()
          const sortCode = res.sort_code
          const accountNumber = res.account_number
          if (!sortCode || !accountNumber) {
            this.sortCode = null
            this.accountNumber = null
            this.isDisabled = false
          } else {
            this.accountNumber = `xxxx${accountNumber}`
            this.sortCode = sortCode
          }
        })
        .catch((error) => {
          this.$vs.loading.close()
          helpers.notifyError(this.$vs, getErrorMessage(error))
        })
    }
  },
  mounted() {
    this.getBankDetails()
  }
}
</script>

<style lang="scss" scoped></style>
