import { CustomError } from 'ts-custom-error';

const messages = {
  400: 'Bad Request',
  401: 'Unauthorized', // RFC 7235
  402: 'Payment Required',
  403: 'Forbidden',
  404: 'Not Found',
  405: 'Method Not Allowed',
  406: 'Not Acceptable',
  407: 'Proxy Authentication Required', // RFC 7235
  408: 'Request Timeout',
  409: 'Conflict',
  410: 'Gone',
  411: 'Length Required',
  412: 'Precondition Failed', // RFC 7232
  413: 'Payload Too Large', // RFC 7231
  414: 'URI Too Long', // RFC 7231
  415: 'Unsupported Media Type',
  416: 'Range Not Satisfiable', // RFC 7233
  417: 'Expectation Failed',
  418: "I'm a teapot", // RFC 2324
  421: 'Misdirected Request', // RFC 7540
  426: 'Upgrade Required',
  428: 'Precondition Required', // RFC 6585
  429: 'Too Many Requests', // RFC 6585
  431: 'Request Header Fields Too Large', // RFC 6585
  451: 'Unavailable For Legal Reasons', // RFC 7725
  500: 'Internal Server Error',
  501: 'Not Implemented',
  502: 'Bad Gateway',
  503: 'Service Unavailable',
  504: 'Gateway Timeout',
  505: 'HTTP Version Not Supported',
  506: 'Variant Also Negotiates', // RFC 2295
  510: 'Not Extended', // RFC 2774
  511: 'Network Authentication Required', // RFC 6585
} as const;

export type Messages = typeof messages;
export type StatusCodes = keyof typeof messages;

export class HttpError extends CustomError {
  protected static messages = messages;

  public constructor(
    public code: StatusCodes,
    message: string
  ) {
    super(message);
  }
}

/**
 * the http function returns a new HttpError that can be thrown
 *
 * @example
 * http(500, "Something went wrong")
 *
 * @param code the status code
 * @param customMessage The message to throw an error with. If none will be provided, a default message
 * from the HttpError will be provided
 *
 * @return {HttpError}
 */
export function http(code: StatusCodes, customMessage?: string): HttpError {
  return new HttpError(code, customMessage || messages[code]);
}

/**
 * Checks if the type of error is HttpError. It allows you to cast the error type properly
 * so you can handle it accordingly
 *
 * @example
 * isHttpError(error)
 *
 * @param error The condition to check
 *
 * @return {boolean} true = the instance of the error is invariant, false = the instance of the error is not invariant
 * and it will not cast the error types.
 */
export function isHttpError(error: unknown): error is HttpError {
  return error instanceof HttpError;
}
