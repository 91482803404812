import actions from './calculatorsModuleActions';
import mutations from './calculatorsModuleMutations';
import state from './calculatorsModuleState';

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
};
