<template>
  <div id="server-error" class="error-message flex justify-center items-center">
    <div class="text-center">
      <div>
        <b-col class="mb-4">
          <b-icon icon="exclamation-triangle" aria-label="Error" font-scale="4"></b-icon>
          <h1 class="text-5xl">500</h1>
          <h2>Oops! Something went wrong</h2>
        </b-col>
      </div>
      <p class="mb-4">
        Please refresh your page, If the problem persists, please
        <a href="mailto:devs@pfida.com"
          >contact us <b-icon icon="envelope-fill" class="ml-1" aria-hidden="true"></b-icon>
        </a>
      </p>
      <b-button @click="refresh" variant="warning" @mouseover="animation = true" @mouseleave="animation = false"
        >Reload
        <b-icon icon="arrow-counterclockwise" :animation="animation ? 'spin-reverse' : ''" font-scale="1"></b-icon
      ></b-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    refreshFN: {
      type: Function
    }
  },
  data() {
    return {
      animation: false
    }
  },
  methods: {
    refresh() {
      if (this.refreshFN) {
        this.refreshFN()
      } else {
        location.reload()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.error-message {
  min-height: calc(var(--vh, 1vh) * 100 - 18rem);
}
</style>
