<template>
  <div
    class="trustpilot-widget"
    id="trustpilot-widget-pf"
    data-locale="en-GB"
    data-template-id="56278e9abfbbba0bdcd568bc"
    data-businessunit-id="61b87e6fa7a4beb2e5e92670"
    data-style-height="52px"
    data-style-width="100%"
  >
    <a href="https://uk.trustpilot.com/review/primary-finance.com" target="_blank" rel="noopener">Trustpilot</a>
  </div>
</template>
<script>
export default {
  mounted() {
    this.$nextTick(() => {
      const trustbox = document.getElementById(`trustpilot-widget-pf`)
      if (window.Trustpilot) {
        window.Trustpilot.loadFromElement(trustbox, true)
      }
    })
  }
}
</script>
