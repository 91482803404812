import type { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { getErrorMessage, http, StatusCodes } from '@repo/utils';
import * as s from '@sentry/browser';
import axios from 'axios';

import { setupInterceptors } from './interceptors';

/**
 * Subset of AxiosRequestConfig
 */
export type RequestConfig<TData = unknown> = {
  baseURL?: string;
  url?: string;
  method: 'get' | 'put' | 'patch' | 'post' | 'delete' | 'GET' | 'PUT' | 'POST' | 'DELETE';
  params?: unknown;
  data?: TData;
  responseType?: 'arraybuffer' | 'blob' | 'document' | 'json' | 'text' | 'stream';
  signal?: AbortSignal;
  headers?: AxiosRequestConfig['headers'];
};
/**
 * Subset of AxiosResponse
 */
export type ResponseConfig<TData = unknown> = {
  data: TData;
  status: StatusCodes;
  statusText: string;
  headers?: AxiosResponse['headers'];
};

type CreateAxiosClientOptions = {
  serviceName: string;
  baseURL: string;
};

export function createAxiosClient(options: CreateAxiosClientOptions) {
  const axiosInstance = setupInterceptors(axios.create({ baseURL: options.baseURL }));

  const axiosClient = async <TData, TError = unknown, TVariables = unknown>(
    config: RequestConfig<TVariables>
  ): Promise<ResponseConfig<TData>> =>
    s.startSpan({ name: options.serviceName }, async () => {
      const promise = axiosInstance.request<TData, ResponseConfig<TData>>(config).catch((err: AxiosError<TError>) => {
        const errorCode = (err.response?.status as StatusCodes) || 500;

        const customErrorMessage = getErrorMessage(err);

        s.captureException(err);
        s.setTag('axios_instance_status', errorCode);
        throw http(errorCode, customErrorMessage);
      });

      return promise;
    });

  return axiosClient;
}
