import type { EventType, Handler } from 'mitt';
import mitt from 'mitt';
import { z } from 'zod';

type Evt = Record<EventType, z.ZodTypeAny>;

export function createEventBus<Events extends Evt>(config: Events) {
  const m = mitt<Events>();

  function emit<Key extends keyof Events>(type: Key, event: z.infer<Events[Key]>): void {
    m.emit(type, config[type]?.parse(event));
  }

  function on<Key extends keyof Events>(type: Key, handler: Handler<z.infer<Events[Key]>>): void {
    m.on(type as any, handler);
  }

  function off<Key extends keyof Events>(type: Key, handler: Handler<z.infer<Events[Key]>>): void {
    m.off(type as any, handler);
  }

  return { emit, on, off, all: m.all };
}
