<template>
  <b-card class="shadow" body-class="wrapper--medium" v-if="!startAiPCheck">
    <div id="about">
      <h2 class="mb-4">What is an Acceptance in Principle (AiP)?</h2>
      <p class="text-lg mb-4">
        An Acceptance in Principle is a simple way to find out if you would meet our eligibility criteria for getting
        finance on a property, without a credit check. Simply tell us how much finance you require, what your income and
        regular spending is and we'll give you an answer straight away. It takes about 5 minutes to complete.
      </p>

      <p class="text-lg mb-4">
        You are not committing to anything as it's just an indication. Note that an AiP is not a guarantee we'll give
        you finance.
      </p>

      <p class="text-lg mb-8">
        By continuing, you agree to Pfida holding and processing your data in line with its privacy policy.
      </p>

      <div class="vx-col w-full sm:w-6/12 md:w-1/3 mt-8 mb-4 ml-auto">
        <b-button variant="primary" class="hover:shadow-lg" block @click.prevent="continueToAiPCheck">
          Continue to AiP check
        </b-button>
      </div>
    </div>
  </b-card>

  <div v-else>
    <FormWizard
      color="rgba(var(--vs-primary), 1)"
      errorColor="rgba(var(--vs-danger), 1)"
      :title="null"
      :subtitle="null"
      finishButtonText="Submit"
    >
      <!-- tab 1 content -->
      <TabContent title="Your details" class="mb-5" icon="feather icon-user" :before-change="validateStep1">
        <Step1 ref="step1Form" />
      </TabContent>

      <!-- tab 2 content -->
      <TabContent title="Your finances" class="mb-5" icon="feather icon-briefcase" :before-change="validateStep2">
        <Step2 ref="step2Form" />
      </TabContent>

      <!-- tab 3 content -->
      <TabContent title="Property Details" class="mb-5" icon="feather icon-home" :before-change="validateStep3">
        <Step3 ref="step3Form" />
      </TabContent>
    </FormWizard>
  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

// For custom error message
import helpers from '../../../helpers'

import Step1 from './components/Step1.vue'
import Step2 from './components/Step2.vue'
import Step3 from './components/Step3.vue'
import { getErrorMessage } from '@repo/utils'
// register custom messages

export default {
  components: {
    FormWizard,
    TabContent,
    Step1,
    Step2,
    Step3
  },
  data() {
    return {
      startAiPCheck: false,
      popupActivo: false
    }
  },
  methods: {
    continueToAiPCheck() {
      this.startAiPCheck = true
    },
    convertBoolean(input) {
      return input === 'Yes' || input === 'Agree' ? 'yes' : 'no'
    },
    propertyTypeChange(val) {
      //clear optional fields
      if (val != 'investment property') {
        this.isCommercialLet = null
        this.willInhabit = null
        this.HaveInhabit = null
        this.ownOtherLets = null
      }
    },
    validateStep1() {
      const { fullName, email, nationality, countryOfResidence, isValidated, touched } = this.$refs.step1Form
      touched.name = true
      touched.email = true
      touched.nationality = true
      touched.countryOfResidence = true

      return new Promise((resolve, reject) => {
        if (isValidated) {
          this.$store.dispatch('calculators/updateAIPData', {
            fullName,
            email,
            nationality,
            countryOfResidence
          })
          resolve(true)
        } else {
          reject('Please fill in the required fields')
        }
      })
    },
    validateStep2() {
      const {
        employmentType,
        continuousEmployment,
        haveYearsOfAccounts,
        historyOfBankruptcy,
        grossAnnualIncome,
        monthlyFinancialCommitments,
        netMonthlyIncome,
        isValidated,
        touched
      } = this.$refs.step2Form

      touched.employmentType = true
      touched.continuousEmployment = true
      touched.haveYearsOfAccounts = true
      touched.historyOfBankruptcy = true
      touched.grossAnnualIncome = true
      touched.monthlyFinancialCommitments = true
      touched.netMonthlyIncome = true
      return new Promise((resolve, reject) => {
        if (isValidated) {
          this.$store.dispatch('calculators/updateAIPData', {
            employmentType,
            continuousEmployment,
            haveYearsOfAccounts,
            historyOfBankruptcy,
            grossAnnualIncome,
            monthlyFinancialCommitments,
            netMonthlyIncome
          })
          resolve(true)
        } else {
          reject('Please fill in the required fields')
        }
      })
    },
    validateStep3() {
      const {
        propertyType,
        financePurpose,
        estimatedPropertyValue,
        financeRequired,
        term,
        isPropertyMainResidence,
        isFirstTimeBuyer,
        propertyLocation,
        isCommercialLet,
        willInhabit,
        HaveInhabit,
        ownOtherLets,
        isValidated,
        touched
      } = this.$refs.step3Form

      touched.propertyType = true
      touched.financePurpose = true
      touched.estimatedPropertyValue = true
      touched.financeRequired = true
      touched.term = true
      touched.isPropertyMainResidence = true
      touched.isFirstTimeBuyer = true
      touched.propertyLocation = true
      touched.isCommercialLet = true
      touched.willInhabit = true
      touched.HaveInhabit = true
      touched.ownOtherLets = true

      return new Promise((resolve, reject) => {
        if (isValidated) {
          this.$store.dispatch('calculators/updateAIPData', {
            propertyType,
            financePurpose,
            estimatedPropertyValue,
            financeRequired,
            term,
            isPropertyMainResidence,
            isFirstTimeBuyer,
            propertyLocation,
            isCommercialLet,
            willInhabit,
            HaveInhabit,
            ownOtherLets
          })
          this.submitForm()

          resolve(true)
        } else {
          reject('Please fill in the required fields')
        }
      })
    },
    submitForm() {
      this.$router.push('/calculators/acceptance-principle/result')
    }
  },
  mounted() {
    if (this.$route.params.skipAIPCheck) {
      this.startAiPCheck = true
    }
  },
  created() {
    this.$store
      .dispatch('calculators/getCountriesList')
      .then(() => {
        if (!this.$store.state.calculators.AIP?.employmentType) {
          this.$store.dispatch('calculators/getCalculatorHistory').then((response) => {
            const latest = response.find((e) => e.type === 'AIP')
            this.$store.dispatch('calculators/updateAIPData', {
              nationality: latest.nationality,
              countryOfResidence: latest.country,
              employmentType: latest.employment_type,
              continuousEmployment: latest.continuous_employment,
              haveYearsOfAccounts: latest.accounts_records,
              historyOfBankruptcy: latest.ccj_bankruptcy,
              grossAnnualIncome: latest.annual_income,
              monthlyFinancialCommitments: latest.monthly_spend,
              netMonthlyIncome: latest.net_monthly_income,
              propertyType: latest.property_type,
              financePurpose: latest.finance_purpose,
              estimatedPropertyValue: latest.property_value,
              financeRequired: latest.deposit,
              term: latest.term,
              isPropertyMainResidence: latest.main_residence,
              isFirstTimeBuyer: latest.first_time_buyer,
              propertyLocation: latest.property_location,
              isCommercialLet:
                latest.property_type === 'investment property' && latest.commercial_let
                  ? latest.commercial_let
                  : undefined,
              willInhabit:
                latest.property_type === 'investment property' && latest.will_inhabit ? latest.will_inhabit : undefined,
              HaveInhabit:
                latest.property_type === 'investment property' && latest.have_inhabited
                  ? latest.have_inhabited
                  : undefined,
              ownOtherLets:
                latest.property_type === 'investment property' && latest.own_other_lets
                  ? latest.own_other_lets
                  : undefined
            })
          })
        }
      })
      .catch((error) => {
        helpers.notifyError(this.$vs, getErrorMessage(error))
      })
  }
}
</script>

<style lang="scss">
.v-select {
  background-color: white;
}

.alignment-fix {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
</style>
