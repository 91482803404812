import { BaseUrl } from '../../types';
import { createAxiosClient } from '../../utils/createAxiosClient';
import { getBaseUrl } from '../../utils/getBaseUrl';

const baseUrl: BaseUrl = {
  production: 'https://api.pfida.com',
  development: 'https://api-dev.pfida.com',
};

const userBackendClient = createAxiosClient({
  baseURL: getBaseUrl(baseUrl),
  serviceName: 'USER_BACKEND',
});

export default userBackendClient;
