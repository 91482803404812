/*=========================================================================================
  File Name: moduleCalendarMutations.js
  Description: Calendar Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
import {
  SET_BANK_DETAILS,
  SET_DOCUMENTS,
  SET_ERROR,
  SET_INVESTOR_DECLARATION,
  SET_LOADING,
  SET_REFERRAL_SETTINGS_DATA,
  SET_REFERRAL_SPLIT,
  SET_REFERRALS,
  SET_RETIREMENT_AGE,
  SET_UNREAD_DOCS_AMOUNT,
  SET_USER_DETAILS,
  SET_ZAKAT_DATE,
  TOGGLE_MFA,
  UPDATE_DOCUMENT,
} from './userMutationTypes.js';

export default {
  [SET_RETIREMENT_AGE](state, payload) {
    state.RetirementAge = payload;
  },
  [TOGGLE_MFA](state, payload) {
    state.MFA = payload;
  },
  [SET_USER_DETAILS](state, payload) {
    for (const property of Object.keys(payload)) {
      if (payload[property] != null) {
        // If some of user property is null - user default property defined in state.user.details
        state.details[property] = payload[property];
      }
    }
  },
  [SET_INVESTOR_DECLARATION](state, payload) {
    state.investorDeclaration = payload;
  },
  [SET_UNREAD_DOCS_AMOUNT](state, payload) {
    state.UnreadDocuments = payload;
  },
  [UPDATE_DOCUMENT](state, { index, payload }) {
    state.Documents[index].unread = payload;
  },
  [SET_ZAKAT_DATE](state, payload) {
    state.zakatDate = payload;
  },
  [SET_REFERRALS](state, payload) {
    state.referrals.data = payload;
  },
  [SET_REFERRAL_SPLIT](state, payload) {
    state.referrals.settings.value = payload;
  },
  [SET_REFERRAL_SETTINGS_DATA](state, payload) {
    state.referrals.settings.options = payload.options;
    state.referrals.settings.value = payload.value;
  },
  [SET_DOCUMENTS](state, payload) {
    state.Documents = payload;
  },
  [SET_BANK_DETAILS](state, payload) {
    state.bankDetails = payload;
  },
  [SET_LOADING](state, { dataName, status }) {
    // create a property in status
    state.status[dataName].loading = status;
  },
  [SET_ERROR](state, { dataName, status }) {
    // create a property in status
    state.status[dataName].error = status;
  },
};
