<template>
  <div>
    <vx-card no-shadow>
      <div class="grid">
        <div class="w-full sm:w-3/4 mb-base place-self-center">
          <h4 class="text-center mb-6">What would you like your zakat date to be?</h4>
          <p class="text-center mb-8">
            If you are a zakat payer, please let us know when you usually pay zakat. Our platform will notify you on
            this day if you have any funds invested with us that zakat must be paid on.
          </p>
          <div class="mb-base">
            <b-container>
              <b-row>
                <b-col>
                  <div class="col-xs-2">
                    <p>Select day:</p>
                    <b-form-select v-model="hijriDay" :options="days"></b-form-select>
                  </div>
                </b-col>
                <b-col>
                  <div id="select-month" class="col-xs-2">
                    <p>Select month:</p>
                    <b-form-select v-model="hijriMonth" :options="months"></b-form-select>
                  </div>
                </b-col>
              </b-row>
            </b-container>
          </div>

          <div class="text-center">
            <b-button class="p-2 mt-4" variant="primary" @click="setZakatDate"
              :disabled="ZakatDateChanged">Confirm</b-button>
          </div>
        </div>
      </div>
    </vx-card>

    <b-modal id="modal-center" centered :title="responseType">
      {{ modalMessage }}
      <template #modal-footer="{ ok }">
        <b-button size="lg" class="shadow-md lg:mt-0 mb-2" variant="primary" @click="ok()">OK</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import helpers from '../../../../helpers'

export default {
  data: () => {
    return {
      hijriMonth: '',
      hijriDay: 'zakat_day',
      months: [
        { value: null, text: '' },
        { value: '01', text: 'Muharram' },
        { value: '02', text: 'Safar' },
        { value: '03', text: 'Rabi-ul-Awwal' },
        { value: '04', text: 'Rabi-ul-Thani' },
        { value: '05', text: 'Jumada-l-Ula' },
        { value: '06', text: 'Jumada-th-Thaniyya' },
        { value: '07', text: 'Rajab' },
        { value: '08', text: 'Shaaban' },
        { value: '09', text: 'Ramadhan' },
        { value: '10', text: 'Shawwal' },
        { value: '11', text: 'Dhul Qa‘dah' },
        { value: '12', text: 'Dhul Hijja' }
      ],
      modalMessage: '',
      responseType: ''
    }
  },
  methods: {
    setZakatDate() {
      this.$vs.loading()

      this.$store
        .dispatch('user/updateZakatDate', { value: `${this.hijriDay}/${this.hijriMonth}` })
        .then((res) => {
          this.$vs.loading.close()
          const response = res.data.response
          helpers.notifySuccess(this.$vs, response.messages[0])
        })
        .catch((error) => {
          const response = error.response.data.response
          this.modalMessage = response.messages[0]
          this.responseType = response.type
          this.$vs.loading.close()
          this.$bvModal.show('modal-center')
        })
    }
  },
  computed: {
    days() {
      const days = [{ value: null, text: '' }]
      for (let i = 1; i < 30; i++) {
        if (i < 10) {
          days.push({ value: `0${i}`, text: i })
        } else {
          days.push({ value: `${i}`, text: i })
        }
      }
      return days
    },
    ZakatDateChanged() {
      if (
        this.hijriDay != this.$store?.state?.zakatDate?.split('/')[0] ||
        this.hijriMonth != this.$store.state?.zakatDate?.split('/')[1]
      ) {
        return false
      }
      return true
    }
  },
  created() {
    this.hijriDay = this.$store.state.user?.zakatDate?.split('/')[0]
    this.hijriMonth = this.$store.state.user?.zakatDate?.split('/')[1]
  }
}
</script>

<style lang="scss" scoped></style>
