<template>
  <div class="flex justify-center">
    <b-card class="shadow w-full md:w-3/4" :body-class="loaded ? 'wrapper--small' : ''">
      <AcceptanceInPrincipleResult
        v-if="loaded === true"
        :title="resultTitle"
        :acceptance="acceptance"
        :messages="messages"
        :goToPage="goToPage"
      />
      <div v-else-if="loaded === 'error'" class="text-center h-48">
        <GeneralError />
      </div>
      <div class="res-skeleton" v-else>
        <div class="flex block justify-center">
          <b-skeleton class="block" animation="wave" width="55%"></b-skeleton>
        </div>
        <div class="flex block justify-center">
          <b-skeleton class="block" animation="wave" width="55%"></b-skeleton>
        </div>
        <div class="mt-2">
          <b-skeleton animation="wave" width="100%"></b-skeleton>
          <b-skeleton animation="wave" width="100%"></b-skeleton>
          <b-skeleton animation="wave" width="100%"></b-skeleton>
          <b-skeleton animation="wave" width="100%"></b-skeleton>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import AcceptanceInPrincipleResult from '../../components/primary/calculators/AcceptanceInPrincipleResult.vue'
import { mapState } from 'vuex'
import dataMapper from './dataMapping'
import GeneralError from '../../components/error-pages/generalError.vue'
import helpers from '../../../helpers'
import { userBackendClient } from '@repo/api/services/userBackend'
import { getErrorMessage } from '@repo/utils'

export default {
  components: {
    AcceptanceInPrincipleResult,
    GeneralError
  },
  data() {
    return {
      loaded: false,
      resultTitle: '',
      acceptance: '',
      messages: ''
    }
  },
  computed: {
    ...mapState(['calculators'])
  },
  methods: {
    goToPage(page) {
      this.$router.push(page)
    }
  },
  mounted() {
    const mappedPayload = dataMapper(this.calculators.AIP)

   userBackendClient({
    method: "post",
    url: "/eligibility",
    data: JSON.stringify(mappedPayload)
   }).then((response) => {
        this.loaded = true
        this.$store.dispatch('calculators/getCalculatorHistory', { force: true })
        this.$store.dispatch('calculators/updateAffordabilityCalculatorData', {
          net_monthly_household_income: mappedPayload.net_monthly_income,
          deposit: mappedPayload.deposit,
          gross_annual_income: mappedPayload.annual_income
        })

        this.$store.dispatch('calculators/updateCostCalculatorData', {
          property_value: mappedPayload.property_value,
          deposit: mappedPayload.deposit,
          term: mappedPayload.term
        })
        this.messages = response.data.response.messages
        const answer = response.data.data.answer
        this.acceptance = {
          answer
        }
        if (answer === 'yes') {
          this.resultTitle = 'Congratulations'
        } else if (answer === 'no') {
          this.resultTitle = 'Sorry'
        } else if (answer === 'maybe') {
          this.resultTitle = 'We need more information'
        }
      })
      .catch((error) => {
        this.loaded = 'error'
        helpers.notifyError(this.$vs, getErrorMessage(error))
      })
  }
}
</script>

<style lang="scss" scoped>
.res-skeleton {
  min-height: 150px;
}
</style>
