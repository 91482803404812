<!-- =========================================================================================
    File Name: ForgotPassword.vue
    Description: Forgot Password Page
========================================================================================== -->

<template>
  <div id="forgot-password">
    <div class="vx-card__title mb-8 text-center">
      <h4 class="mb-4">Reset Password</h4>
    </div>

    <div class="vx-col w-full">
      <b-form id="loginForm" @submit.prevent="sendForgotPassword">
        <b-form-group
          id="input-group-email"
          :invalid-feedback="emailFeedback"
          :state="emailInputState"
          label="Email:"
          label-for="input-email"
        >
          <b-input-group class="mb-2">
            <b-input-group-prepend is-text>
              <b-icon icon="phone"></b-icon>
            </b-input-group-prepend>
            <b-form-input :state="emailInputState" id="input-email" v-model="email" type="text" required></b-form-input>
          </b-input-group>
        </b-form-group>

        <div class="vx-col w-full flex justify-between mt-8">
          <b-button variant="outline-primary" to="/login" class="mb-2 hover:shadow-lg px-4 w-4/12"
            >Back To Login</b-button
          >
          <b-button type="submit" variant="primary" :disabled="!validateForm" class="mb-2 hover:shadow-lg px-4 w-7/12"
            >Send Email</b-button
          >
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
import { getErrorMessage } from '@repo/utils'
import helpers from '../../../helpers'

export default {
  data() {
    return {
      email: ''
    }
  },
  computed: {
    validateForm() {
      return this.email != '' && helpers.emailRegex().test(this.email)
    },
    emailFeedback() {
      if (this.email.length > 0) {
        return 'Please enter a valid Email'
      }
      return 'Email is required'
    },
    emailInputState() {
      if ((this.email?.length > 4 && helpers.emailRegex().test(this.email)) || this.email === '') {
        return null
      }
      return false
    }
  },
  methods: {
    async sendForgotPassword() {
      const formattedEmail = this.email?.toLowerCase().replace(/ /g, '')
      const clientSecret = await helpers.generateUserSecret(formattedEmail)

      const payload = {
        email: formattedEmail,
        client: 'web',
        client_secret: clientSecret
      }

      this.$vs.loading()
      this.$store
        .dispatch('auth/forgotPassword', payload)
        .then(() => {
          // save 'auth session' and 'email' in state then redirect to 2fa screen
          this.$store.commit('auth/SET_EMAIL', formattedEmail)
          this.$router.push("/forgot-password/confirm")
          this.$vs.loading.close()
        })
        .catch((error) => {
          this.$vs.loading.close()
          helpers.notifyError(this.$vs, getErrorMessage(error))
        })
    },
  },
}
</script>
