<template>
  <div>
    <div v-if="!error">
      <portal to="page-title">
        <h2 v-if="pageTitle" class="">{{ pageTitle }}</h2>
      </portal>

      <vs-tabs :position="tabPosition" :value="tabIndex" alignment="fixed">
        <vs-tab label="Transactions">
          <SavingsTab
            :breakdown="breakdown"
            :total_investment="total_investment"
            :expected_profit="expected_profit"
            :expected_profit_aer="expected_profit_aer"
            :loaded="loaded"
          ></SavingsTab>
        </vs-tab>

        <vs-tab label="Settings">
          <div class="con-tab-ejemplo mt-10">
            <SettingsTab :divedendReinvestment="divedendReinvestment"></SettingsTab>
          </div>
        </vs-tab>
      </vs-tabs>
    </div>
    <ServerError500 v-else />
  </div>
</template>

<script>
import SavingsTab from './tabs/SavingsTab.vue'
import SettingsTab from './tabs/SettingsTab.vue'
import ServerError500 from '../../components/error-pages/ServerError500.vue'
import userAPI from '../../../http/requests/user/userAPI'

export default {
  components: {
    SavingsTab,
    SettingsTab,
    ServerError500
  },
  data(props) {
    return {
      tabPosition: window.innerWidth < 550 ? 'center' : 'bottom',
      loaded: false,
      breakdown: [],
      total_investment: '',
      pageTitle: props.$store.state.investmentDetails?.pfhpp?.title || '',
      expected_profit: '',
      expected_profit_aer: '',
      divedendReinvestment: '',
      tabIndex: 0,
      error: false,
      index: ''
    }
  },
  methods: {
    getHistory() {
      // ? already stored in local storage in dashboard use that and store in vuex
      this.$store
        .dispatch('getInvestmentDetails')
        .then((response) => {
          this.loaded = true

          const data = response.pfhpp[this.index - 1]

          this.total_investment = data.total_investment
          this.expected_profit = data.expected_profit
          this.expected_profit_aer = data.expected_profit_aer
          if (!this.pageTitle) this.pageTitle = data.title

          this.$vs.loading()
          userAPI
            .getDividendsReinvestemnts(data.investment_product)
            .then((res) => {
              this.$vs.loading.close()
              this.divedendReinvestment = !!res.data.data.value
            })
            .catch(() => {
              this.$vs.loading.close()
              this.error = true
            })
          this.setBreakdownDetails(data.investment_history)
        })
        .catch(() => {
          this.$vs.loading.close()

          this.error = true
        })
    },
    setBreakdownDetails(details) {
      this.breakdown = details.map((x, index) => ({
        ...x,
        id: index,
        balance_payable: x.dividends_on_account + x.total_share_value
      }))
    }
  },
  created() {
    this.index = this.$route.params.index
    this.getHistory()
  }
}
</script>
