/*=========================================================================================
  File Name: main.js
  Description: main vue(js) file
==========================================================================================*/

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import singleSpaVue from 'single-spa-vue';
import { cssLifecycleFactory } from 'vite-plugin-single-spa/ex';
import Vue from 'vue';

import './assets/css/iconfont.css';

// Vuesax Component Framework
import Vuesax from 'vuesax';

import helpers from './helpers';
import store from './store/store';

import 'material-icons/iconfont/material-icons.css'; //Material Icons
import 'vuesax/dist/vuesax.css'; // Vuesax

// lodash
import lodash from 'lodash';
// moment
import moment from 'moment';

//import sentry tracing

// Filters
import filters from './filters/filters';
// Theme Configurations
import '../themeConfig.js';

import PortalVue from 'portal-vue';

// Auth Plugin
import AuthPlugin from './plugins/auth';
// Globally Registered Components
import './globalComponents.js';
// Styles: SCSS
import './assets/scss/main.scss';
// Main CSS file contains Tailwind
import '@/assets/css/main.css';

import { isTenant } from '@repo/utils';
import VueCountryCode from 'vue-country-code-select';

import App from './App.vue';
import constants from './constants/constants';
import { eventBus } from './plugins/eventBus.js';
// Vue Router
import router from './router';

// Vuex Store
Vue.use(BootstrapVue, {
  BModal: {
    static: true,
    lazy: true,
  },
});
Vue.use(IconsPlugin);

Vue.use(Vuesax);

Vue.prototype.$lodash = lodash;

Vue.prototype.$moment = moment;

Vue.prototype.$filters = filters;

Vue.use(AuthPlugin);

Vue.use(PortalVue);

Vue.prototype.$constants = constants;
Vue.prototype.$eventBus = eventBus;
Vue.prototype.$pfidaTenant = isTenant('pfida');

// Feather font icon
import('./assets/css/iconfont.css');

Vue.use(VueCountryCode);

Vue.config.productionTip = false;
const cssLifecycles = cssLifecycleFactory('parcel');

export function vueParcel() {
  const vueLifecycles = singleSpaVue({
    Vue,
    appOptions: {
      render(h) {
        return h(App);
      },
      router,
      store,
      helpers,
    },
  });

  return {
    bootstrap: [cssLifecycles.bootstrap, vueLifecycles.bootstrap],
    mount: [cssLifecycles.mount, vueLifecycles.mount],
    unmount: [cssLifecycles.unmount, vueLifecycles.unmount],
    update: vueLifecycles.update,
  };
}
