<!-- =========================================================================================
    File Name: Main.vue
    Description: Main layout
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
    Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>


  <div class="router-view">
    <div class="router-content">
      <transition :name="routerTransition">
        <div v-if="$route.meta.breadcrumb || $route.meta.pageTitle"
          class="router-header flex flex-wrap items-center mb-6">
          <div class="content-area__heading"
            :class="{ 'pr-4 border-0 md:border-r border-solid border-grey-light': $route.meta.breadcrumb }">
            <h2 class="mb-1">
              {{ routeTitle }}
              <portal-target name="page-title"></portal-target>
            </h2>
          </div>

          <!-- BREADCRUMB -->
          <vx-breadcrumb class="ml-4 md:block hidden" v-if="$route.meta.breadcrumb" :route="$route" :isRTL="$vs.rtl" />
          <portal-target name="ref"></portal-target>
        </div>
      </transition>

      <div class="content-area__content">
        <back-to-top bottom="7.6%" :right="$vs.rtl ? 'calc(100% - 2.2rem - 38px)' : '30px'" visibleoffset="500"
          v-if="!hideScrollToTop">
          <b-button class="shadow-lg btn-back-to-top" variant="primary">
            <b-icon icon="chevron-up" aria-label="Help"></b-icon>
          </b-button>
        </back-to-top>
        <transition :name="routerTransition" mode="out-in">
          <router-view @changeRouteTitle="changeRouteTitle"
            @setAppClasses="(classesStr) => $emit('setAppClasses', classesStr)" />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import BackToTop from 'vue-backtotop'
import themeConfig from '@/../themeConfig.js'

export default {
  components: {
    BackToTop,

  },
  data() {
    return {
      disableCustomizer: themeConfig.disableCustomizer,
      hideScrollToTop: themeConfig.hideScrollToTop,
      routerTransition: themeConfig.routerTransition || 'none',
      routeTitle: this.$route.meta.pageTitle
    }
  },
  watch: {
    $route() {
      this.routeTitle = this.$route.meta.pageTitle
    },
  },
  methods: {
    changeRouteTitle(title) {
      this.routeTitle = title
    },
  },
}
</script>
