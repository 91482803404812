import { CustomError } from 'ts-custom-error';

export class InvariantError extends CustomError {
  public constructor(message: string) {
    super(message);
  }
}

/**
 * Provide a condition and if that condition is falsey, this throws an error
 * with the given message.
 *
 * inspired by invariant from 'tiny-invariant' except will still include the
 * message in production.
 *
 * @example
 * invariant(typeof value === 'string', `value must be a string`)
 *
 * @param condition The condition to check
 * @param message The message to throw (or a callback to generate the message)
 *
 * @throws {InvariantError} if condition is falsey
 */
export function invariant(condition: unknown, message: string | (() => string)): asserts condition {
  if (!condition) {
    throw new InvariantError(typeof message === 'function' ? message() : message);
  }
}

/**
 * Checks if the type of error is InvariantError. It allows you to cast the error type properly
 * so you can handle it accordingly
 *
 * @example
 * isInvariantError(error)
 *
 * @param error The condition to check
 *
 * @return {boolean} true = the instance of the error is invariant, false = the instance of the error is not invariant
 * and it will not cast the error types.
 */
export function isInvariantError(error: unknown): error is InvariantError {
  return error instanceof InvariantError;
}
