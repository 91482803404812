<template>
  <div>
    <b-table
      thead-class="thVariant"
      :current-page="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      variant="investmentTable"
      striped
      stacked="sm"
      id="gys-transactions-table"
      table-variant="transactions"
      responsive
      :fields="this.fields"
      hover
      :items="formattedTable"
    ></b-table>

    <b-pagination
      v-model="currentPage"
      :per-page="perPage"
      :total-rows="rows"
      align="right"
      aria-controls="gys-transactions-table"
    ></b-pagination>
  </div>
</template>

<script>
export default {
  name: 'TransactionsListTable',
  data() {
    return {
      formattedTable: [],
      currentPage: 1,
      fields: [
        {
          key: 'date',
          label: 'Date',
          tdClass: 'date-col'
        },
        {
          key: 'opening_balance',
          label: 'Opening Balance',
          tdClass: 'd-none d-sm-table-cell',
          thClass: 'd-none d-sm-table-cell'
        },
        {
          key: 'dividend_payments',
          label: 'Dividend Payments',
          tdClass: 'd-none d-sm-table-cell',
          thClass: 'd-none d-sm-table-cell'
        },
        {
          key: 'investment_amount',
          label: 'Investment Amount'
        },
        {
          key: 'closing_balance',
          label: 'Closing Balance'
        }
      ]
    }
  },
  props: {
    breakdown: {
      type: Array,
      required: true
    },
    perPage: {
      type: Number,
      default: 5
    }
  },
  created() {
    // ? Date row to be more than the others ? 17%?
    const allowed = ['date', 'opening_balance', 'dividend_payments', 'investment_amount', 'closing_balance']
    this.formattedTable = this?.breakdown.map((item) => {
      return this.$lodash.pick(item, allowed)
    })
  },
  computed: {
    rows() {
      return this?.breakdown.length
    }
  }
}
</script>

<style lang="scss">
.table-transactions th,
.table-transactions td {
  border-bottom: 1px solid $pfida-purple;
}

.thVariant {
  background-color: $pfida-purple !important;
}
.thVariant tr {
  color: white !important;
}
.table thead th {
  border-bottom: 0px;
}
// .date-col {
//   width: 25%;
// }
</style>
