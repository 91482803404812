export function getItem(key: string) {
  return window.localStorage.getItem(key);
}

export function setItem<T>(key: string, value: T) {
  window.localStorage.set(key, JSON.stringify(value));
}

export function removeItem(key: string) {
  window.localStorage.removeItem(key);
}
