<template>
  <div>
    <div
      id="delete-btn"
      class="flex justify-between items-center settings-option"
      @click.prevent="showConfirmDeleteAccount"
    >
      <h4 class="mb-0">Delete Account</h4>
      <feather-icon icon="ChevronRightIcon" class="ml-4" />
    </div>

    <b-modal id="confirm-delete" v-model="modalConfirmDelete" title="Delete Account" lazy centered no-stacking>
      <template #modal-title>
        Delete Account <b-icon icon="exclamation-triangle" variant="warning" font-scale="1.2"></b-icon>
      </template>
      <div>
        If you delete your account, all your data and transaction history will be deleted. This cannot be recovered.
        <br />
        <br />
        <p>Are you sure you wish to proceed?</p>
      </div>

      <template #modal-footer="{ ok }">
        <b-button @click="showDeleteAccountModal(ok)" variant="outline-danger" type="border"
          >Delete my account</b-button
        >
        <b-button @click="ok()" variant="primary" type="border">Cancel</b-button>
      </template>
    </b-modal>

    <b-modal
      id="delete-account"
      lazy
      centered
      title="Delete Account"
      v-model="modalDeleteAccount"
      @close="oncloseDeleteAccountModal"
      @hide="oncloseDeleteAccountModal"
      no-stacking
    >
      <b-form-group
        id="input-group-password"
        :invalid-feedback="passwordFeedback"
        :state="passwordInputState"
        label="Password:"
        label-for="input-password"
      >
        <b-input-group class="mb-2">
          <b-input-group-prepend is-text>
            <b-icon icon="lock"></b-icon>
          </b-input-group-prepend>
          <b-form-input
            id="input-password"
            :state="passwordInputState"
            v-model="deleteAccountPassword"
            type="password"
            required
          ></b-form-input>
        </b-input-group>
      </b-form-group>

      <template #modal-footer="{ ok }">
        <b-button @click="deleteAccount(ok)" :disabled="!validateForm" class="mt-2" variant="danger"
          >Delete My account</b-button
        >
      </template>
    </b-modal>

    <b-modal id="modal-res-message" centered>
      <template #modal-title>
        <b-icon icon="exclamation-triangle" variant="danger" font-scale="1.2"></b-icon>
        Delete account failed
      </template>
      <div class="my-4">{{ modalResponseMessage }}</div>
      <template #modal-footer="{ ok }">
        <b-button size="lg" class="shadow-md lg:mt-0 mb-2" variant="primary" @click="ok()">OK</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { getErrorMessage } from '@repo/utils'
import helpers from '../../../../../helpers'

export default {
  data() {
    return {
      deleteAccountPassword: '',
      modalConfirmDelete: false,
      modalDeleteAccount: false,
      modalResponseMessage: ''
    }
  },
  computed: {
    validateForm() {
      return this.deleteAccountPassword != '' && this.deleteAccountPassword.length > 7
    },
    passwordInputState() {
      if (this.deleteAccountPassword?.length > 7 || this.deleteAccountPassword === '') {
        return null
      }
      return false
    },

    passwordFeedback() {
      if (this.deleteAccountPassword.length > 0) {
        return 'The password must be at least 8 characters'
      }
      return 'Password is required'
    }
  },
  methods: {
    showDeleteAccountModal() {
      this.modalDeleteAccount = true
    },
    showConfirmDeleteAccount() {
      this.modalConfirmDelete = true
    },
    deleteAccount(ok) {
      const payload = {
        password: this.deleteAccountPassword
      }
      this.$store
        .dispatch(`auth/deleteAccount`, payload)
        .then(() => {
          helpers.notifySuccess(this.$vs, 'Account Deleted')
          setTimeout(
            () =>
              this.$store.dispatch('logOut').then(() => {
                this.$eventBus.emit("mife:logout", {
                  success: true
                })
                this.$router.push('/')
              }),
            2000
          )
        })
        .catch((err) => {
          ok()
          this.modalResponseMessage = getErrorMessage(err)
          this.$bvModal.show('modal-res-message')
        })
    },
    oncloseDeleteAccountModal() {
      this.deleteAccountPassword = ''
    }
  }
}
</script>
