import { initEventBus } from '@repo/single-spa';

/**
 * a function that integrates an event bus with Vuex and emits/listens to event
 * @function
 */
function createEventBusPugin() {
  const eventBus = initEventBus();
  return {
    eventBus,
    plugin: (store) => {
      eventBus.on('mife:logout', () => {
        store.dispatch('logOut');
      });
      /*
        The below is an example how you can subscribe to an actions
        and emit an event.
      */
      // store.subscribeAction((params, state) => {
      //   if (params.type === "user/getUser") {
      //     eventBus.emit("mife:login", {
      //       AccessToken: state.auth.accessToken,
      //       IdToken: state.auth.idToken,
      //     })
      //   }
      // })
    },
  };
}

export const { plugin, eventBus } = createEventBusPugin();
