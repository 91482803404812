<template>
  <div>
    <div v-if="!error" id="user-settings-security">
      <b-card no-body>
        <div class="flex justify-between mfa-option">
          <div>
            <h4>Enable MFA (Recommended)</h4>
            <p>Multi-Factor Authentication (MFA) provides additonal security when accessing your account</p>
          </div>
          <b-form-checkbox
            v-model="checked"
            @change="switchMFAToggle"
            name="mfa-switch"
            class="mt-2"
            switch
            size="lg"
          ></b-form-checkbox>
        </div>
        <ChangePasswordVue />
        <DeleteAccountVue />
      </b-card>

      <b-modal
        id="modal-validate-mfa"
        ref="validate-mfa-modal"
        v-model="modalValidateMFA"
        centered
        @close="onValidateModalHidden"
        @hide="onValidateModalHidden"
      >
        <template #modal-title><div class="text-center">Set up Multi-Factor Authentication</div> </template>
        <div class="mt-2">
          <QrcodeVue class="text-center" :value="QRCodeURL" :size="size" level="H" />
          <div class="mb-4">
            <h6 class="mb-0">
              1. Scan the QR code with your authenticator app or enter the secret below in the Authenticator app
            </h6>
            <span
              >Secret code: <code>{{ secretCode }}</code> <b-icon icon="clipboard-check"></b-icon
            ></span>
          </div>
          <div>
            <h6>
              2. Once you have scanned the QR code, complete the setup by entering the code listed in your Authenticator
              App
            </h6>
            <b-form-input
              id="input-code"
              v-model="codeInput"
              @input="sanitiseInput"
              v-on:keyup.enter="validateMFA"
              type="number"
              class="mb-2"
              placeholder="Code"
            ></b-form-input>
            <span v-if="showCodeError" class="text-danger">{{ codeErrorText }}</span>
          </div>
        </div>
        <template #modal-footer="{ ok }">
          <b-button class="shadow-md mt-2" variant="primary" :disabled="!validateCodeInput" @click="validateMFA(ok)"
            >Verify</b-button
          >
        </template>
      </b-modal>
      <b-modal
        id="modal-disable-mfa"
        v-model="modalDisableMFA"
        ref="disable-mfa-modal"
        lazy
        centered
        @close="onDisableModalHidden"
        @hide="onDisableModalHidden"
      >
        <template #modal-title><div class="text-center">Disable Multi-Factor Authentication</div> </template>
        <div class="mt-2">
          <div>
            <h6>To disable MFA please enter your password</h6>
            <b-form-input
              id="input-pwd"
              type="password"
              v-model="password"
              v-on:keyup.enter="disableMFA"
              class="mb-2"
              placeholder="Password"
            ></b-form-input>
            <span v-if="showPwdError" class="text-danger">{{ passwordErrorText }}</span>
          </div>
        </div>
        <template #modal-footer="{ ok }">
          <b-button class="shadow-md mt-2" variant="primary" :disabled="!validatePwdInput" @click="disableMFA(ok)"
            >Disable MFA</b-button
          >
        </template>
      </b-modal>
    </div>
    <ServerError500 v-else />
  </div>
</template>

<script>
import helpers from '../../../../helpers'
import QrcodeVue from 'qrcode.vue'
import ChangePasswordVue from './components/ChangePassword.vue'
import DeleteAccountVue from './components/DeleteAccount.vue'
import ServerError500 from '../../../components/error-pages/ServerError500.vue'

export default {
  components: {
    QrcodeVue,
    ChangePasswordVue,
    DeleteAccountVue,
    ServerError500
  },
  data() {
    return {
      checked: false,
      QRCodeURL: '',
      secretCode: '',
      size: 150,
      codeInput: '',
      password: '',
      successfullyDisabledMFA: false,
      successfullyEnabledMFA: false,
      codeErrorText: '',
      passwordErrorText: '',
      showPwdError: false,
      showCodeError: false,
      modalValidateMFA: false,
      modalDisableMFA: false,
      error: false
    }
  },
  computed: {
    validateCodeInput() {
      return this.codeInput != '' && this.codeInput.length > 3
    },
    validatePwdInput() {
      return this.password != '' && this.password.length > 7
    },
    investmentDetails() {
      return this.$store.state.investmentDetails
    },
    financeDetails() {
      return this.$store.state.financeDetails
    }
  },
  methods: {
    switchMFAToggleBlockBtn() {
      this.switchMFAToggle(!this.checked)
      if (this.checked === false) {
        this.checked = true
      }
    },
    switchMFAToggle(val) {
      if (!val) {
        this.modalDisableMFA = true
      } else {
        this.$vs.loading()
        const email = this.$store.state.user.details.email
        const payload = {
          enable: 'True'
        }
        this.$store
          .dispatch('user/toggleMFA', payload)
          .then((res) => {
            this.$vs.loading.close()
            this.modalValidateMFA = true
            this.secretCode = res.data.data.secret_code
            this.QRCodeURL = `otpauth://totp/Pfida:${encodeURIComponent(email)}?secret=${
              res.data.data.secret_code
            }&issuer=Pfida`
          })
          .catch(() => {
            this.$vs.loading.close()
          })
      }
    },
    copyCode() {
      this.$bvToast.hide('copy-success')
      /* Copy the text inside the text field */
      navigator.clipboard.writeText(this.secretCode)
      setTimeout(() => {
        this.toast('Copied!')
      }, 400)
    },
    sanitiseInput() {
      this.showPwdError = false
      this.showCodeError = false
      this.showCodeError = false

      this.codeErrorText = ''
      this.passwordErrorText = ''
    },
    validateMFA() {
      const payload = {
        user_code: this.codeInput
      }
      if (this.validateCodeInput) {
        this.$vs.loading()
        this.$store
          .dispatch('user/verifyMFA', payload)
          .then(() => {
            this.successfullyEnabledMFA = true
            this.$vs.loading.close()
            this.toast('MFA Successfully Enabled!')
            this.$refs['validate-mfa-modal'].hide()
          })
          .catch((err) => {
            this.$vs.loading.close()
            this.showCodeError = true
            this.codeErrorText = err?.response?.data?.response?.messages[0] || 'Incorrect Code'
          })
      }
    },
    async disableMFA() {
      const clientSecret = await helpers.generateUserSecret(this.$store.state.auth.username)

      const payload = {
        client: 'web',
        client_secret: clientSecret,
        enable: false,
        password: this.password
      }
      if (this.validatePwdInput) {
        this.$vs.loading()
        this.$store
          .dispatch('user/toggleMFA', payload)
          .then(() => {
            this.$vs.loading.close()
            this.successfullyDisabledMFA = true
            this.$refs['disable-mfa-modal'].hide()
            this.toast('MFA Disabled')
          })
          .catch((err) => {
            this.$vs.loading.close()
            this.showPwdError = true
            this.passwordErrorText = err?.response?.data?.response?.messages[0] || 'Incorrect Password'
          })
      }
    },
    toast(text) {
      this.$bvToast.toast(text, {
        toaster: 'b-toaster-bottom-center',
        noCloseButton: true,
        solid: false,
        id: 'copy-success',
        toastClass: 'rounded-full',
        bodyClass: 'text-center font-bold',
        appendToast: false
      })
    },
    onDisableModalHidden() {
      this.password = ''
      this.showPwdError = false
      if (!this.successfullyDisabledMFA) {
        this.checked = true
      }
    },
    onValidateModalHidden() {
      this.codeInput = ''
      this.showCodeError = false
      if (!this.successfullyEnabledMFA) {
        this.checked = false
      }
    }
  },
  created() {
    this.$vs.loading()
    this.$store
      .dispatch('user/getMFAStatus')
      .then((res) => {
        this.checked = res.data.data.mfa_enabled
        this.$vs.loading.close()
      })
      .catch(() => {
        this.error = true
        this.$vs.loading.close()
      })
  }
}
</script>
<style scoped>
.mfa-option {
  padding: 20px 30px;
}
</style>
