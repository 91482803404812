import { isTenant } from '@repo/utils';

export default {
  showInvestorDeclaration: (state) => {
    return isTenant('pfida')
      ? state?.investorDeclaration === 'link_to' || state?.investorDeclaration === 'block'
      : false;
  },
  //?check why this is not working
  bankDetailsExist: (state) => {
    return !!(state.bankDetails.sort_code && state.bankDetails.account_number);
  },
};
