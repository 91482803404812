<template>
  <div class="vx-row" id="lifetime-chart">
    <div class="content-container text-xl">
      Your total lifetime payments estimate is {{ financeDetails.latest_projected_payments.estimated_total_payments }}
    </div>
    <div class="content-container" style="font-weight: normal">Excludes initial equity amount</div>

    <apexchart
      type="bar"
      height="300"
      class="chart-container"
      :options="barOptions"
      :series="chartData.lifeTimeProfile"
    />
  </div>
</template>

<script>
import helpers from '../../../../helpers'

export default {
  name: 'LifetimeChart',
  computed: {
    chartData() {
      const index = this.$route.params.index - 1
      return this.$store.state.chartsData[index]
    },
    financeDetails() {
      const index = this.$route.params.index - 1
      return this.$store.state.financeDetails[index]
    },
    barOptions: {
      get: function (props) {
        const colors = props.$constants.colors
        return {
          chart: {
            type: 'bar',
            height: 350,
            stacked: true,
            toolbar: {
              show: false
            }
          },
          plotOptions: {
            bar: {
              horizontal: false,
              borderRadius: 4
            }
          },
          dataLabels: {
            enabled: true,
            textAnchor: 'middle',
            style: {
              colors: ['#fff'],
              fontSize: '16px',
              fontFamily: 'Figtree Variable',

              fontWeight: 'bold'
            },
            formatter: function (val) {
              return helpers.formatCurrency(val)
            }
          },
          xaxis: {
            categories: ['Original Forecast', 'Latest Forecast'],
            labels: {
              style: {
                fontSize: '16px',
                fontFamily: 'Figtree Variable'
              },
              formatter: function (val) {
                return val //to integer percentage
              }
            }
          },
          colors: [colors.pfidaPurple, colors.pfidaDustBlue, colors.pfidaSalmon],
          grid: {
            show: false
          },
          yaxis: {
            show: false,
            title: {
              text: undefined
            }
          },
          tooltip: {
            x: {
              show: false
            },
            y: {
              formatter: function (val) {
                return helpers.formatCurrency(val)
              }
            },
            style: {
              fontSize: '16px',
              fontFamily: 'Figtree Variable'
            }
          },
          fill: {
            opacity: 1
          },
          legend: {
            position: 'bottom',
            horizontalAlign: 'center',
            offsetX: 0,
            fontSize: '16px',
            fontFamily: 'Figtree Variable'
          }
        }
      }
    }
  }
}
</script>

<style scoped>
.content-container {
  font-size: 16px;
  display: flex;
  font-weight: bold;
  justify-items: center;
  justify-content: center;
  margin-bottom: 10px;
  text-align: center;
  width: 100%;
}

.chart-container {
  min-height: 315px;
  width: 50%;
  text-align: center;
  margin: auto;
}

@media only screen and (max-width: 800px) {
  .chart-container {
    width: 100%;
  }
}
</style>
