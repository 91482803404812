import z from 'zod';
import { fromZodError } from 'zod-validation-error';

import { isHttpError } from './http';
import { isInvariantError } from './invariant';

const BackendErrorSchema = z.object({
  response: z.object({
    data: z.object({
      response: z.object({
        messages: z.string().array().nonempty(),
      }),
    }),
  }),
});

const ErrorObjectSchema = z.object({
  response: z.object({
    data: z.object({
      error: z.string(),
      status: z.number(),
    }),
  }),
});

const ErrorMessageSchema = z.object({
  response: z.object({
    data: z.object({
      message: z.string(),
    }),
  }),
});

export function getErrorMessage(error: unknown): string {
  if (error instanceof z.ZodError) {
    return fromZodError(error).message;
  }

  const backendError = BackendErrorSchema.safeParse(error);
  if (backendError.success) {
    return backendError.data.response.data.response.messages[0];
  }

  const azureSSOError = ErrorObjectSchema.safeParse(error);
  if (azureSSOError.success) {
    return azureSSOError.data.response.data.error;
  }

  const errorMessage = ErrorMessageSchema.safeParse(error);
  if (errorMessage.success) {
    return errorMessage.data.response.data.message;
  }

  if (isInvariantError(error)) {
    return error.message;
  }

  if (isHttpError(error)) {
    return error.message;
  }

  if (error instanceof Error) {
    return error.message;
  }

  console.error('Unable to get error message for error', error);
  return 'Unknown Error';
}
