<!-- =========================================================================================
  File Name: AffordabilityCalculator.vue
  Description: Affordability Calculator
========================================================================================== -->
<template>
  <div>
    <div class="vx-row" id="affordability-calculator">
      <div class="vx-col w-full md:w-1/2 mb-base">
        <vx-card>
          <b-form id="AffordabilityForm" @submit.prevent="calculate">
            <b-form-group id="input-group-income" :invalid-feedback="incomeFeedback" :state="incomeInputState">
              <label id="label"
                >Gross annual income:
                <b-icon icon="info-circle" v-b-popover.hover.top="{ variant: 'secondary', content: grossAnnualIncome, container:'affordability-calculator' }"
              /></label>
              <b-input-group size="lg">
                <b-input-group-prepend is-text> £ </b-input-group-prepend>
                <CurrencyInput
                  name="gross_income"
                  v-model="gross_annual_income"
                  :options="vCurrencyOptions"
                  :fullLength="false"
                />
              </b-input-group>
            </b-form-group>

            <b-form-group
              id="input-group-deposit"
              :invalid-feedback="depositFeedback"
              :state="depositInputState"
              label="Deposit:"
            >
              <b-input-group size="lg">
                <b-input-group-prepend is-text> £ </b-input-group-prepend>
                <CurrencyInput name="deposit" v-model="deposit" :options="vCurrencyOptions" :fullLength="false" />
              </b-input-group>
            </b-form-group>

            <div class="vx-col mt-base">
              <b-button
                class="shadow-md hover:shadow-lg lg:mt-0 mb-2 mt-2 mr-4 p-2"
                variant="primary"
                size="lg"
                :disabled="!validateForm"
                type="submit"
                >Calculate</b-button
              >
              <b-button
                variant="outline-warning"
                size="lg"
                @click="resetForm"
                class="shadow-sm lg:mt-0 mb-2 mt-2 mr-4 p-2"
                >Reset</b-button
              >
            </div>
          </b-form>
        </vx-card>
      </div>

      <div class="vx-col w-full md:w-1/2 mb-base">
        <AffordabilityCalculatorEstimate
          :estimate_deposit_amount="estimate_deposit_amount"
          :estimate_finance_amount="estimate_finance_amount"
          :estimate_property_budget="estimate_property_budget"
          :annual_income="this_annual_income"
          :isCalculated="isCalculated"
        />
      </div>
    </div>
  </div>
</template>

<script>
import helpers from '../../../helpers'
import AffordabilityCalculatorEstimate from '../../components/primary/calculators/AffordabilityCalculatorEstimate.vue'
import CurrencyInput from '../../components/primary/currency-input/CurrencyInput.vue'
import { getErrorMessage } from '@repo/utils'

function createComputed(readVariable, defaultVal = null) {
  return {
    get() {
      return this.$store.state.calculators.affordabilityCalculator[readVariable] || defaultVal
    },
    set(val) {
      this.$store.dispatch('calculators/updateAffordabilityCalculatorData', {
        [readVariable]: val
      })
    }
  }
}

export default {
  name: 'AffordabilityCalculator',
  components: {
    CurrencyInput,
    AffordabilityCalculatorEstimate
  },
  data() {
    return {
      isCalculated: false,
      estimate_property_budget: 0,
      estimate_deposit_amount: 0,
      estimate_finance_amount: 0,
      this_annual_income: 0,
      grossAnnualIncome: 'This is the combined annual income of all applicants before tax',
      vCurrencyOptions: {
        ...this.$constants.vCurrencyOptions
      }
    }
  },
  computed: {
    gross_annual_income: createComputed('gross_annual_income'),
    deposit: createComputed('deposit'),
    isLoggedIn() {
      return this.$store.state.auth.isUserLoggedIn()
    },
    incomeInputState() {
      if (!this.gross_annual_income) {
        return null
      }
      return this.gross_annual_income >= 1000
    },
    incomeFeedback() {
      if (this.gross_annual_income > 0) {
        return 'Gross annual income should be at least £1,000'
      }
      return 'This field is required'
    },
    depositInputState() {
      if (!this.deposit) {
        return null
      }
      return this.deposit >= 10000
    },
    depositFeedback() {
      if (this.deposit > 0) {
        return 'Deposit should be at least £10,000'
      }
      return 'This field is required'
    },
    validateForm() {
      return this.incomeInputState && this.depositInputState
    }
  },
  methods: {
    resetForm() {
      this.gross_annual_income = this.deposit = ''
      this.estimate_property_budget = this.estimate_deposit_amount = this.estimate_finance_amount = 0
    },
    calculate() {
      if (!this.validateForm) return
      const payload = {
        annual_income: parseInt(this.gross_annual_income),
        deposit: parseInt(this.deposit)
      }
      this.$vs.loading()
      const url = this.$store.state.auth.isUserLoggedIn() ? 'calculateAffordabilityLoggedIn' : 'calculateAffordability'
      this.$store
        .dispatch(`calculators/${url}`, payload)
        .then((response) => {
          this.$vs.loading.close()
          if (this.isLoggedIn) {
            this.$store.dispatch('calculators/getCalculatorHistory', { force: true })
          }
          this.isCalculated = true
          this.estimate_deposit_amount = parseInt(this.deposit)
          this.estimate_finance_amount = response.finance_amount
          this.estimate_property_budget = response.finance_amount + parseInt(this.deposit)
          this.this_annual_income = response.annual_income
        })
        .catch((error) => {
          this.$vs.loading.close()
          helpers.notifyError(this.$vs, getErrorMessage(error))
        })
    }
  },
  created() {
    if (!this.$store.state.calculators.affordabilityCalculator?.deposit && this.isLoggedIn) {
      this.$store
        .dispatch('calculators/getCalculatorHistory')
        .then((response) => {
          const latest = response.find((e) => e.type === 'affordability_calculator')

          this.$store.dispatch('calculators/updateAffordabilityCalculatorData', {
            deposit: latest.deposit,
            gross_annual_income: latest.annual_income
          })
        })
        .catch(() => {})
    }
  }
}
</script>

<style lang="scss">
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
</style>
