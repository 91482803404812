<template>
  <div class="vx-row" id="analytics-summary">
    <!-- Next Payment Card -->
    <div class="flex justify-center w-full">
      <b-card class="sm:w-full md:w-full">
        <div
          class="content-container font-normal"
          id="next-payment-txt"
          style="margin-top: 40px; z-index: 99"
          v-html="nextPaymentText"
        ></div>
        <div class="flex justify-center vx-col w-full">
          <apexchart
            type="bar"
            height="300"
            :options="barOptionsNextPayment"
            :series="chartData.nextPaymentBreakdown"
          />
        </div>
        <div class="content-container font-normal text-base mt-4">
          If you cannot afford your next payment, please contact us as soon as possible so we can see how best to help
          you
        </div>
      </b-card>
    </div>

    <!-- Upcoming payments Card -->
    <div class="flex justify-center w-full">
      <b-card class="sm:w-full md:w-full text-center">
        <div class="content-container" style="margin-top: 40px; z-index: 99">
          Your upcoming payment projections are:
        </div>

        <div class="flex justify-center vx-col w-full">
          <apexchart
            type="bar"
            height="300"
            class="chart-container"
            :options="barOptionsUpcomingPayments"
            :series="chartData.upcomingPayments"
          />
        </div>
        <b-button @click="goToAgreementSettings" id="change-agreement" class="mt-2" variant="outline-primary" size="sm"
          >Make a change <b-icon icon="pencil"
        /></b-button>
      </b-card>
    </div>

    <!-- Aquisition cost Pie -->
    <div class="flex justify-center w-full">
      <b-card class="sm:w-full">
        <div class="content-container" style="margin-top: 20px">
          The Aquisition Cost of your {{ financeDetails.product_type === 'eb' ? 'equity boost' : 'property' }} is
          {{ financeDetails.acquisition_cost }}
        </div>
        <div class="flex justify-center vx-col w-3-4">
          <apexchart
            type="pie"
            height="300"
            class="vx-col w-full mb-4"
            :options="pieOptionsEquity"
            :series="chartData.equityChart"
          />
        </div>
      </b-card>
    </div>

    <!-- Projected tern bar chart -->
    <div class="flex justify-center w-full">
      <b-card class="sm:w-full">
        <div class="content-container" style="margin-top: 40px">
          Your total projected term is {{ financeDetails.total_term_string }}.
        </div>
        <div class="w-full">
          <apexchart
            type="bar"
            height="300"
            width="520"
            class="flex justify-around mb-4"
            :options="barOptionsProjectedYears"
            :series="chartData.projectedYears"
          />
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import helpers from '../../../../helpers'

export default {
  name: 'AnalyticsSummary',
  data: (vm) => {
    return {
      colors: vm.$constants.colors,
      index: vm.$route.params.index - 1
    }
  },
  methods: {
    goToAgreementSettings() {
      this.$emit('updateIndex')
    }
  },
  computed: {
    chartData() {
      return this.$store.state.chartsData[this.index]
    },
    financeDetails() {
      return this.$store.state.financeDetails[this.index]
    },
    nextPaymentText() {
      if (this.financeDetails.total_payments_made_ex_rent_arrears_unformatted > 0) {
        return `<div>Your next total payment amount is <b>${this.financeDetails.next_total_monthly_payment_ex_rent_arrears_paid}</b> <div> You have <b>already paid</b> ${this.financeDetails.total_payments_made_ex_rent_arrears} with ${this.financeDetails.next_payment_remaining} remaining to pay</div></div>`
      } else {
        return `<span>Your next payment amount is <b>${this.financeDetails.next_total_monthly_payment_ex_rent_arrears_paid}</b></span>`
      }
    },
    barOptionsUpcomingPayments() {
      return {
        chart: {
          type: 'bar',
          height: 350,
          stacked: true,
          toolbar: {
            show: false
          }
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: false,
            columnWidth: '90%'
          }
        },
        dataLabels: {
          enabled: true,
          textAnchor: 'middle',
          style: {
            colors: [this.colors.pfidaPurple, this.colors.pfidaDustBlue],
            fontSize: '14px',
            fontFamily: 'Figtree Variable',
            fontWeight: 'bold'
          },
          background: {
            enabled: true,
            padding: 2,
            borderRadius: 2,
            borderWidth: 1,
            opacity: 0.9
          },
          formatter: function (val) {
            return helpers.formatCurrency(val)
          }
        },
        colors: [this.colors.pfidaPurple, this.colors.pfidaDustBlue],
        stroke: {
          width: 1,
          colors: ['#fff']
        },
        grid: {
          show: false
        },
        xaxis: {
          categories: this.chartData.amountLabels
        },
        yaxis: {
          show: false,
          title: {
            text: undefined
          }
        },
        tooltip: {
          x: {
            show: false
          },
          y: {
            formatter: function (val) {
              return helpers.formatCurrency(val)
            }
          },
          followCursor: true,
          style: {
            fontSize: '16px',
            fontFamily: 'Figtree Variable'
          }
        },
        fill: {
          opacity: 1
        },
        legend: {
          position: 'bottom',
          horizontalAlign: 'center',
          fontSize: '16px',
          fontFamily: 'Figtree Variable'
        }
      }
    },
    barOptionsNextPayment() {
      const colors = this.financeDetails.rent_arrears_remaining_rounded
        ? [this.colors.pfidaPurple, this.colors.danger, this.colors.pfidaDustBlue]
        : [this.colors.pfidaPurple, this.colors.pfidaDustBlue]
      return {
        chart: {
          type: 'bar',
          height: 350,
          stacked: true,
          toolbar: {
            show: false
          }
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: false
          }
        },
        dataLabels: {
          enabled: true,
          textAnchor: 'middle',
          style: {
            colors: colors,
            fontSize: '16px',
            fontFamily: 'Figtree Variable',
            fontWeight: 'bold'
          },
          background: {
            enabled: true,
            padding: 5,
            borderRadius: 2,
            borderWidth: 1,
            opacity: 0.9
          },
          formatter: function (val, opt) {
            let value = opt.w.globals.series[opt.seriesIndex]
            return helpers.formatCurrency(value)
          }
        },
        colors: colors,
        stroke: {
          width: 1,
          colors: ['#fff']
        },
        grid: {
          show: false
        },
        xaxis: {
          categories: [this.financeDetails.next_payment_date]
        },
        yaxis: {
          show: false,
          title: {
            text: undefined
          }
        },
        tooltip: {
          x: {
            show: false
          },
          y: {
            formatter: function (val) {
              return helpers.formatCurrency(val)
            }
          },
          followCursor: true,
          style: {
            fontSize: '16px',
            fontFamily: 'Figtree Variable'
          }
        },
        fill: {
          opacity: 1
        },
        legend: {
          position: 'bottom',
          horizontalAlign: 'center',
          fontSize: '16px',
          fontFamily: 'Figtree Variable'
        }
      }
    },
    barOptionsProjectedYears() {
      const projected_years_label = ['Projected term']
      if (this.chartData.isShowingLease) projected_years_label.push('Lease term')
      return {
        labels: ['Years Remaining', 'Years Elapsed'],
        dataLabels: {
          enabled: true,
          textAnchor: 'start',
          style: {
            colors: [this.colors.pfidaPurple, this.colors.pfidaDustBlue],
            fontSize: '16px',
            fontFamily: 'Figtree Variable',
            fontWeight: 'bold'
          },
          background: {
            enabled: true,
            padding: 5,
            borderRadius: 2,
            borderWidth: 1,
            opacity: 0.9
          },
          formatter: function (val) {
            return val
          }
        },
        legend: {
          position: 'bottom',
          horizontalAlign: 'center',
          fontSize: '16px',
          fontFamily: 'Figtree Variable'
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true
          }
        },
        xaxis: {
          categories: projected_years_label,
          labels: {
            formatter: function () {
              return ''
            }
          }
        },
        yaxis: {
          show: true,
          labels: {
            formatter: function (val) {
              return val
            },
            style: {
              fontSize: '16px',
              fontFamily: 'Figtree Variable',
              fontWeight: 400
            }
          }
        },
        grid: {
          show: false
        },
        chart: {
          type: 'bar',
          stacked: true,
          dropShadow: {
            enabled: false,
            blur: 5,
            left: 1,
            top: 1,
            opacity: 0.2
          },
          toolbar: {
            show: false
          }
        },
        tooltip: {
          x: {
            show: false
          },
          y: {
            formatter: function (val) {
              return val
            }
          },
          followCursor: true,
          style: {
            fontSize: '16px',
            fontFamily: 'Figtree Variable'
          }
        },
        responsive: [
          {
            breakpoint: 700,
            options: {
              chart: {
                width: 350
              }
            }
          }
        ],
        colors: [this.colors.pfidaPurple, this.colors.pfidaDustBlue]
      }
    },
    pieOptionsEquity() {
      let dataLabelText = ['Oustanding Equity', 'Equity purchased', 'Initial Equity']
      let dataLabelColors = [this.colors.pfidaDustBlue, this.colors.pfidaPurple, this.colors.pfidaSalmon]
      if (Math.abs(this.financeDetails.equity_released)) {
        dataLabelColors = [
          this.colors.pfidaDustBlue,
          this.colors.pfGray,
          this.colors.pfidaPurple,
          this.colors.pfidaSalmon
        ]
        dataLabelText = ['Oustanding Equity', 'Equity Released', 'Equity purchased', 'Initial Equity']
      }
      return {
        labels: dataLabelText,
        dataLabels: {
          enabled: true,
          textAnchor: 'end',
          style: {
            colors: dataLabelColors,
            fontSize: '18px',
            fontFamily: 'Figtree Variable',
            fontWeight: 'bold'
          },
          background: {
            enabled: true,
            padding: 5,
            borderRadius: 2,
            borderWidth: 1,
            opacity: 0.9
          },
          formatter: function (val, opt) {
            let seriesName = opt.w.globals.seriesNames[opt.seriesIndex]
            let value = opt.w.globals.series[opt.seriesIndex]
            if (seriesName === 'Equity Released') {
              value = -Math.abs(value)
            }
            return helpers.formatCurrency(value)
          }
        },
        legend: {
          position: 'bottom',
          horizontalAlign: 'center',
          fontSize: '16px',
          fontFamily: 'Figtree Variable'
        },
        chart: {
          type: 'pie',
          offsetY: 0,
          dropShadow: {
            enabled: false,
            blur: 5,
            left: 1,
            top: 1,
            opacity: 0.2
          },
          toolbar: {
            show: false
          }
        },
        stroke: {
          width: 2
        },
        colors: dataLabelColors,
        tooltip: {
          y: {
            formatter: function (val, opt) {
              let seriesName = opt.globals.seriesNames[opt.dataPointIndex]
              let value = val
              if (seriesName === 'Equity Released') {
                value = -Math.abs(value)
              }
              return helpers.formatCurrency(value)
            }
          },
          style: {
            fontSize: '16px',
            fontFamily: 'Figtree Variable'
          }
        }
      }
    }
  }
}
</script>

<style scoped>
.content-container {
  display: flex;
  font-weight: bold;
  justify-items: center;
  justify-content: center;
  margin-bottom: 10px;
  text-align: center;
  width: 100%;
}

.chart-container {
  min-height: 315px;
  width: 50%;
  text-align: center;
  margin: auto;
}

@media only screen and (max-width: 800px) {
  .chart-container {
    width: 100%;
  }
}
</style>
