/*=========================================================================================
  File Name: moduleAuthState.js
  Description: Auth Module State
==========================================================================================*/
import auth from '@/auth/authService';

import store from '../store';

const authInitialState = {
  isUserLoggedIn: () => {
    let isAuthenticated = auth.isAuthenticated();
    return store.getters.userData && isAuthenticated;
  },
  idToken: '',
  accessToken: '',
  authEmail: '',
  password: '',
  tokenExpiry: '',
  username: '',
  authSession: '',
  challengeName: '',
  marketing: '',
};
export default authInitialState;
