/*=========================================================================================
  File Name: moduleCalendarState.js
  Description: Calendar Module State
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
const userInitalState = {
  RetirementAge: '',
  MFA: '',
  UnreadDocuments: 0,
  Documents: [],
  details: {
    address: null,
    city: null,
    postcode: null,
  },
  zakatDate: '',
  investorDeclaration: '',
  referrals: {
    data: {},
    settings: { options: [] },
  },
  // to manage loading/status of APIs
  status: {
    Documents: {
      loading: false,
      error: false,
    },
    referrals: {
      loading: false,
      error: false,
    },
    referralSettings: {
      loading: false,
      error: false,
    },
  },
};
export default userInitalState;
