export default function createComputed(readVariable, defaultVal = null) {
  return {
    get() {
      return this.$store.state.calculators.AIP[readVariable] || defaultVal;
    },
    set(val) {
      this.touched[readVariable] = true;
      this.$store.dispatch('calculators/updateAIPData', {
        [readVariable]: val,
      });
    },
  };
}
