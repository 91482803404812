<template>
  <div>
    <b-card class="sm:w-full md:w-full shadow">
      <div class="form-container">
        <div class="flex flex-column items-center justify-center vx-col"></div>
      </div>
      <FormWizard
        color="rgba(var(--vs-primary), 1)"
        errorColor="rgba(var(--vs-danger), 1)"
        :title="null"
        :subtitle="null"
        finishButtonText="Submit"
      >
        <!-- tab 1 content -->
        <TabContent class="mb-5" title="Select" icon="feather icon-users" :before-change="validateStep1">
          <div class="tab-container">
            <b-form data-vv-scope="step-1">
              <b-form-group
                class="mt-4 mb-8"
                label-class="font-weight-bold mb-4"
                id="input-investorType-group"
                :invalid-feedback="investorTypeFeedback"
                :state="investorTypeInputState"
                label="To be eligible to receive information regarding our investment products please tell us which type of investor you are. Selecting each option will provide a description below:"
              >
                <b-form-radio-group
                  size="lg"
                  id="investorType-radios"
                  v-model="investorType"
                  stacked
                  :options="options.investorType"
                  name="investorType"
                ></b-form-radio-group>

                <div id="investor-type-info" class="investor-dec">
                  <div v-if="investorType === 'High net worth investor'">
                    <div class="my-10">
                      <h4>Description</h4>

                      <p>
                        You are a high net worth investor if you earn more than £100,000 a year or have net assets worth
                        more than £250,000 other than your home and pension.
                      </p>
                    </div>
                  </div>
                  <div v-if="investorType === 'ifa_certified_investor'">
                    <div class="my-10">
                      <h4>Description</h4>

                      <p>
                        You are an <b>IFA-certified</b> investor if a <b>regulated</b> financial advisor has confirmed
                        that you understand the risks relating to this type of investment.
                      </p>
                    </div>
                  </div>
                  <div v-if="investorType === 'Self-certified sophisticated investor'">
                    <div class="my-10">
                      <h4>Description</h4>
                      <p>You are likely to come under this category if you have invested with us before.</p>
                      <p>You are a self-certified sophisticated investor if any of the following apply:</p>
                      <ul>
                        <li>
                          I have made more than one investment in an unlisted company in the two years prior to the date
                          below (for example if you have two share certificates with us through our Grow Your Savings
                          investment opportunity);
                        </li>

                        <li>
                          I am a member of a network or syndicate of business angels and have been so for at least the
                          last six months prior to today;
                        </li>

                        <li>
                          I am working, or have worked in the two years prior to today, in a professional capacity in
                          the private equity sector, or in the provision of finance for small and medium enterprises;
                        </li>

                        <li>
                          I am currently, or have been in the two years prior to today, a director of a company with an
                          annual turnover of at least £1 million
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div v-if="investorType === 'None of the above'">
                    <NoneOfTheAbove />
                  </div>
                </div>
              </b-form-group>
            </b-form>
          </div>
        </TabContent>

        <!-- tab 2 content -->
        <TabContent
          class="mb-5"
          icon="feather icon-calendar"
          title="Confirm"
          :before-change="submitInvestorDeclaration"
        >
          <div class="tab-container">
            <b-form>
              <div id="investor-type-info" class="investor-dec">
                <div v-if="investorType === 'High net worth investor'">
                  <HighNetWorth />
                </div>
                <div v-if="investorType === 'ifa_certified_investor'">
                  <IFACertifiedInvestor />
                </div>
                <div v-if="investorType === 'Self-certified sophisticated investor'">
                  <SelfCertified />
                </div>
                <div v-if="investorType === 'None of the above'">
                  By submitting this form, you understand that you will not yet be eligible to apply for our investment
                  opportunities without first speaking to an Independent Financial Advisor.
                </div>
              </div>

              <b-form-group
                v-if="investorType != 'None of the above'"
                label-class="font-weight-bold mb-4"
                id="input-confirm-group"
                :invalid-feedback="confirmFeedback"
                :state="confirmInputState"
              >
                <b-form-checkbox id="declaration-checkbox" v-model="confirm" name="checkbox"
                  >{{ declarationText }}
                </b-form-checkbox>
              </b-form-group>
            </b-form>
          </div>
        </TabContent>
      </FormWizard>
    </b-card>

    <b-modal
      id="investor-type-modal"
      centered
      title="Investor Type"
      v-model="popupInvestorDec"
      @close="goToHome"
      @hidden="goToHome"
    >
      <p class="text-lg">You have already completed your investor declaration. Thank you!</p>
      <p class="text-lg">You do not need to do anything further.</p>

      <template #modal-footer>
        <b-button
          type="submit"
          form="getHistory"
          size="lg"
          class="shadow-md lg:mt-0 mb-2"
          variant="primary"
          @click="goToHome()"
          >OK
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import IFACertifiedInvestor from './components/IFA-certifiedInvestor.vue'
import HighNetWorth from './components/HighNetWorth.vue'
import SelfCertified from './components/SelfCertified.vue'
import NoneOfTheAbove from './components/NoneOfTheAbove.vue'
import helpers from '../../../helpers'
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { getErrorMessage } from '@repo/utils'

export default {
  components: {
    IFACertifiedInvestor,
    HighNetWorth,
    SelfCertified,
    NoneOfTheAbove,
    FormWizard,
    TabContent
  },
  data() {
    return {
      investorType: 'Self-certified sophisticated investor',
      confirm: null,
      options: {
        investorType: [
          { text: 'High net worth investor', value: 'High net worth investor' },
          { text: 'IFA-certified investor', value: 'ifa_certified_investor' },
          { text: 'Self-certified sophisticated investor', value: 'Self-certified sophisticated investor' },
          { text: 'None of the above', value: 'None of the above' }
        ]
      },
      popupInvestorDec: false
    }
  },
  computed: {
    showNextSection() {
      return this.investorType != 'None of the above' && this.investorType != ''
    },
    investorTypeFeedback() {
      return 'This field is required'
    },
    investorTypeInputState() {
      if (this.investorType === null) {
        return null
      }

      if (!this.investorType) {
        return false
      }
      return this.investorType?.length >= 1
    },
    confirmFeedback() {
      return 'This field is required'
    },
    confirmInputState() {
      if (this.confirm === null) {
        return null
      }
      if (!this.confirm) {
        return false
      }

      return true
    },
    validateForm() {
      if (this.investorType === 'None of the above') {
        return true
      }
      return this.investorTypeInputState && this.confirmInputState
    },
    declarationText() {
      if (this.investorType === 'Self-certified sophisticated investor') {
        return 'I declare that this is an accurate description of my investor status. I wish to be treated as a Self-Certified Sophisticated client when investing in a Grow Your Savings account. I am aware that as a consequence of being a Self-Certified Sophisticated client I may not be eligible to benefit or be compensated from the Financial Services Compensation Scheme.'
      } else if (this.investorType === 'High net worth investor') {
        return 'I declare that this is an accurate description of my investor status. I wish to be treated as a Certified High Net Worth client when investing in a Grow Your Savings account. I am aware that as a consequence of being a Certified High Net Worth client I may not be eligible to benefit or be compensated from the Financial Services Compensation Scheme.'
      }
      return 'I confirm that the above statements apply to me'
    }
  },
  methods: {
    validateStep1() {
      return new Promise((resolve, reject) => {
        if (this.investorTypeInputState) {
          resolve(true)
        } else {
          this.investorType = false
          reject('Please fill in the required fields')
        }
      })
    },
    submitInvestorDeclaration() {
      return new Promise((resolve, reject) => {
        if (this.confirmInputState || this.investorType === 'None of the above') {
          this.$vs.loading()

          this.$store
            .dispatch('user/submitInvestorDeclaration', this.investorType)
            .then((result) => {
              this.$router.push('/dashboard')
              this.$vs.loading.close()
              helpers.notifySuccess(this.$vs, result.data.response.messages[0])
            })
            .catch((err) => {
              this.$vs.loading.close()
              helpers.notifyError(this.$vs, getErrorMessage(err))
            })

          resolve(true)
        } else {
          this.confirm = false
          reject('Please fill in the required fields')
        }
      })
    },
    goToHome() {
      this.$router.push('/dashboard')
    }
  },
  mounted() {
    if (this.$store.state.user.investorDeclaration === 'none') {
      this.popupInvestorDec = true
    }
  }
}
</script>

<style lang="scss" scoped>
.tab-container {
  margin: 0 auto;
  max-width: 800px;
}

h4 {
  margin: 15px 0;
}
p {
  margin: 15px 0;
}
ul {
  list-style-type: disc;
  display: table;
  padding: 0 10px;
  margin: 0 auto;
  margin-left: 10px;
  margin-bottom: 10px;
}
li {
  margin-bottom: 5px;
}
.form-container {
  padding: 0 30px;
  overflow: overflow;
}
</style>
