/*=========================================================================================
  File Name: userModuleActions.js
  Description: update user details
==========================================================================================*/

import { userBackendClient } from '@repo/api/services/userBackend';

import userAPI from '../../http/requests/user/userAPI';
import store from '../store.js';
import {
  SET_BANK_DETAILS,
  SET_DOCUMENTS,
  SET_ERROR,
  SET_INVESTOR_DECLARATION,
  SET_LOADING,
  SET_REFERRAL_SETTINGS_DATA,
  SET_REFERRAL_SPLIT,
  SET_REFERRALS,
  SET_RETIREMENT_AGE,
  SET_UNREAD_DOCS_AMOUNT,
  SET_USER_DETAILS,
  SET_ZAKAT_DATE,
  TOGGLE_MFA,
} from './userMutationTypes.js';

export default {
  /**
   * Get user details from the BE
   *
   * @param commit
   * @param payload
   * @returns {Promise<unknown>}
   */
  getUser({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      userAPI
        .getUser(payload)
        .then((response) => {
          commit(SET_USER_DETAILS, response.data.data);

          userAPI
            .getInvestorDeclaration()
            .then((res) => {
              commit(SET_INVESTOR_DECLARATION, res.data.data.action);

              if (store.state.referral || store.state.equityInvestmentRedirect) {
                userAPI
                  .setReferred({
                    company: store.state.equityInvestmentRedirect || 'pfhpp',
                    referral: store.state.referral || '',
                  })
                  .catch(() => {
                    commit('SET_REFERRAL', '', { root: true });
                  });
              }
              dispatch('getBankDetails');

              resolve(response);
            })
            .catch((err) => reject(err));
        })
        .catch((error) => reject(error));
    });
  },

  /**
   * Update user details in BE
   *
   * @param commit
   * @param payload
   * @returns {Promise<unknown>}
   */
  updateUserDetails({ commit }, payload) {
    return new Promise((resolve, reject) => {
      userAPI
        .updateUser(payload)
        .then((response) => {
          commit(SET_USER_DETAILS, response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /**
   * Get user retirement age fro the BE
   *
   * @param commit
   * @param payload
   * @returns {Promise<unknown>}
   */
  getRetirementAge({ commit }, payload) {
    return new Promise((resolve, reject) => {
      userAPI
        .getRetirementAge(payload)
        .then((response) => {
          commit(SET_RETIREMENT_AGE, response.data.data.retirement_age);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getDocuments({ commit, state }, payload) {
    if (state.Documents.length && !payload?.force) {
      return state.Documents;
    }
    commit(SET_LOADING, { dataName: 'Documents', status: true });
    commit(SET_ERROR, { dataName: 'Documents', status: false });
    return new Promise((resolve, reject) => {
      userAPI
        .getUserDocuments(payload)
        .then((response) => {
          const docList = response.data.data.user_objects_v2;

          const unread = docList.filter((i) => i.unread === true).length;
          commit(SET_UNREAD_DOCS_AMOUNT, unread);
          commit(SET_LOADING, { dataName: 'Documents', status: false });
          commit(SET_DOCUMENTS, docList);
          resolve(response);
        })
        .catch((err) => {
          commit(SET_LOADING, { dataName: 'Documents', status: false });
          commit(SET_ERROR, { dataName: 'Documents', status: true });
          reject(err);
        });
    });
  },

  downloadDocument(_, payload) {
    return new Promise((resolve, reject) => {
      userAPI
        .downloadDocument(payload)
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  toggleDocumentsUnread({ commit }) {
    return new Promise((resolve, reject) => {
      userAPI
        .toggleDocumentsUnread()
        .then((res) => {
          const unread = store.state.user.Documents.some((item) => {
            return item.unread === true;
          });
          let newDocs;
          if (unread) {
            newDocs = store.state.user.Documents.map((i) => {
              return { ...i, unread: false };
            });
          } else {
            newDocs = store.state.user.Documents.map((i) => {
              return { ...i, unread: true };
            });
          }
          commit(SET_DOCUMENTS, newDocs);
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  getZakatDate({ commit }, payload) {
    return new Promise((resolve, reject) => {
      userAPI
        .getZakatDate(payload)
        .then((response) => {
          commit(SET_ZAKAT_DATE, response.data.data.zakat_date);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateZakatDate({ commit }, payload) {
    return new Promise((resolve, reject) => {
      /*  payload could be either
       { notify: false } or
       { value: `${this.hijriDay}/${this.hijriMonth}` } */
      userAPI
        .updateZakatDate(payload)
        .then((response) => {
          if (payload.notify != false) {
            commit(SET_ZAKAT_DATE, payload);
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getReferrals({ commit, state }) {
    if (state.referrals.referral_link) {
      return state.referrals;
    }
    commit(SET_LOADING, { dataName: 'referrals', status: true });

    return new Promise((resolve, reject) => {
      userAPI
        .getReferrals()
        .then((res) => {
          commit(SET_REFERRALS, res.data.data);
          commit(SET_LOADING, { dataName: 'referrals', status: false });
          resolve(res.data.data);
        })
        .catch((err) => {
          commit(SET_LOADING, { dataName: 'referrals', status: false });
          commit(SET_ERROR, { dataName: 'referrals', status: err });
          reject(err);
        });
    });
  },
  setReferralSplit({ commit }, payload) {
    return new Promise((resolve, reject) => {
      userAPI
        .setReferralSplit(payload)
        .then((res) => {
          commit(SET_REFERRAL_SPLIT, payload);
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getReferralSettings({ commit }, payload) {
    commit(SET_LOADING, { dataName: 'referralSettings', status: true });

    return new Promise((resolve, reject) => {
      userAPI
        .getReferralSettings(payload)
        .then((res) => {
          commit(SET_REFERRAL_SETTINGS_DATA, res.data.data);
          commit(SET_LOADING, { dataName: 'referralSettings', status: false });
          resolve(res);
        })
        .catch((err) => {
          commit(SET_LOADING, { dataName: 'referralSettings', status: false });
          commit(SET_ERROR, { dataName: 'referralSettings', status: err });
          reject(err);
        });
    });
  },

  updateRetirementAge({ commit }, payload) {
    return new Promise((resolve, reject) => {
      userAPI
        .updateRetirementAge(payload)
        .then((response) => {
          commit(SET_RETIREMENT_AGE, payload.age);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getMFAStatus({ commit }, payload) {
    return new Promise((resolve, reject) => {
      userAPI
        .getMFAStatus(payload)
        .then((response) => {
          commit(TOGGLE_MFA, response.data.data.mfa_enabled);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  toggleMFA({ commit }, payload) {
    return new Promise((resolve, reject) => {
      userAPI
        .toggleMFA(payload)
        .then((response) => {
          if (payload.enable === false) {
            commit(TOGGLE_MFA, false);
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  verifyMFA({ commit }, payload) {
    return new Promise((resolve, reject) => {
      userBackendClient({
        url: '/verify_mfa_token',
        data: JSON.stringify(payload),
        method: "post"
      })
        .then((response) => {
          commit(TOGGLE_MFA, true);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  submitInvestorDeclaration({ commit }, payload) {
    return new Promise((resolve, reject) => {
      userAPI
        .submitInvestorDeclaration(payload)
        .then((response) => {
          userAPI.getInvestorDeclaration().then((res) => {
            commit(SET_INVESTOR_DECLARATION, res.data.data.action);
          });
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getBankDetails({ commit, state }, payload) {
    if (state.bankDetails?.sort_code && !payload?.force) {
      return state.bankDetails;
    }

    return new Promise((resolve, reject) => {
      userAPI
        .getBankDetails()
        .then((result) => {
          commit(SET_BANK_DETAILS, result.data.data);
          resolve(result.data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  postBankDetails(_, payload) {
    return new Promise((resolve, reject) => {
      userAPI
        .postBankDetails(payload)
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
