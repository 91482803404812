<template>
  <div id="transaction-tab">
    <div v-if="!error">
      <div class="mb-8">
        <TransactionSummaryTable v-if="summary && summary.length > 0" :summary="summary"></TransactionSummaryTable>
      </div>

      <div class="mt-4">
        <TransactionsListTable
          v-if="breakdown && breakdown.length > 0"
          :breakdown="breakdown"
          :perPage="7"
        ></TransactionsListTable>
      </div>

      <b-button v-if="breakdown && breakdown.length > 0" v-b-modal.modal-center variant="primary" class="shadow-md p-3"
        >Download statement<b-icon icon="download" class="ml-2" aria-hidden="true"></b-icon
      ></b-button>

      <div id="no-transactions" v-if="loaded && summary.length === 0">You do not have any current transactions</div>

      <b-modal id="modal-center" centered title="Download statement">
        <b-form @submit.stop.prevent="downloadStatement" id="getHistory">
          <b-container class="mb-8">
            <b-row class="justify-center">
              <b-col col lg="5">
                <label for="start-date">Start date</label>
                <b-form-datepicker
                  v-model="form.startDate"
                  :min="agreementStartDate"
                  :max="todayDate"
                  size="md"
                  dateFormat="DD/MM/YYYY"
                  :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                  locale="gb"
                ></b-form-datepicker>
              </b-col>
              <b-col col lg="5">
                <label for="end-date">End date</label>
                <b-form-datepicker
                  v-model="form.endDate"
                  :min="formattedStartDate"
                  :max="todayDate"
                  :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                  locale="gb"
                ></b-form-datepicker>
              </b-col>
            </b-row>
          </b-container>

          <div class="flex justify-center mb-8">
            <b-form-radio-group
              id="btn-radios-1"
              v-model="form.fileType"
              :options="options"
              button-variant="outline-primary"
              name="radio-btn"
              size="lg"
              buttons
            ></b-form-radio-group>
          </div>
        </b-form>
        <template #modal-footer>
          <b-button
            type="submit"
            form="getHistory"
            size="lg"
            class="shadow-md lg:mt-0 mb-2"
            variant="primary"
            :disabled="!validateForm"
            >Download <b-icon icon="download" class="ml-2" aria-hidden="true"></b-icon
          ></b-button>
        </template>
      </b-modal>
    </div>
    <ServerError500 v-else />
  </div>
</template>

<script>
import TransactionSummaryTable from '../../../components/primary/transaction-history/TransactionSummaryTable.vue'
import TransactionsListTable from '../../../components/primary/transaction-history/TransactionsListTable.vue'
import helpers from '../../../../helpers.js'
import moment from 'moment'
import { saveAs } from 'file-saver'
import ServerError500 from '../../../components/error-pages/ServerError500.vue'
import userAPI from '../../../../http/requests/user/userAPI.js'
import { getErrorMessage } from '@repo/utils'

export default {
  components: {
    TransactionSummaryTable,
    TransactionsListTable,
    ServerError500
  },
  data: () => ({
    loaded: false,
    index: '',
    summary: [],
    breakdown: [],
    form: {
      startDate: '',
      endDate: '',
      fileType: ''
    },
    todayDate: moment(new Date()).format('YYYY-MM-DD'),
    options: [
      { text: 'PDF', value: 'pdf' },
      { text: 'CSV', value: 'csv' }
    ],
    error: false
  }),
  methods: {
    getHistory() {
      this.$store
        .dispatch('getFinanceDetails')
        .then((response) => {
          this.loaded = true

          const currentFinance = response[this.index - 1]
          if (response.length) {
            this.ref = currentFinance.reference
            this.setSummaryDetails(currentFinance)
          }
          this.setBreakdownDetails(currentFinance)
        })
        .catch(() => {
          this.error = true
        })
    },
    setSummaryDetails(finance) {
      this.summary = [
        {
          outstanding_equity: finance.remaining_finance,
          payments_made: finance.total_actually_paid,
          rent_charged: finance.total_rent,
          equity_purchased: finance.total_equity_without_deposit
        }
      ]
    },
    setBreakdownDetails(finance) {
      if (finance?.transaction_history) {
        this.breakdown = finance.transaction_history.map((transaction, index) => {
          return {
            id: index,
            date: transaction.date,
            outstanding_equity: transaction.outstanding_equity_amount,
            payment_made: transaction.actual_payment,
            rent_charged: transaction.rent,
            equity_purchased: transaction.equity,
            type: transaction.type
          }
        })
      }
    },
    downloadStatement() {
      const payload = {
        reference: this.ref,
        lower_date: moment(this.form.startDate).format('DD/MM/YYYY'),
        upper_date: moment(this.form.endDate).format('DD/MM/YYYY'),
        file_type: this.form.fileType
      }
      const MimeType = this.form.fileType === 'pdf' ? 'application/pdf' : 'text/csv'
      this.$vs.loading()
      userAPI
        .downloadTransactionStatement(payload, {
          Accept: MimeType
        })
        .then((res) => {
          this.$vs.loading.close()
          // check for ios chorome
          if (/CriOS/i.test(navigator.userAgent) && /iphone|ipod|ipad/i.test(navigator.userAgent)) {
            let pdfWindow = window.open('')
            pdfWindow.document.write(
              `<iframe width='100%' height='100%' src='data:${MimeType},` + escape(res.data) + "'></iframe>"
            )
          } else {
            const fileName = res.headers['content-disposition'].split('filename=')[1].replace(/"/g, '')
            let file
            if (MimeType === 'application/pdf') {
              file = new File([res.data], ``, { type: 'application/octet-stream' })
            } else {
              const data = Buffer.from(res.data, 'base64')
              file = new File([data], ``, { type: 'application/octet-stream;base64' })
            }

            saveAs(file, fileName)
          }
        })
        .catch((error) => {
          this.$vs.loading.close()
          helpers.notifyError(this.$vs, getErrorMessage(error))
        })
    }
  },
  computed: {
    validateForm() {
      return this.form.startDate != '' && this.form.endDate != '' && this.form.fileType != ''
    },
    formattedStartDate() {
      return moment(this.form.startDate).format('YYYY-MM-DD')
    },
    currentFinance() {
      return this.$store.state.financeDetails[this.index - 1]
    },
    agreementStartDate() {
      const startDate = this.$store.state.financeDetails[this.index - 1].agreement_start_date
      return moment(startDate, 'DD-MM-YYYY').format('YYYY-MM-DD')
    }
  },
  created() {
    this.index = this.$route.params.index
    this.getHistory()
  }
}
</script>
