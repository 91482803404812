<template>
  <div v-if="gotRes" class="password-rules-wrapper">
    <span class="pwd-title">Password must:</span>
    <ul>
      <li :class="{ ['text-danger']: !is8Chars }" v-if="passwordRules.MinimumLength">
        &#8226; Be at least {{ passwordRules.MinimumLength }} characters long
        <ValidationIconsPwd :condition="is8Chars" />
      </li>
      <li :class="{ ['text-danger']: !oneUpperCaseLetter }" v-if="passwordRules.RequireUppercase">
        &#8226; Contain at least one uppercase character
        <ValidationIconsPwd :condition="oneUpperCaseLetter" />
      </li>
      <li :class="{ ['text-danger']: !oneLowerCaseLetter }" v-if="passwordRules.RequireLowercase">
        &#8226; Contain at least one lowercase character
        <ValidationIconsPwd :condition="oneLowerCaseLetter" />
      </li>
      <li :class="{ ['text-danger']: !containsNumber }" v-if="passwordRules.RequireNumbers">
        &#8226; Contain at least one number
        <ValidationIconsPwd :condition="containsNumber" />
      </li>
      <li :class="{ ['text-danger']: !containsSymbol }" v-if="passwordRules.RequireSymbols">
        &#8226; Contain at least one symbol
        <ValidationIconsPwd :condition="containsSymbol" />
      </li>
    </ul>
    {{ passwordMessage }}
  </div>
</template>

<script>
import helpers from '../../helpers';
import ValidationIconsPwd from './ValidationIconsPwd.vue';
import jwt from '../../http/requests/auth/jwt/index.js';
import { getErrorMessage } from '@repo/utils';


export default {
  name: 'PasswordRules',
  components: {
    ValidationIconsPwd
  },
  props: {
    password: {
      type: String
    }
  },
  data() {
    return {
      gotRes: false,
      passwordRules: {
        MinimumLength: null,
        RequireLowercase: null,
        RequireNumbers: null,
        RequireSymbols: null,
        RequireUppercase: null
      },
      passwordMessage: ''
    }
  },
  computed: {
    is8Chars() {
      return this.password.length >= 8
    },
    oneUpperCaseLetter() {
      return this.password.toLowerCase() != this.password
    },
    oneLowerCaseLetter() {
      return this.password.toUpperCase() != this.password
    },
    containsNumber() {
      return /\d/.test(this.password)
    },
    containsSymbol() {
      const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
      return specialChars.test(this.password)
    }
  },
  methods: {
    getPasswordRules() {
      return jwt.getUserPoolDetails().then((res) => {
        this.passwordRules = res.data.data.password_policy
        this.gotRes = true
      }).catch((error) => {
          helpers.notifyError(this.$vs, getErrorMessage(error))
        })
    },
  },
  mounted() {
    const vm = this
    setTimeout(
      function () {
        vm.getPasswordRules()
      },
      300,
      vm
    )
  }
}
</script>

<style scoped>
.pwd-title {
  font-size: 16px;
  font-weight: bold;
}

.password-rules-wrapper {
  background-color: #f3f2f8;
  border: 1px solid #4b3e6d;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 2px;
}

ul {
  font-size: 16px;
}
</style>
