<template>
  <div id="login">
    <div class="vx-card__title mb-4 text-center">
      <h3>Sign up to create an account.</h3>
      <div class="vx-col w-full mt-5">
        <span>Already have an account? </span>
        <router-link :to="{ path: 'login', query: { to: this.$router.currentRoute?.query?.to } }">Login</router-link>
      </div>
    </div>
    <div class="vx-col w-full">
      <b-form id="loginForm" class="flex flex-col items-center w-100" @submit.prevent="signUp">
        <b-form-group id="input-group-name" class="w-full" label-class="font-weight-bold"
          :invalid-feedback="firstNameFeedback" :state="firstNameInputState" label="First Name:">
          <b-form-input size="lg" @keypress="isLetter($event)" name="name" v-model="firstName" />
        </b-form-group>

        <b-form-group id="input-group-lastName" class="w-full" label-class="font-weight-bold"
          :invalid-feedback="lastNameFeedback" :state="lastNameInputState" label="Last Name:">
          <b-form-input size="lg" name="lastName" @keypress="isLetter($event)" v-model="lastName" />
        </b-form-group>
        <b-form-group id="input-group-email" class="w-full" label-class="font-weight-bold"
          :invalid-feedback="emailFeedback" :state="emailInputState" label="Email:" label-for="input-email">
          <b-input-group class="mb-2">
            <b-form-input id="signup-input-email" v-model="email" autocomplete="false" type="email" size="lg"
              required></b-form-input>
          </b-input-group>
        </b-form-group>

        <b-form-group id="input-group-dob" label-class="font-weight-bold" :invalid-feedback="dobFeedback"
          :state="dobInputState" label="Date of Birth:" label-for="input-dob">
          <b-input-group class="mb-3">
            <FreeDateInput :value="dob" ref="dateOfBirth" v-model="dob" />
          </b-input-group>
        </b-form-group>

        <b-form-group id="input-group-mobile" class="w-full" label-class="font-weight-bold"
          :invalid-feedback="mobileFeedback" :state="mobileInputState" label="Mobile Number:">
          <div class="flex">
            <vue-country-code @onSelect="countryCodeSelect" :enabledCountryCode="true" :preferredCountries="['gb']"
              :ignoredCountries="['IL']" />

            <b-form-input size="lg" name="telephone" v-model="telephone" type="number" required></b-form-input>
          </div>
        </b-form-group>

        <b-form-group id="input-group-password" class="w-full" :invalid-feedback="passwordFeedback"
          :state="passwordInputState" label="Password:" label-class="font-weight-bold" label-for="input-password">
          <b-input-group class="mb-2">
            <b-form-input id="input-password" size="lg" v-model="password" type="password" autoComplete="false"
              required></b-form-input>
          </b-input-group>
        </b-form-group>

        <b-form-group id="input-group-confirm-password" class="w-full" :invalid-feedback="confirmPasswordFeedback"
          :state="confirmPasswordInputState" label="Confirm password:" label-for="input-confirm-password"
          label-class="font-weight-bold">
          <b-input-group class="mb-2">
            <b-form-input size="lg" id="input-confirm-password" v-model="confirmPassword" type="password"
              required></b-form-input>
          </b-input-group>
        </b-form-group>
        <PasswordRules :password="password" class="w-full mb-8" />

        <b-form-group id="input-group-hear-about-us" class="w-full"
          v-if="options.hearAboutUsOptions.length && this.$pfidaTenant" :invalid-feedback="hearAboutUsFeedback"
          :state="hearAboutUsInputState" label="Where did you hear about us:" label-for="input-hear-about-us"
          label-class="font-weight-bold">
          <b-input-group class="mb-2">
            <b-form-select v-model="whereDidYouHearAboutUs" :options="options.hearAboutUsOptions"
              @change="hearAboutUsChanged" id="input-hear-about-us" size="lg"></b-form-select>
          </b-input-group>
        </b-form-group>

        <b-form-group id="input-group-events" class="w-full" v-if="whereDidYouHearAboutUs === 'Event'"
          :invalid-feedback="eventsFeedback" :state="eventsInputState" label="Which event did you attend?"
          label-for="input-events" label-class="font-weight-bold">
          <b-input-group class="mb-2">
            <b-form-select v-model="event" :options="options.events" id="input-events" size="lg"></b-form-select>
          </b-input-group>
        </b-form-group>

        <b-form-group id="input-marketing-group" class="w-full" v-if="this.$pfidaTenant">
          <b-form-checkbox id="marketing-checkbox" v-model="marketingCheck" name="checkbox">
            I would like to receive further information relating to Pfida products.
          </b-form-checkbox>
        </b-form-group>

        <b-form-group label-class="font-weight-bold" id="input-TandC-group" class="w-full"
          :invalid-feedback="TandCFeedback" :state="TandCInputState">
          <span>
            <b-form-checkbox id="tnc-checkbox" v-model="TandCCheck" name="checkbox">
              I have read and agree to the
              <a href="https://www.pfida.com/privacy-policy/" target="_blank">data policy <b-icon
                  icon="box-arrow-up-right"></b-icon></a>
            </b-form-checkbox>
          </span>
        </b-form-group>

        <div class="vx-col w-full mt-8">
          <b-button type="submit" :disabled="!validateForm" block class="hover:shadow-lg" variant="primary">Sign
            up</b-button>
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
import helpers from '../../../helpers'
import moment from 'moment'
import FreeDateInput from '../../components/FreeDateInput.vue'
import PasswordRules from '../../components/PasswordRules.vue'
import { getErrorMessage } from '@repo/utils'

export default {
  components: {
    FreeDateInput,
    PasswordRules
  },

  data() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      dob: '',
      telephone: '',
      password: '',
      confirmPassword: '',
      whereDidYouHearAboutUs: '',
      event: '',
      marketingCheck: false,
      TandCCheck: null,
      countryCode: '',
      options: {
        hearAboutUsOptions: [],
        events: []
      }
    }
  },
  computed: {
    firstNameFeedback() {
      if (this.firstName?.length > 0) {
        return 'Please enter a valid Name'
      }
      return 'This field is required'
    },
    firstNameInputState() {
      if (!this.firstName) {
        return null
      }
      return this.firstName?.length >= 1 && /^[a-z ,.'-]+$/i.test(this.firstName)
    },
    lastNameFeedback() {
      if (this.lastName?.length > 0) {
        return 'Please enter a valid Name'
      }
      return 'This field is required'
    },
    lastNameInputState() {
      if (!this.lastName) {
        return null
      }
      return this.lastName?.length >= 1 && /^[a-z ,.'-]+$/i.test(this.lastName)
    },
    emailInputState() {
      if (!this.email) {
        return null
      }

      return this.email?.length > 4 && helpers.emailRegex().test(this.email)
    },
    emailFeedback() {
      if (this.email.length < 4) {
        return 'Please enter a valid Email'
      }
      if (!helpers.emailRegex().test(this.email)) {
        return 'Please enter a valid Email'
      }
      return 'Email is required'
    },
    passwordInputState() {
      if (this.password === '') {
        return null
      }
      if (
        this.password?.length > 7 &&
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]).{8,}$/.test(this.password)
      ) {
        return true
      }
      return false
    },
    passwordFeedback() {
      if (this.password.length < 7) {
        return 'The password must be at least 8 characters'
      }
      if (
        !/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]).{8,}$/.test(this.password)
      ) {
        return 'Password must match password policy'
      }
      return 'Password is required'
    },
    confirmPasswordInputState() {
      if ((this.confirmPassword?.length > 7 && this.confirmPassword === this.password) || this.confirmPassword === '') {
        return null
      }
      return false
    },
    confirmPasswordFeedback() {
      if (this.confirmPassword.length < 7) {
        return 'The password must be at least 8 characters'
      }
      if (this.confirmPassword != this.password) {
        return 'Passwords must match'
      }
      return 'Password is required'
    },
    hearAboutUsInputState() {
      if (!this.whereDidYouHearAboutUs) {
        return null
      }
      return !!this.whereDidYouHearAboutUs?.length
    },
    hearAboutUsFeedback() {
      if (!this.whereDidYouHearAboutUs && this.$pfidaTenant) {
        return 'This field is required'
      }
    },
    eventsInputState() {
      if (!this.event) {
        return null
      }
      return !!this.event?.length
    },
    eventsFeedback() {
      if (!this.event) {
        return 'This field is required'
      }
    },
    dobFeedback() {
      var date_regex = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/
      if (!date_regex.test(this.dob)) {
        return 'Please enter a valid date'
      }
      const dob = moment(this.dob, 'DD/MM/YYYY', true)
      const now = moment()
      if (dob > now) {
        return 'Date must be in the past'
      }
      return 'This field is required'
    },
    dobInputState() {
      if (!this.dob) {
        return null
      }

      const dob = moment(this.dob, 'DD/MM/YYYY', true)
      const now = moment()

      if (dob.isValid()) {
        if (dob > now) {
          return false
        }

        return true
      }

      return false
    },
    mobileFeedback() {
      if (this.telephone?.length > 0) {
        return 'The telephone field must be at least 10 characters'
      }
      return 'This field is required'
    },
    mobileInputState() {
      if (!this.telephone) {
        return null
      }
      return this.telephone?.length >= 10
    },

    TandCFeedback() {
      return 'Please confirm you have read and understood the Terms and Conditions'
    },
    TandCInputState() {
      return this.TandCCheck
    },
    validateForm() {
      const isValid = this.firstNameInputState &&
        this.lastNameInputState &&
        this.emailInputState &&
        this.dobInputState &&
        this.mobileInputState &&
        this.passwordInputState &&
        this.TandCInputState

      // if the user came from a referral link then ignore hear about us input, also if hear about us doesnt doesnt have any option ignore it
      const hearAboutus = (this.$store.state.referral || !this.options.hearAboutUsOptions.length ? true : this.hearAboutUsInputState) &&
        (this.whereDidYouHearAboutUs !== 'Event' ? true : this.eventsInputState)

      const optionalIsValid = this.$pfidaTenant ? hearAboutus : true

      return isValid && optionalIsValid
    }
  },
  methods: {
    isLetter(e) {
      let char = String.fromCharCode(e.keyCode) // Get the character
      if (/^[a-z ,.'-]+$/i.test(char)) return true
      // Match with regex
      else e.preventDefault() // If not match, don't add to input text
    },
    hearAboutUsChanged() {
      if (this.whereDidYouHearAboutUs !== 'Event') {
        this.event = ''
      }
    },
    countryCodeSelect(value) {
      this.countryCode = value.dialCode
    },
    async signUp() {
      const formattedEmail = this.email?.toLowerCase().replace(/ /g, '')
      const clientSecret = await helpers.generateUserSecret(formattedEmail)
      const payload = {
        name: `${this.firstName} ${this.lastName}`,
        email: formattedEmail,
        birthdate: this.dob,
        client: 'web',
        client_secret: clientSecret,
        password: this.password,
        phone_number: `+${this.countryCode}${this.telephone}`
      }

      this.$vs.loading()

      this.$store
        .dispatch(`auth/signUp`, payload)
        .then(() => {
          this.$router.push({ path: '/signup/confirm', query: { to: this.$router.currentRoute?.query?.to } })

          this.$store.commit('auth/SET_MARKETING', this.marketingCheck)
          
          if (!this.$store.state.referral) {
            const referredEvent = this.whereDidYouHearAboutUs !== 'Event' ? this.whereDidYouHearAboutUs : this.event

            this.$store.commit('SET_REFERRAL', referredEvent || 'Other')
          }

          this.$vs.loading.close()
        })
        .catch((err) => {
          helpers.notifyError(this.$vs, getErrorMessage(err))
          this.$vs.loading.close()
        })
    }
  },
  mounted() {
    if (this.$router.currentRoute?.query?.referral) {
      this.$store.commit('SET_REFERRAL', this.$router.currentRoute?.query?.referral)
    } else {
      if (!this.$store.state.referral) {
        this.$store.dispatch('getHearAboutUs').then((res) => {
          this.options.hearAboutUsOptions = [{ value: null, text: 'Please select an option' }, ...res.hear_about]
          this.options.events = [{ value: null, text: 'Please select an option' }, ...res.events]
        })
      }
    }

    if (this.$router.currentRoute?.query?.company) {
      this.$store.commit('SET_EQUITY_INVESTMENT_INTEREST', this.$router.currentRoute?.query?.company)
    }
  }
}
</script>

<style lang="scss" scoped>
#input-group-mobile {

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0;
    /* <-- Apparently some margin are still there even though it's hidden */
  }

  input[type='number'] {
    -moz-appearance: textfield;
    /* Firefox */
  }
}
</style>
