<template>
  <div>
    <b-table
      thead-class="thVariant"
      :current-page="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      variant="investmentTable"
      stacked="sm"
      table-variant="transactions"
      id="transactions-table"
      :fields="this.fields"
      :tbody-tr-class="rowClass"
      hover
      :items="formattedTable"
      caption-top
    >
      <!-- <template #table-caption>Transactions</template> -->
    </b-table>

    <b-pagination
      v-model="currentPage"
      :per-page="perPage"
      :total-rows="rows"
      align="right"
      aria-controls="transactions-table"
    ></b-pagination>
  </div>
</template>

<script>
export default {
  name: 'TransactionsListTable',
  props: {
    breakdown: {
      type: Array,
      required: true
    },
    perPage: {
      type: Number,
      default: 5
    }
  },
  data: () => ({
    formattedTable: [],
    currentPage: 1,
    fields: [
      {
        key: 'date',
        label: 'Date',
        tdClass: 'date-col'
      },
      {
        key: 'outstanding_equity',
        label: 'Outstanding Equity',
        tdClass: 'd-none d-sm-table-cell',
        thClass: 'd-none d-sm-table-cell'
      },
      {
        key: 'payment_made',
        label: 'Payment Made'
      },
      {
        key: 'rent_charged',
        label: 'Rent'
      },
      {
        key: 'equity_purchased',
        label: 'Equity'
      }
    ]
  }),
  created() {
    const allowed = ['date', 'outstanding_equity', 'payment_made', 'rent_charged', 'equity_purchased', 'type']
    this.formattedTable = this?.breakdown.map((item) => {
      const pickedObj = this.$lodash.pick(item, allowed)
      return pickedObj
    })
  },
  methods: {
    rowClass(item) {
      if (item.type === 'aggregate') return 'table-secondary'
    }
  },
  computed: {
    rows() {
      return this?.breakdown.length
    }
  }
}
</script>

<style lang="scss">
.thVariant {
  background-color: $pfida-purple !important;
}

.thVariant tr {
  color: white !important;
}

.table-transactions th,
.table-transactions td {
  border-bottom: 1px solid $pfida-purple;
}

.date-col {
  width: 25%;
}
@media only screen and (max-width: 576px) {
  .date-col {
    width: 100%;
  }
}
</style>
