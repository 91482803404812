<!-- =========================================================================================
  File Name: Dashboard.vue
  Description: Dashboard
========================================================================================== -->

<template>
  <div id="dashboard-analytics">
    <div v-if="!error" class="flex flex-col gap-6">
      <!-- Rent Arrears notification -->
      <div id="rent-arrears" class="w-full" v-if="rentArrears.length != 0">
        <b-alert show variant="danger">
          At least one of your finance agreements is in <b>rent arrears</b>
          <span v-for="(ref, index) in rentArrears" :key="index">
            <b-button variant="link" class="pl-1 pr-1" @click="showPropertyPurchaseProduct(ref.index)"
              ><b>{{ ref.reference }}{{ rentArrears.length > 1 ? ';' : '' }}</b></b-button
            >
          </span>
        </b-alert>
      </div>

      <!-- CARD 1: Property Purchase Plans -->
      <div
        id="hps"
        class="vx-col w-full mb-base"
        v-for="(hps, index) in propertyPurchasePlans"
        :key="index"
        tabindex="1"
      >
        <a href="" @click.prevent="showPropertyPurchaseProduct(index)" tabindex="-1">
          <vx-card class="hover-effect">
            <div class="flex items-center">
              <div
                class="fake-icon p-3 inline-flex rounded-full select-none relative"
                style="background: rgba(var(--vs-primary), 0.15)"
              >
                <b-icon icon="house-door" aria-label="Home"></b-icon>
              </div>
              <h3 class="pl-2 mb-0">{{ hps.product_name }} {{ hps.reference ? '-' : '' }} {{ hps.reference }}</h3>
            </div>
            <p class="mt-2">View charts and figures for your {{ hps.product_name }}.</p>

            <div class="vx-row" v-if="hps && hps.remaining_finance">
              <vs-divider class="my-6"></vs-divider>
              <div class="vx-col w-full mb-3">
                <div class="vx-col w-full mb-3">
                  <p>Next remaining payment: {{ hps.next_payment_remaining }}</p>
                  <vs-progress
                    class="block mt-1 progress"
                    :percent="hps.next_total_monthly_payment_progress_percentage"
                    :color="constants.colors.pfidaPurple"
                  ></vs-progress>
                </div>
                <p>Outstanding equity: {{ hps.remaining_finance }}</p>
                <vs-progress
                  class="block mt-1 progress"
                  :percent="outstandingAmount(hps.total_actually_paid, hps.acquisition_cost)"
                  :color="constants.colors.pfidaDustBlue"
                ></vs-progress>
              </div>

              <div class="vx-col w-full mb-3">
                <p>Projected term remaining: {{ hps.remaining_term_string }}</p>
                <vs-progress
                  class="block mt-1 progress"
                  :percent="((hps.total_term_years - hps.remaining_term_years) / hps.total_term_years) * 100"
                  :color="constants.colors.pfidaSalmon"
                ></vs-progress>
              </div>
            </div>
          </vx-card>
        </a>
      </div>

      <!-- CARD 1: If Dip Sent -->
      <div id="no-hps" class="vx-col w-full mb-base" v-for="(dip, index) in dipApplications" :key="index" tabindex="2">
        <vx-card class="hover-effect">
          <div class="flex items-center">
            <div
              class="fake-icon p-3 inline-flex rounded-full select-none relative"
              style="background: rgba(var(--vs-primary), 0.15)"
            >
              <b-icon icon="house-door" aria-label="Home"></b-icon>
            </div>
            <h3 class="pl-2 mb-0">{{ dip.productName }} - {{ dip.agreementReference }}</h3>
          </div>
          <p class="mt-2" style="color: rgba(var(--vs-primary))">
            Start your property journey with a Decision in Principle.
          </p>
          <vs-divider class="my-6" />
          <div class="flex items-center justify-center">
            <b-button
              id="invest-more"
              variant="primary"
              size="lg"
              @click="goTo(`/dip?agreementReference=${dip.agreementReference}&applicationId=${dip.applicationId}`)"
              >Start Decision in Principle</b-button
            >
          </div>
        </vx-card>
      </div>

      <!-- CARD 1: IF No HPS AND No Dip Sent -->
      <div
        id="no-hps"
        class="vx-col w-full mb-base"
        v-if="propertyPurchasePlans.length === 0 && dipApplications.length === 0"
        tabindex="2"
      >
        <a href="/app/property-purchase-plan" @click.prevent="showPropertyPurchaseProduct()" tabindex="-1">
          <vx-card class="hover-effect">
            <div class="flex items-center">
              <div
                class="fake-icon p-3 inline-flex rounded-full select-none relative"
                style="background: rgba(var(--vs-primary), 0.15)"
              >
                <b-icon icon="house-door" aria-label="Home"></b-icon>
              </div>
              <h3 class="pl-2 mb-0">Property Purchase Plan</h3>
            </div>
          </vx-card>
        </a>
      </div>

      <template v-if="this.$pfidaTenant">
        <!-- CARD 2: Grow savings -->
        <div
          id="gys"
          class="vx-col w-full mb-base"
          v-for="(investment, index) in investments.pfhpp"
          :key="'gys' + index"
          tabindex="2"
        >
          <vx-card class="hover-effect">
            <a href="" @click.prevent="showGrowYourSavings(index)" tabindex="3">
              <div class="flex items-center">
                <div
                  class="fake-icon cursor-pointer p-3 inline-flex rounded-full items-center select-none relative text-primary"
                  style="background: rgba(var(--vs-primary), 0.15)"
                >
                  £
                </div>
                <h3 class="pl-2 mb-0">{{ investment.title }}</h3>
                <b-badge class="ml-auto px-6 py-3" :class="getBadgeClass(index)" pill>{{ investment.status }}</b-badge>
              </div>
              <p class="mt-2" v-html="investment.text"></p>

              <div id="gys-progress" class="vx-row" v-if="investment.investment_history.length">
                <vs-divider class="my-6"></vs-divider>
                <div class="vx-col w-full mb-3">
                  <p>Portfolio Value: {{ investment.portfolio_value }}</p>
                  <vs-progress
                    class="block mt-1 progress"
                    :percent="investment.portfolio_value_progress_percentage"
                    :color="constants.colors.pfidaDustBlue"
                  ></vs-progress>
                </div>
                <div class="vx-col w-full mb-3" v-if="investment.show_gain_loss">
                  <p>Gain/Loss to date: {{ investment.total_gain_loss_amount }}</p>
                  <vs-progress
                    class="block mt-1 progress"
                    :percent="investment.total_gain_loss_progress_percentage"
                    :color="constants.colors.pfidaPurple"
                  ></vs-progress>
                </div>
                <div class="vx-col w-full mb-3">
                  <p>Share Class: {{ investment.current_share_class }}</p>
                  <vs-progress
                    class="block mt-1 progress"
                    :percent="investment.share_class_progress_percentage"
                    :color="constants.colors.pfidaSalmon"
                  ></vs-progress>
                </div>
              </div>

              <vs-divider class="my-6" v-if="investment.show_gys_button"></vs-divider>
            </a>
            <div class="flex items-center justify-center" v-if="investment.show_gys_button">
              <b-button id="invest-more" variant="primary" size="lg" @click="investMorePopup(index)">{{
                investment.gys_button_text
              }}</b-button>
            </div>
          </vx-card>
        </div>

        <!-- CARD 3a: Pfida Equity Investment -->
        <div id="equity-investment" class="vx-col w-full mb-base" v-if="pfidac && pfidac.show_card">
          <vx-card class="ahover-effect">
            <!-- <a href="" @click.prevent="goTo('investor-hub')" tabindex="4"> -->
            <div class="flex items-center">
              <div
                class="fake-icon p-3 inline-flex rounded-full feather-icon select-none relative"
                style="background: rgba(var(--vs-primary), 0.15)"
              >
                <HandShakeIcon />
              </div>
              <h3 class="pl-2 mb-0">{{ pfidac.title }}</h3>
            </div>
            <p class="mt-2">{{ pfidac.text }}</p>
            <vs-divider class="my-6"></vs-divider>

            <div class="vx-row">
              <!-- <vs-divider class="my-6"></vs-divider> -->
              <div class="vx-col w-full mb-3">
                <div class="grid-container text-center">
                  <div>
                    <PercentageCircle :text="pfidac.total_investment" :color="constants.colors.pfidaPurple" />
                    <p class="font-bold">Original investment</p>
                  </div>
                  <div>
                    <PercentageCircle
                      :text="pfidac.num_shares"
                      :color="constants.colors.pfidaDustBlue"
                      :isShares="true"
                    />
                    <p class="font-bold">Number of shares</p>
                  </div>
                </div>
              </div>
            </div>
            <vs-divider v-if="pfidac.show_equity_hub" class="my-6"></vs-divider>

            <div class="flex items-center justify-center" v-if="pfidac.show_equity_hub">
              <b-button id="invest-more" variant="primary" size="lg" @click="goTo('investor-hub')"
                >Visit Investor Hub</b-button
              >
            </div>
            <!-- </a> -->
          </vx-card>
        </div>

        <!-- CARD 3b: PFC -->
        <div id="PFC" class="vx-col w-full mb-base" v-if="consortium && consortium.num_shares">
          <vx-card>
            <div class="flex items-center">
              <div
                class="fake-icon p-3 inline-flex rounded-full feather-icon select-none relative"
                style="background: rgba(var(--vs-primary), 0.15)"
              >
                <HandShakeIcon />
              </div>
              <h3 class="pl-2 mb-0">{{ consortium.title }}</h3>
            </div>

            <div class="vx-row">
              <vs-divider class="my-6"></vs-divider>
              <div class="vx-col w-full mb-3">
                <div class="grid-container text-center">
                  <div>
                    <PercentageCircle :text="consortium.total_investment" :color="constants.colors.pfidaPurple" />
                    <p class="font-bold">Original investment</p>
                  </div>
                  <div>
                    <PercentageCircle
                      :text="consortium.num_shares"
                      :color="constants.colors.pfidaDustBlue"
                      :isShares="true"
                    />
                    <p class="font-bold">Number of shares</p>
                  </div>
                </div>
              </div>
            </div>
          </vx-card>
        </div>

        <!-- CARD 4: Calculators -->
        <div class="vx-col w-full sm:w-full mb-base">
          <a
            v-if="this.$pfidaTenant"
            id="calculators"
            href="/app/calculators"
            @click.prevent="$router.push('/calculators').catch(() => {})"
            tabindex="4"
          >
            <vx-card class="hover-effect">
              <div class="flex items-center">
                <span
                  class="material-icons-outlined cursor-pointer p-3 inline-flex rounded-full select-none relative text-primary"
                  style="background: rgba(var(--vs-primary), 0.15)"
                >
                  calculate
                </span>
                <h3 class="pl-2 mb-0">Calculators</h3>
              </div>
              <p class="mt-2">Check costs and eligibility.</p>
            </vx-card>
          </a>
        </div>
        <!-- Review us Widgets -->
        <div v-if="propertyPurchasePlans.length" class="pb-4 flex justify-center w-full">
          <TrustPilotVue />
        </div>
      </template>
    </div>
    <ServerError500Vue v-else />
    <template v-if="this.$pfidaTenant">
      <b-modal id="invest-more-modal" title="Top up your account" centered v-model="popupActiveTopUp" ok-only>
        <div class="text-container">
          <p class="py-2 text-left">
            You are eligible to invest anything up to <b>{{ monthlyInvestmentsAmount(selectedGYSIndex) }}</b> in any
            month{{ monthlyInvestmentAmountRemainingTxt(selectedGYSIndex) }}
            without having to complete another form.
          </p>

          <p class="py-2 text-left">
            Simply make payment following the instructions in the document called <b>Payment instructions GYS</b>, which
            can be found in your
            <b-link
              variant="link"
              class="p-0"
              @click="goTo({ name: 'documents', params: { filter: 'Payment instructions' } })"
              >Documents</b-link
            >
            center.
          </p>
          <p class="py-2 text-left">
            If you would like to invest anything more than <b>{{ monthlyInvestmentsAmount(selectedGYSIndex) }}</b
            >, then you will need to complete a new <b>subscription form </b>by clicking the button below.
          </p>
        </div>
        <template #modal-footer="{ ok }">
          <b-button class="shadow-md lg:mt-0" @click="investMore(selectedGYSIndex)" variant="outline-primary"
            >I would like to invest more than {{ monthlyInvestmentsAmount(selectedGYSIndex) }}</b-button
          >
          <b-button class="shadow-md lg:mt-0" @click.prevent="ok()" variant="primary">Close</b-button>
        </template>
      </b-modal>

      <b-modal id="gys-apply-modal" title="Grow Your Savings" centered v-model="popupActiveGYSApply" ok-only>
        <div class="text-container">
          <div class="py-2 text-left" v-html="gysInvestment(selectedGYSIndex).gys_disclaimer_boxed_text" />
          <div class="py-2 text-left" v-html="gysInvestment(selectedGYSIndex).gys_disclaimer_footer_text" />
        </div>
        <template #modal-footer="{ ok }">
          <b-button class="shadow-md lg:mt-0" @click="investMore(selectedGYSIndex)" variant="outline-primary"
            >Apply
          </b-button>
          <b-button class="shadow-md lg:mt-0" @click.prevent="ok()" variant="primary">Cancel</b-button>
        </template>
      </b-modal>

      <b-modal id="no-investment-modal" title="No investment product" centered v-model="popupActiveInvestment">
        <div>
          You do not have this investment product with us currently. If you have already applied, our team will be in
          touch with you soon with next steps.
        </div>
        <template #modal-footer="{ ok }">
          <div class="flex justify-center mb-0">
            <b-button class="shadow-md lg:mt-0" @click.prevent="ok()" variant="primary">OK</b-button>
          </div>
        </template>
      </b-modal>

      <b-modal id="zakat-modal" centered title="Set your zakat date" v-model="popupActiveZakat">
        <p class="text-lg">
          If you are a zakat payer, please let us know when you usually pay zakat. Our platform will notify you on this
          day if you have any funds invested with us that zakat must be paid on.
        </p>

        <template #modal-footer>
          <b-button
            type="submit"
            form="getHistory"
            size="lg"
            class="shadow-md lg:mt-0 mb-2"
            variant="light"
            @click="doNotShowAgain()"
            >Do not show again</b-button
          >
          <b-button
            type="submit"
            form="getHistory"
            size="lg"
            class="shadow-md lg:mt-0 mb-2"
            variant="primary"
            @click="goToZakatDate()"
            >Take me there
          </b-button>
        </template>
      </b-modal>

      <b-modal id="no-hps-modal" title="No Property Purchase Plan" centered v-model="popupActiveHPS">
        <div>
          You do not have a Home Provision Scheme with us currently. Check out our calculators using the button below.
        </div>
        <template #modal-footer>
          <div class="flex justify-center mb-0">
            <b-button class="shadow-md lg:mt-0" @click="goTo('/calculators')" variant="primary"
              >Go to Calculators</b-button
            >
          </div>
        </template>
      </b-modal>
      <b-modal id="no-bank-details-modal" centered title="Set your Bank Details" v-model="popupActiveNoPaymentDetails">
        <p class="text-lg">
          To be able to pay dividends out to you, we need your bank details. Follow the link below to add your bank
          details
        </p>

        <template #modal-footer="{ ok }">
          <b-button
            type="submit"
            form="getHistory"
            size="lg"
            class="shadow-md lg:mt-0 mb-2"
            variant="light"
            @click.prevent="ok()"
            >Cancel</b-button
          >
          <b-button
            type="submit"
            form="getHistory"
            size="lg"
            class="shadow-md lg:mt-0 mb-2"
            variant="primary"
            @click="goTo('/bank-details')"
            >Take me there
          </b-button>
        </template>
      </b-modal>
    </template>
  </div>
</template>

<script>
import helpers from '../helpers';
import PercentageCircle from './components/primary/PercentageCircle/PercentageCircle.vue';
import HandShakeIcon from '../assets/images/file-icons/handshake.vue';
import TrustPilotVue from './components/primary/trustPilot/TrustPilot.vue';
import ServerError500Vue from './components/error-pages/ServerError500.vue';
import userAPI from '../http/requests/user/userAPI';
import { getErrorMessage } from '@repo/utils';

export default {
  components: {
    PercentageCircle,
    HandShakeIcon,
    TrustPilotVue,
    ServerError500Vue,
  },
  data() {
    return {
      loaded: false,
      salesBarSession: {},
      propertyPurchasePlans: [],
      dipApplications: [],
      investments: {},
      popupActiveHPS: false,
      popupActiveInvestment: false,
      popupActiveTopUp: false,
      popupActiveZakat: false,
      popupActiveGYSApply: false,
      constants: {},
      rentArrears: [],
      selectedGYSIndex: 0,
      popupActiveNoPaymentDetails: false,
      error: false,
    };
  },
  methods: {
    outstandingAmount(outstanding, total) {
      return (helpers.currencyToInt(outstanding) / helpers.currencyToInt(total)) * 100;
    },
    gysInvestment(index) {
      return this?.investmentDetails[index];
    },
    getBadgeClass(index) {
      const investment = this.gysInvestment(index);
      return {
        'gys-active': investment.status === 'Active',
        'gys-in-progress': investment.status === 'In progress',
        'gys-not-started': investment.status === 'Not started',
      };
    },
    monthlyInvestmentsAmount(index) {
      return this?.investmentDetails[index]?.monthly_investment_amount;
    },
    monthlyInvestmentAmountRemainingTxt(index) {
      if (
        this?.investmentDetails[index]?.monthly_investment_amount_remaining &&
        helpers.currencyToInt(this?.investmentDetails[index]?.monthly_investment_amount_remaining) <
          helpers.currencyToInt(this?.monthlyInvestmentsAmount(index))
      ) {
        return `, with ${this.investmentDetails[index]?.monthly_investment_amount_remaining} remaining for this month,`;
      }
    },
    async investMore(index) {
      if (this.investmentDetails[index].gys_button_text === 'Apply') {
        await userAPI.sendSubscriptionForm();
      }

      window.open(this.investmentDetails[index].gys_button_link, '_blank');
    },
    investMorePopup(index) {
      if (this.investmentDetails[index]?.show_gys_disclaimer) {
        this.selectedGYSIndex = index;
        this.popupActiveGYSApply = true;
      } else {
        if (this.monthlyInvestmentsAmount(index)) {
          this.selectedGYSIndex = index;
          this.popupActiveTopUp = true;
        } else {
          if (this.investmentDetails[index].gys_button_text === 'Apply') {
            userAPI.sendSubscriptionForm();
          }
          window.open(this.investmentDetails[index].gys_button_link, '_blank');
        }
      }
    },
    showGrowYourSavings(index) {
      if (this.investments?.pfhpp.length && this.investments?.pfhpp[index].investment_history?.length) {
        this.$router.push(`grow-your-savings/${index + 1}`);
      } else {
        this.popupActiveInvestment = true;
      }
    },
    showPropertyPurchaseProduct(index) {
      if (this.propertyPurchasePlans.length && this.propertyPurchasePlans[0].remaining_finance) {
        this.$router.push(`/property-purchase-plan/${index + 1}`).catch(() => {});
      } else {
        this.popupActiveHPS = true;
      }
    },
    doNotShowAgain() {
      this.$vs.loading();
      const payload = {
        notify: false,
      };

      this.$store
        .dispatch('user/updateZakatDate', payload, {
          headers: {
            'pf-access-token': this.$store.getters['auth/accessToken'],
          },
        })
        .then(() => {
          this.$vs.loading.close();
          this.popupActiveZakat = false;
        })
        .catch(() => {
          this.$vs.loading.close();
          this.popupActiveZakat = false;
        });
    },
    goToZakatDate() {
      this.$router.push({ name: 'account-settings', params: { index: 2 } });
    },
    goTo(url, external) {
      if (external) {
        window.open(url);
      } else {
        this.$router.push(url);
      }
    },
    getData() {
      this.$vs.loading();

      this.$store.dispatch('getDipApplications').then((response) => {
        this.dipApplications = response.filter((dip) => dip.status === 'SENT');
      });

      this.$store
        .dispatch('getFinanceDetails')
        .then((response) => {
          const finance_details = response;

          this.propertyPurchasePlans = finance_details;
          this.rentArrears = finance_details.reduce(
            (acc, { rent_arrears_remaining, reference, show_rent_arrears }, i) => {
              if ((rent_arrears_remaining, show_rent_arrears)) {
                acc.push({ reference, index: i });
                return acc;
              }
              return acc;
            },
            []
          );

          this.$store
            .dispatch('getInvestmentDetails', { force: true })
            .then((response) => {
              this.loaded = true;
              this.investments = response;
              const showEquityHub = response.pfidac.show_equity_hub;
              // Redirect to Investor Hub if user logged in with company param
              if (this.$store.state.equityInvestmentRedirect && showEquityHub) {
                this.$router.push('/investor-hub');
              }

              // Check if Zakat date set
              this.$store
                .dispatch('user/getZakatDate')
                .then((res) => {
                  this.$vs.loading.close();
                  if (res.data.data.notify && this.$pfidaTenant) {
                    this.popupActiveZakat = true;
                  }
                })
                .catch(() => {
                  this.$vs.loading.close();
                });
            })
            .catch((error) => {
              this.$vs.loading.close();
              this.error = true;
              helpers.notifyError(this.$vs, getErrorMessage(error));
            });
        })
        .catch((error) => {
          this.$vs.loading.close();
          // ! Show 500 error page
          this.error = true;
          helpers.notifyError(this.$vs, getErrorMessage(error));
        });
    },
  },
  computed: {
    consortium() {
      return this.$store.state.investmentDetails.pfc;
    },
    pfidac() {
      return this.$store.state.investmentDetails.pfidac;
    },
    _bankDetailsExist() {
      return this.$store.getters['user/bankDetailsExist'];
    },
    investmentDetails() {
      return this.$store.state?.investmentDetails?.pfhpp;
    },
  },
  created() {
    this.getData();
    this.constants = { ...this.$constants };
  },
};
</script>

<style lang="scss">
.grid-container {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.material-icons-outlined {
  font-size: 20px;
}

.gys-active {
  background-color: #e8f6ef;
  color: #1b4230;
}

.gys-not-started {
  background-color: #ebecf0;
  color: #171717;
}

.gys-in-progress {
  background-color: #f5f0ff;
  color: #332554;
}

.fake-icon {
  max-width: 35px;
  max-height: 35px;
  width: 35px;
  text-align: center;
  display: flex;
  justify-content: center;
  font-weight: 500;
}

.progress {
  height: 14px !important;
}

.hover-effect:hover {
  padding-left: 20px;
}
</style>
