export const SET_RETIREMENT_AGE = 'SET_RETIREMENT_AGE';
export const TOGGLE_MFA = 'TOGGLE_MFA';
export const UPDATE_DOCUMENT = 'UPDATE_DOCUMENT';
export const SET_USER_DETAILS = 'SET_USER_DETAILS';
export const SET_ZAKAT_DATE = 'SET_ZAKAT_DATE';
export const SET_UNREAD_DOCS_AMOUNT = 'SET_UNREAD_DOCS_AMOUNT';
export const SET_REFERRALS = 'SET_REFERRALS';
export const SET_REFERRAL_SPLIT = 'SET_REFERRAL_SPLIT';
export const SET_REFERRAL_SETTINGS_DATA = 'SET_REFERRAL_SETTINGS_DATA';
export const SET_DOCUMENTS = 'SET_DOCUMENTS';
export const SET_BANK_DETAILS = 'SET_BANK_DETAILS';
export const SET_INVESTOR_DECLARATION = 'SET_INVESTOR_DECLARATION';
export const SET_LOADING = 'SET_LOADING';
export const SET_ERROR = 'SET_ERROR';
