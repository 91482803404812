<template>
  <div>
    <b-table
      thead-class="thVariant"
      variant="investmentTable"
      stacked="sm"
      :fields="this.fields"
      hover
      :items="formattedSummary"
    ></b-table>
  </div>
</template>

<script>
export default {
  name: 'TransactionSummaryTable',
  props: {
    summary: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      formattedSummary: [],
      fields: [
        {
          key: 'totals',
          label: '',
          tdClass: 'totalsCol'
        },
        {
          key: 'outstanding_equity',
          label: 'Outstanding Equity'
        },
        {
          key: 'payments_made',
          label: 'Payments Made'
        },
        {
          key: 'rent_charged',
          label: 'Rent Paid'
        },
        {
          key: 'equity_purchased',
          label: 'Equity Purchased'
        }
      ]
    }
  },
  mounted() {
    const allowed = ['outstanding_equity', 'payments_made', 'rent_charged', 'equity_purchased']
    this.formattedSummary = this?.summary.map((item) => {
      const pickedObj = this.$lodash.pick(item, allowed)

      pickedObj.totals = 'Totals'
      pickedObj._rowVariant = 'pfidaPaper'
      return pickedObj
    })
  }
}
</script>

<style lang="scss">
.totalsCol {
  font-weight: bold;
  width: 25%;
}

.table-pfidaPaper {
  background-color: $pfida-paper;
}

.table-hover .table-pfidaPaper:hover {
  background-color: $pfida-purple-xLight;
}

@media only screen and (max-width: 576px) {
  .totalsCol {
    width: 100%;
  }
}
</style>
