<template>
  <div>
    <div class="flex justify-between items-center settings-option" @click.prevent="showPwdModal">
      <h4 class="mb-0">Change Password</h4>
      <feather-icon icon="ChevronRightIcon" class="ml-4" />
    </div>

    <b-modal id="change-pwd" lazy centered title="Change Password">
      <form id="passwordForm" novalidate="true" @submit.prevent="ChangePassword">
        <b-form-group id="input-group-old-password" :invalid-feedback="oldPasswordFeedback" :state="oldPasswordState"
          label="Old Password:" label-for="input-old-password">
          <b-input-group class="mb-2">
            <b-form-input :state="oldPasswordState" id="input-old-password" v-model="oldPassword" type="password"
              required></b-form-input>
          </b-input-group>
        </b-form-group>

        <b-form-group id="input-group-new-password" :invalid-feedback="newPasswordFeedback" :state="newPasswordState"
          label="New Password:" label-for="input-new-password">
          <b-input-group class="mb-2">
            <b-form-input :state="newPasswordState" id="input-new-password" v-model="newPassword" type="password"
              required></b-form-input>
          </b-input-group>
        </b-form-group>

        <b-form-group id="input-group-confirm-password" :invalid-feedback="confirmNewPasswordFeedback"
          :state="confirmNewPasswordInputState" label="Confirm new password:" label-for="input-confirm-password">
          <b-input-group class="mb-2">
            <b-form-input :state="confirmNewPasswordInputState" id="input-confirm-password" v-model="confirmNewPassword"
              type="password" required></b-form-input>
          </b-input-group>
        </b-form-group>

        <div class="mt-4" v-if="errors.length">
          <b>Please correct the following error(s):</b>
          <ul>
            <li v-for="(err, i) in errors" :key="i" class="text-danger">{{ err }}</li>
          </ul>
        </div>

        <PasswordRules :password="newPassword" class="mt-4" />
      </form>
      <template #modal-footer>
        <div class="flex flex-wrap items-center justify-end">
          <b-button variant="outline-warning" @click="resetPasswordForm"
            class="shadow-sm lg:mt-0 mb-2 mt-2 mr-4 p-2">Reset</b-button>
          <b-button class="shadow-md hover:shadow-lg lg:mt-0 mb-2 mt-2 mr-4 p-2" variant="primary" type="submit"
            form="passwordForm" :disabled="!validateForm">Save Changes</b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { getErrorMessage } from '@repo/utils'
import helpers from '../../../../../helpers'
import PasswordRules from '../../../../components/PasswordRules.vue'

export default {
  components: {
    PasswordRules
  },
  data() {
    return {
      oldPassword: '',
      newPassword: '',
      confirmNewPassword: '',
      errors: []
    }
  },
  computed: {
    validateForm() {
      return (
        this.oldPassword != '' &&
        this.newPassword != '' &&
        this.confirmNewPassword != '' &&
        this.confirmNewPassword === this.newPassword
      )
    },

    oldPasswordState() {
      if (
        (this.oldPassword?.length > 7 &&
          /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]).{8,}$/.test(
            this.oldPassword
          )) ||
        this.oldPassword === ''
      ) {
        return null
      }
      return false
    },
    oldPasswordFeedback() {
      if (this.oldPassword.length < 7) {
        return 'The password must be at least 8 characters'
      }
      if (
        !/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]).{8,}$/.test(
          this.oldPassword
        )
      ) {
        return 'Password must match password policy'
      }
      return 'Password is required'
    },
    newPasswordState() {
      if (
        (this.newPassword?.length > 7 &&
          /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]).{8,}$/.test(
            this.newPassword
          )) ||
        this.newPassword === ''
      ) {
        return null
      }
      return false
    },
    newPasswordFeedback() {
      if (this.newPassword.length < 7) {
        return 'The password must be at least 8 characters'
      }
      if (
        !/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]).{8,}$/.test(
          this.newPassword
        )
      ) {
        return 'Password must match password policy'
      }
      return 'Password is required'
    },
    confirmNewPasswordInputState() {
      if (
        (this.confirmNewPassword?.length > 7 && this.confirmNewPassword === this.newPassword) ||
        this.confirmNewPassword === ''
      ) {
        return null
      }
      return false
    },
    confirmNewPasswordFeedback() {
      if (this.confirmNewPassword.length < 7) {
        return 'The password must be at least 8 characters'
      }
      if (this.confirmNewPassword != this.newPassword) {
        return 'Passwords must match'
      }
      return 'Password is required'
    }
  },
  methods: {
    showPwdModal() {
      this.$bvModal.show('change-pwd')
    },
    checkForm() {
      this.errors = []

      if (this.oldPassword.length < 8) {
        this.errors.push('Old Password must be at least 8 characters')
      }
      if (this.newPassword.length < 8 || this.confirmNewPassword.length < 8) {
        this.errors.push('New Password must be at least 8 characters')
      }

      if (this.newPassword != this.confirmNewPassword) {
        this.errors.push('Passwords do not match.')
      }

      if (!this.errors.length) {
        return true
      }
    },
    ChangePassword() {
      if (this.checkForm()) {
        const payload = {
          client: 'web',
          previous_password: this.oldPassword,
          proposed_password: this.newPassword
        }
        this.$store
          .dispatch('auth/updatePassword', payload)
          .then(() => {
            this.$vs.loading.close()
            helpers.notifySuccess(this.$vs, 'Your password has been updated!')
            this.resetPasswordForm()
            this.$bvModal.hide('change-pwd')
          })
          .catch((error) => {
            this.$vs.loading.close()
            this.resetPasswordForm()
            this.$bvModal.hide('change-pwd')
            helpers.notifyError(this.$vs, getErrorMessage(error))
          })
      }
    },
    resetPasswordForm() {
      this.oldPassword = this.newPassword = this.confirmNewPassword = ''
    }
  }
}
</script>
